import {
  faClipboardQuestion,
  faEllipsisVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import DialogAddNewMergeFieldQuestion from "../dialogs/DialogAddNewMergeFieldQuestion";
import DialogAddNewQuestionToQuestionnaire from "../dialogs/DialogAddNewQuestionToQuestionnaire";
import DialogChangeQuestionOrder from "../dialogs/DialogChangeQuestionOrder";
import DialogRemoveMergeField from "../dialogs/DialogRemoveMergeField";
import DialogRemoveQuestion from "../dialogs/DialogRemoveQuestion";
import { renderMergeFieldValueControls } from "../MergeFieldMenu/renderMergeFieldValueControls";

/** @type {import("./../editor/nodes/MarkNode").MergeField[]} */
const defaultMergeFields = [];

/**
 * @typedef {object} QuestionnaireDrawerProps
 * @property {string} docId
 * @property {boolean} documentIsTemplate
 * @property {string} partyId
 * @property {string} docId
 * @property {string} agreementTypeId
 * @property {string[]} agreementLabelsIds
 */

/**
 * @param {QuestionnaireDrawerProps} props
 */
export default function QuestionnaireDrawer({
  docId,
  documentIsTemplate,
  partyId,
  agreementTypeId,
  agreementLabelsIds,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [latestVersion] = state.drawerVersions.versions;

  const /** @type {string} */ agrvId =
      state.drawerVersions.active?._id ?? docId;

  const /** @type {string} */ organizationName = state.org.shortName;
  const /** @type {string} */ organizationId = state.org._id;

  const [activeStep, setActiveStep] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [completed] = useState({});
  const [topLevelMenuAnchor, setTopLevelMenuAnchorElement] = useState(null);
  const topLevelMenuOpen = Boolean(topLevelMenuAnchor);
  const [questionMenuAnchor, setQuestionMenuAnchorElement] = useState(null);
  const questionMenuOpen = Boolean(questionMenuAnchor);
  const [mergeFieldMenuAnchor, setMergeFieldMenuAnchorElement] = useState(null);
  const mergeFieldMenuOpen = Boolean(mergeFieldMenuAnchor);
  const [
    openAddNewQuestionToQuestionnaireDialog,
    setOpenAddNewQuestionToQuestionnaireDialog,
  ] = useState(false);
  const [
    openDialogAddNewMergeFieldQuestion,
    setopenDialogAddNewMergeFieldQuestion,
  ] = useState(false);
  const [, setIsLoading] = useState(false);
  const [mergeFields, setMergeFields] = useState(defaultMergeFields);
  const [openRemoveQuestionModal, setOpenRemoveQuestionModal] = useState(false);
  const [openChangeQuestionOrderDialog, setOpenChangeQuestionOrderDialog] =
    useState(false);
  // @ts-ignore
  const /** @type {import("./../editor/nodes/MarkNode").MergeField} */ defaultSelectedMergeField =
      null;
  const [selectedMergeField, setSelectedMergeField] = useState(
    defaultSelectedMergeField
  );
  const [openDialogRemoveMergeField, setOpenDialogRemoveMergeField] =
    useState(false);

  const loadDocumentMergeFields = useCallback(
    async () => {
      const response = await axios
        .get(`${state.settings.api}properties`, {
          params: {
            versionId: latestVersion._id,
            scope: "questionnaire",
          },
        })
        .catch((error) => {
          console.error(error);
        });

      setIsLoading(false);

      if (!response) throw new Error("Error getting response.");

      /** @type {import("./../editor/nodes/MarkNode").MergeField[]} */
      const documentMergeFields = response.data.data;
      if (!documentMergeFields) throw new Error("Error getting Merge Fields");

      setMergeFields(documentMergeFields);

      // console.info("%cQuestionnaire Merge Fields:", "color: gold");
      // console.info(documentMergeFields);

      if (documentMergeFields.length) {
        const mergeFields = documentMergeFields
          .filter((x) => x.type !== "partyInformation")
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );

        const unpositionableMergeFields = mergeFields.filter(
          (x) => x.position === -1
        );

        const sortedMergeFields = [
          ...unpositionableMergeFields
            .filter((x) => x.scope === "questionnaire")
            .sort((a, b) => {
              return a.createdAt < b.createdAt ? 1 : -1;
            }),
          ...mergeFields
            .filter((x) => x.position > -1)
            .sort((previous, next) =>
              previous.position > next.position ? 1 : -1
            ),
          ...unpositionableMergeFields
            .filter((x) => x.scope === "document")
            .sort((a, b) => {
              return a.createdAt > b.createdAt ? 1 : -1;
            }),
        ];

        setMergeFields(sortedMergeFields);

        return sortedMergeFields;
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.settings.api, agrvId]
  );

  useEffect(() => {
    setIsLoading(true);
    loadDocumentMergeFields();
  }, [loadDocumentMergeFields]);

  useEffect(
    () => {
      if (!initialized) {
        setInitialized(true);
        return;
      }
      if (
        ["CREATED_MERGE_FIELD", "DELETED_MERGE_FIELD"].includes(
          state?.selectedMergeField?.event
        )
      ) {
        if (state?.selectedMergeField?.event !== "DELETED_MERGE_FIELD") {
          delete state.selectedMergeField.event;
        }

        loadDocumentMergeFields().then((sortedMergeFields) => {
          const index = sortedMergeFields.findIndex(
            (x) => x._id === state.selectedMergeField._id
          );

          if (index === -1) {
            console.log("Merge Field not found.");
            return;
          }

          setActiveStep(index);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.selectedMergeField]
  );

  const handleTopLevelMenuOpen = (/** @type {*} **/ event) => {
    setTopLevelMenuAnchorElement(event.currentTarget);
  };

  const handleTopLevelMenuClose = () => {
    setTopLevelMenuAnchorElement(null);
  };

  const handleQuestionMenuOpen = (/** @type {*} **/ event) => {
    setQuestionMenuAnchorElement(event.currentTarget);
  };

  const handleQuestionMenuClose = () => {
    setQuestionMenuAnchorElement(null);
  };

  const handleMergeFieldMenuOpen = (/** @type {*} **/ event) => {
    setMergeFieldMenuAnchorElement(event.currentTarget);
  };

  const handleMergeFieldMenuClose = () => {
    setMergeFieldMenuAnchorElement(null);
  };

  const totalSteps = () => {
    return mergeFields.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = (
    /** @type {import("./../editor/nodes/MarkNode").MergeField} */ mergeField
  ) => {
    dispatch({
      type: "NEW_OPEN_ISSUE_SELECTION",
      payload: {
        id: mergeField._id,
        type: "navigation",
        status: "ongoing",
      },
    });

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // If it's the last step but not all steps have been completed,
          // find the first step that has been completed.
          mergeFields.findIndex((_, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = (
    /** @type {import("./../editor/nodes/MarkNode").MergeField} */ mergeField
  ) => {
    dispatch({
      type: "NEW_OPEN_ISSUE_SELECTION",
      payload: {
        id: mergeField._id,
        type: "navigation",
        status: "ongoing",
      },
    });

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (
    /** @type {import("./../editor/nodes/MarkNode").MergeField} */ mergeField,
    /** @type {number} */ step
  ) => {
    dispatch({
      type: "NEW_OPEN_ISSUE_SELECTION",
      payload: {
        id: mergeField._id,
        type: "navigation",
        status: "ongoing",
      },
    });

    setActiveStep(step);
  };

  const handleSubmit = (/** @type {any} **/ mergeField) => {
    const newMergeFields = [...mergeFields, mergeField];
    setMergeFields(newMergeFields);
  };

  /**
   * @param {import("./../editor/nodes/MarkNode").MergeField[]} orderedMergeFields
   */
  const bulkUpdateMergeFields = async (orderedMergeFields) => {
    for (const mergeField of mergeFields) {
      const orderedMergeFieldIndex = orderedMergeFields.findIndex(
        (omf) => omf._id === mergeField._id
      );

      if (orderedMergeFieldIndex > -1) {
        mergeField.position = orderedMergeFieldIndex;
      }
    }

    await axios
      .put(`${state.settings.api}mergefield/bulk`, {
        mergeFields,
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        p={2}
        sx={{
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={11}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <FontAwesomeIcon
              icon={faClipboardQuestion}
              color={theme.palette.primary.main}
              size="lg"
            />
            &nbsp;&nbsp;&nbsp;Questionnaire
          </Typography>
        </Grid>

        <Grid item xs={1}>
          {state.user.role.name !== "Counterparty" &&
            state.drawerVersions?.active?._id ===
              state.drawerVersions.versions[0]?._id && (
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                color={theme.palette.grey[800]}
                style={{
                  fontSize: "16px",
                  marginLeft: "auto",
                  cursor: "pointer",
                  padding: "0 8px",
                }}
                onClick={handleTopLevelMenuOpen}
              />
            )}

          <Menu
            anchorEl={topLevelMenuAnchor}
            open={topLevelMenuOpen}
            onClose={handleTopLevelMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            disableScrollLock={true}
          >
            <MenuItem
              onClick={() => {
                setOpenAddNewQuestionToQuestionnaireDialog(true);
                handleTopLevelMenuClose();
              }}
            >
              <ListItemText>Add Agreement Property ...</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setopenDialogAddNewMergeFieldQuestion(true);
                handleTopLevelMenuClose();
              }}
            >
              <ListItemText>Add Merge Field ...</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setOpenChangeQuestionOrderDialog(true);
                handleTopLevelMenuClose();
              }}
            >
              <ListItemText>Change order ...</ListItemText>
            </MenuItem>
          </Menu>

          <DialogAddNewQuestionToQuestionnaire
            open={openAddNewQuestionToQuestionnaireDialog}
            close={() => {
              setSelectedMergeField(defaultSelectedMergeField);
              setOpenAddNewQuestionToQuestionnaireDialog(false);
              loadDocumentMergeFields();
            }}
            mergeField={selectedMergeField}
            submit={handleSubmit}
            agrvId={state.drawerVersions.versions.at(0)._id}
            organizationId={organizationId}
            organizationName={organizationName}
            documentIsTemplate={documentIsTemplate}
            partyId={partyId}
            agreementTypeId={agreementTypeId}
            agreementLabelsIds={agreementLabelsIds}
          />

          <DialogAddNewMergeFieldQuestion
            open={openDialogAddNewMergeFieldQuestion}
            close={() => {
              setopenDialogAddNewMergeFieldQuestion(false);
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          backgroundColor: "white",
          overflow: "auto",
        }}
        px={2}
      >
        {mergeFields.length ? (
          <>
            <Stepper nonLinear activeStep={activeStep} orientation={"vertical"}>
              {mergeFields.map((mergeField, index) => (
                <Step key={index}>
                  <Grid container>
                    <Grid item xs={11}>
                      <StepButton onClick={() => handleStep(mergeField, index)}>
                        <div
                          style={{
                            wordBreak: "break-word",
                            maxWidth: "200px",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {mergeField.name}
                        </div>
                      </StepButton>
                    </Grid>

                    {index === activeStep && (
                      <Grid item xs={1}>
                        {state.user.role.name !== "Counterparty" &&
                          state.drawerVersions?.active?._id ===
                            state.drawerVersions.versions[0]?._id && (
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              color={theme.palette.grey[800]}
                              style={{
                                fontSize: "16px",
                                marginLeft: "auto",
                                cursor: "pointer",
                                padding: "0 8px",
                              }}
                              onClick={(event) => {
                                if (mergeField.scope === "questionnaire") {
                                  return handleQuestionMenuOpen(event);
                                }

                                if (mergeField.scope === "document") {
                                  return handleMergeFieldMenuOpen(event);
                                }
                              }}
                            />
                          )}

                        {mergeField.scope === "questionnaire" && (
                          <Menu
                            anchorEl={questionMenuAnchor}
                            open={questionMenuOpen}
                            onClose={handleQuestionMenuClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            disableScrollLock={true}
                          >
                            <MenuItem onClick={handleQuestionMenuClose}>
                              <ListItemText
                                onClick={() => {
                                  setSelectedMergeField(mergeField);
                                  setOpenAddNewQuestionToQuestionnaireDialog(
                                    true
                                  );
                                }}
                              >
                                Edit Property ...
                              </ListItemText>
                            </MenuItem>

                            <MenuItem onClick={handleQuestionMenuClose}>
                              <ListItemText
                                onClick={() => {
                                  setSelectedMergeField(mergeField);
                                  setOpenRemoveQuestionModal(true);
                                }}
                              >
                                Remove Property ...
                              </ListItemText>
                            </MenuItem>
                          </Menu>
                        )}

                        {mergeField.scope === "document" && (
                          <Menu
                            anchorEl={mergeFieldMenuAnchor}
                            open={mergeFieldMenuOpen}
                            onClose={handleMergeFieldMenuClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            disableScrollLock={true}
                          >
                            <MenuItem
                              onClick={() => {
                                dispatch({
                                  type: "OPEN_MERGE_FIELD_MENU",
                                  payload: mergeField,
                                });
                                handleMergeFieldMenuClose();
                              }}
                            >
                              <ListItemText>Edit Merge Field ...</ListItemText>
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                setSelectedMergeField(mergeField);
                                setOpenDialogRemoveMergeField(true);
                                handleMergeFieldMenuClose();
                              }}
                            >
                              <ListItemText>
                                Remove Merge Field ...
                              </ListItemText>
                            </MenuItem>
                          </Menu>
                        )}
                      </Grid>
                    )}
                  </Grid>

                  <StepContent>
                    <Typography
                      component={"div"}
                      sx={{ fontWeight: "bold", whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          wordBreak: "break-word",
                          maxWidth: "250px",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {mergeField.wizardQuestion}
                      </div>
                    </Typography>

                    <br />

                    <Typography
                      component={"div"}
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "14px",
                        color: "grey",
                      }}
                    >
                      <div
                        style={{
                          wordBreak: "break-word",
                          maxWidth: "250px",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {mergeField.wizardGuidance}
                      </div>
                    </Typography>

                    <br />

                    {renderMergeFieldValueControls(mergeField)}

                    <br />

                    <Box sx={{ mb: 2 }}>
                      <Box>
                        <Button
                          disabled={index === 0}
                          variant="outlined"
                          onClick={() => handleBack(mergeFields[index - 1])}
                          sx={{ mt: 1, mr: 1 }}
                          size="small"
                        >
                          Back
                        </Button>

                        <Button
                          disabled={index === mergeFields.length - 1}
                          variant="contained"
                          disableElevation
                          onClick={() => handleNext(mergeFields[index + 1])}
                          sx={{ mt: 1, mr: 1 }}
                          size="small"
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </>
        ) : (
          <>
            <div
              style={{
                whiteSpace: "normal",
              }}
            >
              <i style={{ fontSize: "14px" }}>
                Any questions related to Agreement Properties or Merge Fields
                will automatically appear here.
              </i>
            </div>
          </>
        )}
      </Box>

      <DialogRemoveQuestion
        open={openRemoveQuestionModal}
        close={() => {
          setOpenRemoveQuestionModal(false);
          setSelectedMergeField(defaultSelectedMergeField);
        }}
        mergeField={selectedMergeField}
        submit={async () => {
          const url = `${state.settings.api}mergefield/${selectedMergeField._id}`;
          await axios.delete(url).catch((error) => {
            console.error(error);
          });
          loadDocumentMergeFields();
          setOpenRemoveQuestionModal(false);
        }}
      />

      {openChangeQuestionOrderDialog && (
        <DialogChangeQuestionOrder
          open={openChangeQuestionOrderDialog}
          close={() => {
            setOpenChangeQuestionOrderDialog(false);
          }}
          mergeFields={mergeFields}
          submit={async (orderedMergeFields) => {
            await bulkUpdateMergeFields(orderedMergeFields);
            loadDocumentMergeFields();
            setOpenRemoveQuestionModal(false);
          }}
        />
      )}

      {selectedMergeField && openDialogRemoveMergeField && (
        <DialogRemoveMergeField
          open={openDialogRemoveMergeField}
          close={() => {
            setOpenDialogRemoveMergeField(false);
            setSelectedMergeField(defaultSelectedMergeField);
          }}
          mergeField={selectedMergeField}
        />
      )}
    </>
  );
}
