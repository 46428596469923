import { Autocomplete, Grid, TextField } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { currencyCodes, durationUnits } from "./constants";
import { getMergeFieldDisplayValue } from "./utils";

/**
 * @param {import("./../editor/nodes/MarkNode").MergeField} mergeField
 */
export function renderMergeFieldValueControls(mergeField) {
  if (
    !mergeField.name ||
    !mergeField.wizardQuestion ||
    !mergeField.wizardGuidance
  ) {
    return <></>;
  }

  if (mergeField.setValueLater) {
    return (
      <Grid container>
        <TextField
          disabled
          value={"[value to be set later]"}
          variant="outlined"
        />
      </Grid>
    );
  }

  // If Merge Field is a list.
  if (mergeField.isList) {
    if (mergeField.allowSelectingMultipleListOptions) {
      return (
        <Autocomplete
          multiple
          options={mergeField.listOptions}
          value={
            mergeField.listOptions.filter((option) =>
              mergeField.selectedListOptionsIds.includes(option.id)
            ) || null
          }
          autoHighlight
          getOptionLabel={(option) =>
            getMergeFieldDisplayValue(option.mergeFieldValue)
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <>
              <TextField {...params} disabled variant="outlined" />
            </>
          )}
        />
      );
    }

    return (
      <Autocomplete
        options={mergeField.listOptions}
        value={
          mergeField.listOptions.find(
            (option) => option.id === mergeField.selectedListOptionsIds[0]
          ) || null
        }
        autoHighlight
        getOptionLabel={(option) =>
          getMergeFieldDisplayValue(option.mergeFieldValue)
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <>
            <TextField {...params} disabled variant="outlined" />
          </>
        )}
      />
    );
  }

  const mergeFieldType = mergeField.value.type;

  switch (mergeFieldType) {
    case "freeText":
      return (
        <Grid container>
          <TextField
            disabled
            autoFocus={true}
            value={mergeField.value.value}
            variant="outlined"
          />
        </Grid>
      );

    case "date":
      return (
        <Grid container>
          <TextField
            disabled
            autoFocus={true}
            value={
              mergeField.value.value
                ? format(new Date(mergeField.value.value), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM-dd")
            }
            type="date"
            variant="outlined"
          />
        </Grid>
      );

    case "duration":
      return (
        <Grid container direction={"row"} justifyContent={"center"}>
          <Grid item xs={5}>
            <TextField
              type="number"
              autoFocus={true}
              value={mergeField.value.durationValue}
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={7} sx={{ paddingLeft: "10px" }}>
            <Autocomplete
              value={mergeField.value.durationUnit}
              options={durationUnits}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Duration Unit"
                    placeholder="Select Duration Unit ..."
                    variant="outlined"
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      );

    case "number":
      return (
        <Grid container>
          <TextField
            disabled
            autoFocus={true}
            type="number"
            value={mergeField.value.value}
            variant="outlined"
          />
        </Grid>
      );

    case "currency":
      return (
        <Grid container direction={"row"} justifyContent={"center"}>
          <Grid item xs={6}>
            <Autocomplete
              options={currencyCodes}
              autoHighlight
              getOptionLabel={(option) => option.label}
              value={mergeField.value.currencyUnit}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Currency Unit"
                    placeholder="Select Currency Unit ..."
                    variant="outlined"
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={6} sx={{ paddingLeft: "10px" }}>
            <TextField
              autoFocus={true}
              type="number"
              value={mergeField.value.currencyValue}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
      );

    case "percentage":
      return (
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <TextField
              type="number"
              autoFocus={true}
              value={mergeField.value.value}
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
            <div>{"%"}</div>
          </Grid>
        </Grid>
      );

    default:
      throw new Error(`${mergeFieldType} is not a valid Merge Field type.`);
  }
}
