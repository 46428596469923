import {
  faCaretDown,
  faClock,
  faPen,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { getColorForPartyID, trunc } from "../../../utils";
import { getCanveoTier } from "../../../utils/getCanveoTier";
import RemindersSection from "./RemindersSection";

/**
 * @typedef {*} SignersListSectionProps
 */

/**
 * @param {*} props
 * @returns {React.JSX.Element}
 */
export default function SignersListSection({
  signConfig,
  signers,
  signingOrder,
  reminders,
  isOwner,
  owner,
  handleSignersChange,
  handleOrganizationSelection,
  handleChangeReminder,
  handleSigningOrderChange,
  handleEditUser,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [anchorElSig, setAnchorElSig] = useState(null);
  const theme = useTheme();

  const styles = {
    signQualityBox: {
      width: "260px",
      cursor: "pointer",
    },
    signQualityCaret: {
      color: theme.palette.primary.main,
      marginLeft: "5px",
    },
    menuOption: {
      fontWeight: "500",
      paddingTop: "7px",
      paddingBottom: "7px",
      width: "260px",
    },
  };

  /**
   * @param {string} actionType
   * @param {*} actionDetail
   */
  const handleSignBoxAction = (actionType, actionDetail) => {
    if (actionType === "remove") {
      let newSigners = signers.filter(
        (/** @type {{ _id: any; }} */ s) => s._id !== actionDetail
      );
      handleSignersChange(newSigners);
    }

    if (actionType === "edit") {
      handleEditUser(
        signers.find(
          (/** @type {{ _id: string; }} */ s) => s._id === actionDetail
        )
      );
    }
  };

  const handleSigQualitySelect = (/** @type {string} */ newSigQuality) => {
    console.log("todo new sig quality", newSigQuality);
    setAnchorElSig(null);
  };

  const reorder = (
    /** @type {Iterable<any> | ArrayLike<any>} */ list,
    /** @type {number} */ startIndex,
    /** @type {number} */ endIndex
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   *
   * @param {{ destination: { index: any; }; source: { index: any; }; }} result
   * @returns
   */
  const onDragEnd = (result) => {
    // Loop through the ordered list of signatories and assign to the respective objects.
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const orderedList = signers.sort(
      (
        /** @type {{ order: number; }} */ a,
        /** @type {{ order: number; }} */ b
      ) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0)
    );

    const reorderedSigners = reorder(
      orderedList,
      result.source.index,
      result.destination.index
    ).map((signer, index) => ({
      ...signer,
      order: index,
    }));

    handleSignersChange([...reorderedSigners]);
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {getCanveoTier(state?.user?.email) === "experimental" && (
            <>
              {signConfig ? (
                <>
                  {state.user.role.name !== "Counterparty" && (
                    <Box sx={styles.signQualityBox}>
                      <Typography
                        variant="body1"
                        // @ts-ignore
                        onClick={(e) => setAnchorElSig(e.currentTarget)}
                        color={theme.palette.primary.main}
                        sx={{ fontWeight: 600 }}
                      >
                        {Boolean(signConfig.quality) &&
                        ["SES"].includes(signConfig.quality)
                          ? "Simple Electronic Signature (SES)"
                          : Boolean(signConfig.quality) &&
                            ["AES"].includes(signConfig.quality.substring(0, 3))
                          ? "Advanced Signature (SES)"
                          : Boolean(signConfig.quality) &&
                            ["QES"].includes(signConfig.quality.substring(0, 3))
                          ? "Qualified Signature (SES)"
                          : "SES"}
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          style={styles.signQualityCaret}
                        />
                      </Typography>
                    </Box>
                  )}

                  <Menu
                    anchorEl={anchorElSig}
                    keepMounted
                    open={Boolean(anchorElSig)}
                    onClose={() => setAnchorElSig(null)}
                  >
                    <MenuItem
                      onClick={() => handleSigQualitySelect("SES")}
                      style={styles.menuOption}
                      disabled={!isOwner}
                    >
                      Simple Electronic Signature (SES)
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                "Unable to display signature setup"
              )}
            </>
          )}

          {isOwner && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={signingOrder}
                  onChange={handleSigningOrderChange}
                />
              }
              sx={{ marginRight: 0 }}
              disabled={state.org.orgType !== "cust"}
              label={
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary.main}
                  sx={{ fontWeight: 600 }}
                >
                  Signing order
                </Typography>
              }
            />
          )}

          {getCanveoTier(state?.user?.email) === "experimental" &&
            reminders === null &&
            isOwner && (
              <Button
                disableElevation
                color="primary"
                variant="text"
                onClick={() => handleChangeReminder("7")}
                sx={{ marginRight: "12px" }}
              >
                <FontAwesomeIcon icon={faClock} />
                &nbsp;&nbsp;Reminders
              </Button>
            )}
        </Box>

        {reminders !== null && reminders !== undefined && (
          <RemindersSection
            tooltip="Automatic email reminders will be sent until the agreement is signed."
            reminders={reminders}
            handleChangeReminder={handleChangeReminder}
          />
        )}
      </Box>

      {signingOrder ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="flex-start"
          sx={{ mt: 3, minHeight: "120px" }}
        >
          <Box sx={{ my: 2 }}>
            {/* @ts-ignore */}
            <DragDropContext
              // @ts-ignore
              onDragEnd={onDragEnd}
            >
              <Column
                id="1"
                signers={signers}
                parties={state.parties}
                // @ts-ignore
                signConfig={signConfig}
                activeUser={state.user}
                handleSignBoxAction={handleSignBoxAction}
              />
            </DragDropContext>
          </Box>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ minHeight: "120px" }}
        >
          {
            // SignBlocks for scenario without a signing order
            // TODO: Create component to reuse eac grid item
            state.parties.map(
              (
                /** @type {{ orgID: React.Key | null | undefined; legalName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; role: any; partyID: string; myClient: boolean; }} */ party
              ) => (
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="flex-start"
                  mb={2}
                  key={party.orgID}
                >
                  <Typography
                    display="block"
                    // @ts-ignore
                    variant="subtitle"
                    sx={{ fontWeight: 600 }}
                    pb={1}
                  >
                    {party.legalName}{" "}
                    {party.role && party.role !== "null"
                      ? `(${party.role})`
                      : ""}{" "}
                    {party.myClient && "(Client)"}
                  </Typography>
                  <Grid item container>
                    {signers
                      .filter(
                        (/** @type {{ orgID: any; }} */ s) =>
                          s.orgID === party.orgID
                      )
                      .map(
                        (
                          /** @type {{ _id: React.Key | null | undefined; orgID: any; }} */ signer
                        ) => (
                          <Grid item key={signer._id} xs={12} sm={6}>
                            <RenderSignBox
                              signer={signer}
                              partyID={party ? party.partyID : null}
                              // @ts-ignore
                              signConfig={signConfig}
                              hasActions={
                                signer.orgID === state.user.orgID || isOwner
                              }
                              canEdit={
                                (!isOwner &&
                                  signer.orgID === state.user.orgID) ||
                                (isOwner && signer.orgID !== owner)
                              }
                              handleSignBoxAction={handleSignBoxAction}
                            />
                          </Grid>
                        )
                      )}
                  </Grid>
                  {(isOwner || party.orgID === state.org._id) && (
                    <Button
                      // @ts-ignore
                      color={getColorForPartyID(party.partyID, "", "", true)}
                      onClick={() => handleOrganizationSelection(party.orgID)}
                    >
                      Add signer ...
                    </Button>
                  )}
                </Grid>
              )
            )
          }
        </Grid>
      )}
    </Box>
  );
}

/**
 * @typedef {*} RenderSignBoxProps
 */

/**
 *
 * @param {RenderSignBoxProps} props
 * @returns {React.JSX.Element}
 */
function RenderSignBox({
  signer,
  partyID,
  hasActions,
  canEdit,
  handleSignBoxAction,
}) {
  return (
    <Box
      sx={{
        border: "1px solid" + theme.palette.grey[200],
        borderBottom: "2px solid" + getColorForPartyID(partyID),
        borderRadius: "20px 20px 0px 00px",
        padding: "15px 20px",
        width: "240px",
        backgroundColor: theme.palette.grey[50],
        margin: "5px 0px 10px 0px",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Avatar src={signer.photoURL} alt={signer.displayName} />
        </Grid>
        <Grid item sx={{ pl: 3, width: "150px" }}>
          {hasActions && (
            <Box
              sx={{
                textAlign: "right",
                marginTop: "-10px",
                marginRight: "-18px",
                marginBottom: "-14px",
              }}
            >
              {canEdit && (
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={() => handleSignBoxAction("edit", signer._id)}
                  style={{
                    color: getColorForPartyID(partyID),
                    cursor: "pointer",
                    marginRight: "2.5px",
                    fontSize: "14px",
                  }}
                />
              )}
              <FontAwesomeIcon
                icon={faTimesCircle}
                onClick={() => handleSignBoxAction("remove", signer._id)}
                style={{
                  color: getColorForPartyID(partyID),
                  cursor: "pointer",
                  marginLeft: "2.5px",
                  fontSize: "14px",
                }}
              />
            </Box>
          )}
          <Typography sx={{ fontWeight: "700" }} pt={2}>
            {trunc(signer.displayName, 14)}
          </Typography>
          <Typography variant="body2">{trunc(signer.title, 19)}</Typography>
          <Typography variant="body2">
            {trunc(signer.entityName, 19)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {trunc(signer.email, 19)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

/**
 * @typedef {*} ColumnProps
 */

/**
 * @param {ColumnProps} props
 */
function Column(props) {
  const { id, signers, parties, sigConfig, activeUser, handleSignBoxAction } =
    props;

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {signers.map(
            (/** @type {{ _id: any; }} */ signer, /** @type {any} */ index) => {
              return (
                <Task
                  id={signer._id}
                  key={index}
                  index={index}
                  signer={signer}
                  parties={parties}
                  sigConfig={sigConfig}
                  activeUser={activeUser}
                  handleSignBoxAction={handleSignBoxAction}
                />
              );
            }
          )}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

/**
 * @typedef {*} TaskProps
 */

/**
 * @param {TaskProps} props
 */
function Task(props) {
  const {
    id,
    index,
    signer,
    parties,
    sigConfig,
    activeUser,
    handleSignBoxAction,
  } = props;

  return (
    <Draggable
      draggableId={id}
      key={id}
      index={index}
      isDragDisabled={activeUser.role === "Counterparty"}
    >
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item xs={1}>
              <Box>{index + 1}.</Box>
            </Grid>
            <Grid item xs={10} sm={6}>
              <RenderSignBox
                key={signer._id}
                signer={signer}
                partyID={
                  parties.find(
                    (/** @type {{ orgID: any; }} */ p) =>
                      p.orgID === signer.orgID
                  )
                    ? parties.find(
                        (/** @type {{ orgID: any; }} */ p) =>
                          p.orgID === signer.orgID
                      ).partyID
                    : null
                }
                sigConfig={sigConfig}
                hasActions={
                  signer.orgID === activeUser.orgID ||
                  (Boolean(
                    parties.filter(
                      (/** @type {{ orgID: any; }} */ p) =>
                        p.orgID === signer.orgID
                    )[0]
                  ) &&
                    parties.filter(
                      (/** @type {{ orgID: any; }} */ p) =>
                        p.orgID === signer.orgID
                    )[0].ownerOrgID === activeUser.orgID)
                }
                handleSignBoxAction={handleSignBoxAction}
              />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
      )}
    </Draggable>
  );
}
