import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { globalStore } from "../state/store";

export default function useLabelService(fetchTypes = false) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [errorMessage, setErrorMessage] = useState(
    /** @type {unknown | string} */ ("")
  );
  const [loading, setLoading] = useState(false);
  const [labelTypes, setLabelTypes] = useState([]);

  const getLabelTypes = useCallback(
    async () => {
      try {
        setLoading(true);
        setErrorMessage("");

        const types = await axios.get(
          `${state.settings.api}label/types/${state.user.orgID}`
        );
        setLoading(false);
        setLabelTypes(types.data.data);
      } catch (e) {
        setLoading(false);
        setErrorMessage(e);
      }
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (fetchTypes) getLabelTypes();
    },
    // Runs only when the reference to `getLabelTypes` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getLabelTypes]
  );

  /**
   * @param {string} labelName
   * @returns {Promise<*>}
   */
  async function checkLabelName(labelName) {
    try {
      const result = await axios.get(`${state.settings.api}label/${labelName}`);
      if (!result?.data) return "Unable to verify if the label can be deleted";
      if (result.data.data) return "This label name is already in use";
    } catch (error) {
      return "An error occurred while verifying the label";
    }
  }

  /**
   * @param {{ name: string }} label
   * @returns {Promise<*>}
   */
  async function createLabel(label) {
    const error = await checkLabelName(label.name);
    if (error) {
      setErrorMessage(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: error, severity: "error" },
      });
      return false;
    }
    try {
      setLoading(true);
      setErrorMessage("");

      const newLabel = await axios.post(state.settings.api + "label", {
        label: { ...label, orgID: state.user.orgID, active: true },
      });
      dispatch({ type: "ADD_LABEL", payload: newLabel.data.data });
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: "Label created", severity: "success" },
      });
      setLoading(false);
      return newLabel.data.data;
    } catch (e) {
      setLoading(false);
      setErrorMessage(e);
      return null;
    }
  }

  /**
   * @param {*} label
   * @param {boolean} hasNameChanged
   * @returns {Promise<*>}
   */
  async function updateLabel(label, hasNameChanged) {
    let error = "";
    if (hasNameChanged) error = await checkLabelName(label.name);
    if (error) {
      setErrorMessage(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: error, severity: "error" },
      });
      return false;
    }
    try {
      setLoading(true);
      setErrorMessage("");
      const updatedLabel = await axios.put(
        `${state.settings.api}label/${label._id}`,
        {
          label: label,
        }
      );
      dispatch({ type: "UPDATE_LABEL", payload: updatedLabel.data.data });
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: "Label updated", severity: "success" },
      });
      setLoading(false);
      return updatedLabel.data.data;
    } catch (e) {
      setLoading(false);
      setErrorMessage(e);
      return null;
    }
  }

  /**
   * @param {*} label
   * @returns {Promise<*>}
   */
  async function canDeactivateLabel(label) {
    try {
      const areaLabels = state.labels.filter(
        (/** @type {{ type: { name: string; }; }} */ l) =>
          l.type.name === "Area"
      );
      if (label.type.name === "Area" && areaLabels.length === 1) {
        return "There needs to be at least one area label at all times";
      }

      const result = await axios.get(
        `${state.settings.api}label/check/${label._id}`
      );

      if (!result?.data) return "Unable to verify if the label can be deleted";

      if (result.data.data) {
        return "This label is currently in use and cannot be deleted";
      }
    } catch (e) {
      return "An error occurred while verifying the label";
    }
  }

  /**
   * @param {*} label
   * @returns {Promise<*>}
   */
  const deactivateLabel = async (label) => {
    const error = await canDeactivateLabel(label);
    if (error) {
      setErrorMessage(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: error, severity: "error" },
      });
      return false;
    }

    try {
      setLoading(true);
      setErrorMessage("");
      const updatedLabel = await axios.put(
        `${state.settings.api}label/${label._id}`,
        {
          label: { ...label, active: false },
        }
      );

      dispatch({ type: "REMOVE_LABEL", payload: updatedLabel.data.data });
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: "Label deleted", severity: "success" },
      });
      setLoading(false);
      return updatedLabel.data.data;
    } catch (e) {
      setLoading(false);
      setErrorMessage(e);
      return null;
    }
  };

  function resetState() {
    setLoading(false);
    setErrorMessage("");
  }

  return {
    errorMessage,
    loading,
    createLabel,
    updateLabel,
    deactivateLabel,
    resetState,
    labelTypes,
  };
}
