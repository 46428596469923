import { faEllipsisVertical, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
  toggleButtonGroupClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logoPng from "../../assets/img/ct-logo.png";
import pdfPng from "../../assets/img/pdf.png";
import useExhibitService from "../../hooks/useExhibitService";
import useFileService from "../../hooks/useFileService";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { fileSources } from "../../utils/fileSourceMapping";
import { getCanveoTier } from "../../utils/getCanveoTier";
import mapOrigin from "../../utils/mapOrigin";
import EditableField from "../EditableField";
import DialogConfirmDelete from "../dialogs/DialogConfirmDelete";

const dateTimeFormat = "D MMM YYYY, h:mm A";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));

const ExhibitBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  position: "relative",
  cursor: !selected ? "pointer" : "",
  borderRadius: "15px",
  backgroundColor: selected ? alpha(theme.palette.primary.main, 0.15) : "",

  whiteSpace: "initial",

  "&:hover": {
    backgroundColor: !selected ? theme.palette.grey[200] : "",
  },

  "&:first-of-type": {
    marginTop: 0,
  },

  "&:last-child": {
    marginBottom: 0,
  },
}));

/**
 * @param {*} _
 */
function ExhibitDrawerItem({
  exhibit,
  isTemplate,
  hasPen,
  mainBody,
  handleExport,
  isInEffect,
  displayMode,
  setDisplayMode,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [latestVersion] = state.drawerVersions.versions;
  const { tid, aid } = useParams();
  const navigate = useNavigate();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [editExhibitName, setEditExhibitName] = useState({
    editing: false,
    value: null,
  });
  const [deletingExhibit, setDeletingExhibit] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [canEditName, setCanEditName] = useState(false);
  const [preventEditsByCounterpartyOpen, setPreventEditsByCounterpartyOpen] =
    useState(false);

  const { downloadFile } = useFileService();
  const { updateExhibit, deleteExhibit } = useExhibitService();

  const {
    _id,
    agrTitle,
    parentID,
    priority,
    creationDate,
    creationBy,
    fileSource,
    origin,
    visibleTo,
  } = exhibit;
  const active = tid === _id || aid === _id;

  useEffect(() => {
    const { canEditName, canDelete } = getPermissionStatus();

    const isCounterparty = state.user.role.name === "Counterparty";

    const menuItems = [];

    if (!(getCanveoTier(state?.user?.email) === "stable" && isCounterparty)) {
      menuItems.push({
        text: "Edit name",
        action: () => initEditExhibitName(),
        hidden: !canEditName || isInEffect,
      });
    }

    menuItems.push(
      // {
      //   text: "Export ...",
      //   action: () => handleExport(),
      // },
      {
        text: "Delete exhibit ...",
        action: () => setDeletingExhibit(true),
        hidden: !canDelete || isInEffect,
      }
    );

    if (
      state.user.role.name !== "Counterparty" &&
      getCanveoTier(state?.user?.email) === "experimental"
    ) {
      menuItems.push({
        text: "Prevent edits by counterparty ...",
        action: () => {
          setPreventEditsByCounterpartyOpen(true);
        },
        hidden: false,
      });
    }

    const filteredMenuItems = menuItems.filter((item) => !item.hidden);
    setCanEditName(canEditName);
    setMenuItems(filteredMenuItems);
  }, [isTemplate, state.org, state.user, hasPen, exhibit]);

  const getPermissionStatus = () => {
    // This part only affects agreements, because if it's a template the menu items are always shown (for now)
    const mainBodyVersion = state.avs?.find(
      (version) => version.agrID === mainBody?._id
    );
    const editMode = mainBodyVersion?.owner?.find(
      (o) => o.orgID === state.org._id
    )?.editMode;

    const canEdit = ["full", "edit"].includes(editMode) && hasPen;
    const isOwner = state.org._id === mainBody?.owner;
    const isOnlyVisibleToOrg = visibleTo?.every((v) => v === state.org._id);

    const canEditName =
      isTemplate ||
      (!isOwner && canEdit) ||
      (isOwner && (hasPen || isOnlyVisibleToOrg));

    const canDelete =
      mainBody?._id !== _id &&
      (isTemplate || (isOwner && (hasPen || isOnlyVisibleToOrg)));

    return { canEditName, canDelete };
  };

  const initEditExhibitName = () => {
    const { canEditName } = getPermissionStatus();
    if (!canEditName) return;

    setEditExhibitName({
      editing: true,
      value: agrTitle,
    });
  };

  const handleDoubleClick = (event, initHandler) => {
    if (event?.detail !== 2 || !initHandler) return;

    initHandler();
  };

  const submitExhibitName = async (clear = false) => {
    const { value } = editExhibitName;
    if ((!value || value === agrTitle) && !clear) return;
    const newExhibit = { ...exhibit, agrTitle: value };

    await updateExhibit(newExhibit, isTemplate);

    setEditExhibitName({ editing: false, value: null });
  };

  const selectExhibit = () => {
    if (active) return;

    const pathname = `/${isTemplate ? "templates" : "agreement"}/${_id}`;
    // const search = createSearchParams({
    //   openedDrawer: "exhibits",
    // }).toString();

    navigate({
      pathname,
      // search,
    });
  };

  const getExhibitTitle = () => {
    if (!parentID) return agrTitle;
    const charCode = String.fromCharCode(96 + priority / 10).toUpperCase();
    return `Exhibit ${charCode}: ${agrTitle}`;
  };

  const getCreationPeriod = () => {
    const period = mainBody?.creationDate === creationDate ? "at " : "after ";
    return `(${period} ${isTemplate ? "template" : "agreement"} creation)`;
  };

  const handleDeleteExhibit = async () => {
    const result = await deleteExhibit(_id, isTemplate);

    setDeletingExhibit(false);
    if (result) {
      navigate(`/${isTemplate ? "templates" : "agreement"}/${mainBody?._id}`);
    }
  };

  const menuClick = (handler) => {
    setMenuAnchor(null);
    handler();
  };

  return (
    <ExhibitBox selected={active} p={1} my={1} onClick={selectExhibit}>
      <Grid
        container
        justifyContent="space-between"
        sx={{ gap: "5px", flexWrap: "noWrap" }}
        py={1}
      >
        {!editExhibitName.editing ? (
          <Typography
            variant="body2"
            color={theme.palette.grey[800]}
            onClick={(event) => handleDoubleClick(event, initEditExhibitName)}
            sx={{
              cursor: canEditName && "pointer",
            }}
          >
            {getExhibitTitle()}
          </Typography>
        ) : (
          <EditableField
            value={editExhibitName.value}
            handleChange={setEditExhibitName}
            handleSubmit={submitExhibitName}
            sx={{
              flexGrow: 1,
            }}
          />
        )}
        {active && !!menuItems.length && (
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            style={{
              cursor: "pointer",
              padding: "0 4px",
            }}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            color={theme.palette.grey[800]}
          />
        )}
      </Grid>
      <Collapse in={active}>
        <Grid container direction="column" sx={{ gap: "16px" }} mt={2}>
          <Grid item container>
            <Grid item xs={4}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                Created by:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {creationBy?.orgID?.shortName}
              </Typography>
            </Grid>
          </Grid>
          {fileSource && (
            <Grid item container>
              <Grid item xs={4}>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  Source:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  {fileSources?.find((fs) => fs.value === fileSource)?.label}
                </Typography>
              </Grid>
            </Grid>
          )}

          {(latestVersion.pdfFileKey || isInEffect) && (
            <Grid item mt={0.5}>
              <Paper
                elevation={0}
                sx={(theme) => ({
                  display: "flex",
                  border: `1px solid ${theme.palette.divider}`,
                  flexWrap: "wrap",
                  width: "72px",
                })}
              >
                <StyledToggleButtonGroup
                  value={displayMode}
                  exclusive
                  onChange={(_event, value) => {
                    setDisplayMode(value);
                  }}
                >
                  <ToggleButton value="canveo" size="small">
                    <img
                      src={logoPng}
                      alt="Canveo"
                      title="Display in Canveo format"
                      width="15px"
                    />
                  </ToggleButton>

                  <ToggleButton value="pdf" size="small">
                    <img
                      src={pdfPng}
                      alt="PDF"
                      title="Display in PDF format"
                      width="15px"
                    />
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Paper>
            </Grid>
          )}

          <Grid item container>
            <Grid item xs={4}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                Basis:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                color={theme.palette.grey[800]}
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {mapOrigin(origin, downloadFile).map((origin, index) => (
                  <span key={index} style={{ display: "block" }}>
                    {origin}
                  </span>
                ))}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={4}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                Added:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {dayjs(creationDate).format(dateTimeFormat)}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {getCreationPeriod()}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {creationBy?.displayName} ({creationBy?.orgID?.shortName})
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>

      {!!menuItems.length && (
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableScrollLock={true}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={() => menuClick(item.action)}>
              <ListItemText>{item.text}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}

      {deletingExhibit && (
        <DialogConfirmDelete
          open={deletingExhibit}
          title="Delete Exhibit"
          message={`Are you sure you want to delete the exhibit ${agrTitle}?`}
          handleClose={() => setDeletingExhibit(false)}
          handleConfirm={handleDeleteExhibit}
        />
      )}

      {preventEditsByCounterpartyOpen && (
        <Dialog
          open={preventEditsByCounterpartyOpen}
          onClose={() => {
            setPreventEditsByCounterpartyOpen(false);
          }}
          fullWidth
          maxWidth="sm"
        >
          <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
            <IconButton
              onClick={() => {
                setPreventEditsByCounterpartyOpen(false);
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{ padding: "4px 7px", fontSize: "20px" }}
              />
            </IconButton>
          </Box>
          <DialogTitle>Prevent Edits by Counterparties</DialogTitle>
          <DialogContent sx={{ my: 3 }}>
            <Grid>
              Are you sure you want to prevent edits by your counterparties on
              this exhibit?
            </Grid>

            <br />

            <Grid>
              This will override the edit authorization you set when sending the
              agreement. (Your counterparty will still be able to add comments.)
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPreventEditsByCounterpartyOpen(false);
              }}
              sx={{ marginRight: "auto" }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                setPreventEditsByCounterpartyOpen(false);
              }}
              variant="contained"
              disableElevation
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ExhibitBox>
  );
}

export default ExhibitDrawerItem;
