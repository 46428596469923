import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useProperties } from "../../hooks/useProperties";
import { globalStore } from "../../state/store";
import CanveoCircularProgress from "../CanveoCircularProgress";
import { ASSIGN_PROPERTIES_COMMAND } from "../editor/commands";

/**
 * @import { Property } from "../../hooks/useProperties"
 * @typedef {object} DialogAssignPropertiesToClauseProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {string} activeClauseNodeKey
 * @property {boolean} [onlyTemplateProperties]

*/
/**
 * @param {DialogAssignPropertiesToClauseProps} props
 * @returns {JSX.Element}
 */
export function DialogAssignPropertiesToClause({
  open,
  close,
  activeClauseNodeKey,
  onlyTemplateProperties = false,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const latestVersionId = state?.drawerVersions?.versions?.at(0)?._id || "";
  const [editor] = useLexicalComposerContext();

  const { getVersionProperties, isLoading } = useProperties();

  const [properties, setProperties] = useState(/** @type {Property[]} */ ([]));
  const [selectedProperties, setSelectedProperties] = useState(
    /** @type {Property[]} */ ([])
  );

  useEffect(
    () => {
      getVersionProperties(latestVersionId, onlyTemplateProperties).then(
        setProperties
      );
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>
        {isLoading ? "Loading ..." : "Assign Properties to a Clause"}
      </DialogTitle>

      {isLoading ? (
        <Grid container justifyContent="center" mt={2} mb={4}>
          <CanveoCircularProgress />
        </Grid>
      ) : (
        <>
          <DialogContent>
            <Grid container justifyContent="center" my={2}>
              <Grid item>
                <Autocomplete
                  multiple
                  options={properties}
                  getOptionLabel={(property) => property.name}
                  value={selectedProperties}
                  onChange={(_event, value) => setSelectedProperties(value)}
                  openOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Properties"
                      placeholder="Select ..."
                      autoFocus
                    />
                  )}
                  sx={{ width: 300 }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button sx={{ marginRight: "auto" }} onClick={close}>
              Cancel
            </Button>

            <Button
              color="primary"
              variant="contained"
              disableElevation
              disabled={selectedProperties.length === 0}
              onClick={() => {
                const propertiesIds = selectedProperties.map((p) => p._id);
                editor.dispatchCommand(ASSIGN_PROPERTIES_COMMAND, {
                  activeClauseNodeKey,
                  propertiesIds,
                });
                close();
                dispatch({
                  type: "NEW_SNACKBAR",
                  payload: {
                    severity: "success",
                    message: "Successfully assigned properties to clause.",
                  },
                });
              }}
            >
              Assign &nbsp;&nbsp;
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
