import {
  addClassNamesToElement,
  removeClassNamesFromElement,
} from "@lexical/utils";
import {
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  ElementNode,
} from "lexical";
import { getColorForPartyID } from "../../../utils";
import { $createRedlineNode } from "./RedlineNode";

/**
 * @typedef {object} ValueWithLabel
 * @property {string} value
 * @property {string} label
 */

/**
 * @typedef {object} FreeTextMergeFieldValue
 * @property {"freeText"} type
 * @property {string} value
 */

/**
 * @typedef {object} DateMergeFieldValue
 * @property {"date"} type
 * @property {string} value The date in the date time string format (simplified format based on ISO 8601).
 */

/**
 * @typedef {object} NumberMergeFieldValue
 * @property {"number"} type
 * @property {string} value
 */

/**
 * @typedef {object} DurationMergeFieldValue
 * @property {"duration"} type
 * @property {string} durationValue
 * @property {ValueWithLabel} durationUnit
 */

/**
 * @typedef {object} CurrencyMergeFieldValue
 * @property {"currency"} type
 * @property {string} currencyValue
 * @property {ValueWithLabel} currencyUnit
 */

/**
 * @typedef {object} PercentageMergeFieldValue
 * @property {"percentage"} type
 * @property {string} value
 */

/** @typedef {FreeTextMergeFieldValue | DateMergeFieldValue | NumberMergeFieldValue | DurationMergeFieldValue | CurrencyMergeFieldValue | PercentageMergeFieldValue} MergeFieldValue */

/** @typedef {"freeText" | "date" | "number" | "duration" | "currency" | "percentage"} MergeFieldValueType */

/**
 * @typedef {object} MergeField
 * @property {string} _id
 * @property {string} name
 * @property {"partyInformation" | "regular" | string & {}} type
 * @property {string} selectedText
 * @property {MergeFieldValueType} valueType
 * @property {MergeFieldValue} value
 * @property {string} displayValue
 * @property {boolean} isTemplate
 * @property {string} partyRole
 * @property {string} editorMarkNodeId
 * @property {string} organizationId
 * @property {string} organizationName
 * @property {boolean} askCounterpartyToConfirm
 * @property {string} editorMarkNodeId
 * @property {boolean} setValueLater
 * @property {boolean} valueBasedOnCondition
 * @property {string} visibilityForCounterparties
 * @property {string} wizardQuestion
 * @property {string} wizardGuidance
 * @property {string} originId
 * @property {import("../../MergeFieldMenu/constants").ListOption[]} listOptions
 * @property {string[]} selectedListOptionsIds
 * @property {boolean} setValueLater
 * @property {string} counterPartyThatCanView
 * @property {import("../../MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition[]} conditions
 * @property {"questionnaire" | "document"} scope
 * @property {string} documentId
 * @property {boolean} isList
 * @property {boolean} allowSelectingMultipleListOptions
 * @property {number} position
 * @property {string} createdAt Creation date string in ISO format.
 * @property {string} updatedAt Last update date string in ISO format.
 * @property {boolean} containsSensitiveInformation
 * @property {boolean} setOrganizationAsRole
 * @property {string[]} areaLabels
 * @property {string[]} agreementTypes
 * @property {{ _id: string; displayName: string; title: string; photoURL: string }} [createdBy]
 */

export class MarkNode extends ElementNode {
  /** @type {string[]} */
  __ids;

  /** @private @type {import("./RedlineNode").NodeMetadata} */
  __metadata;

  /** @private @type {MergeField | undefined} */
  __mergeField;

  /** @private @type {string[]} */
  __resolvedIds;

  /** @private @type {boolean} */
  __isVisible;

  static getType() {
    return "mark";
  }

  /**
   * @param {MarkNode} node
   * @returns {MarkNode}
   */
  static clone(node) {
    const clonedNode = new MarkNode(
      Array.from(node.__ids),
      node.__metadata,
      node.__mergeField,
      node.__resolvedIds,
      node.__key
    );

    // Since we do not pass isVisible in the constructor we need to set it this way.
    // DO NOT USE setIsVisible AS THIS WILL TRIGGER INFINITE CALLS AND STACK ERRORS.
    clonedNode.__isVisible = node.__isVisible;

    return clonedNode;
  }

  /**
   * @param {string[]} ids
   * @param {import("./RedlineNode").NodeMetadata} metadata
   * @param {MergeField} [mergeField]
   * @param {string[]} [resolvedIds]
   * @param {string} [key]
   */
  constructor(ids, metadata, mergeField, resolvedIds, key) {
    super(key);
    this.__ids = ids || [];
    // Uncomment when uploading files for tests.
    // this.__ids =
    //   ids.slice().map((el) => {
    //     if (el.startsWith("cp_")) {
    //       return "cp_410787ef-25b2-4715-8e58-46f0c911a014";
    //     } else if (el.startsWith("pa_")) {
    //       return "pa_410787ef-25b2-4715-8e58-46f0c911a014";
    //     } else {
    //       return el;
    //     }
    //   }) || [];
    this.__metadata = metadata;
    this.__mergeField = mergeField;
    this.__resolvedIds = resolvedIds || [];
    this.__isVisible = true;
  }

  /**
   * @param {*} serializedNode
   */
  static importJSON(serializedNode) {
    const node = $createMarkNode(
      serializedNode.ids,
      serializedNode.metadata,
      serializedNode.mergeField,
      serializedNode.resolvedIds
    ); //clauseTypes, workflows, libIDs, filter, lock
    return node;
  }

  /**
   * @returns {*}
   */
  exportJSON() {
    return {
      ...super.exportJSON(),
      type: "mark",
      ids: this.getIDs(),
      metadata: this.getMetadata(),
      mergeField: this.getMergeField(),
      version: 1,
      resolvedIds: this.getResolvedIds(),
    };
  }

  /**
   * Returns `mergeField` if only has merge field ids.
   * Returns `publicComment` if only has comment ids.
   * Returns `both` if contains both types of ids.
   * Otherwise, throws error.
   *
   * @returns {"mergeField" | "publicComment" | "both"}
   */
  getMarkType() {
    const hasComment = this.__ids.some((i) => i.startsWith("cp"));
    const hasMergeField = this.__ids.some((i) => i.startsWith("pa"));
    if (hasComment && hasMergeField) {
      return "both";
    }

    // If there is a mark node with both a public comment and a
    // merge field, the merge field has precedence.
    if (hasMergeField) {
      return "mergeField";
    }

    if (hasComment) {
      return "publicComment";
    }
    throw new Error("Unable to calculate the type of the mark node.");
  }

  /**
   * @param {*} config
   */
  createDOM(config) {
    const element = document.createElement("mark");
    if (this.getIsVisible() === false) {
      addClassNamesToElement(element, "__invisible");
      return element;
    }

    const hasComment = this.__ids.some((i) => i.startsWith("cp_"));
    const hasMergeField = this.__ids.some((i) => i.startsWith("pa"));
    const hasIds = this.__ids.length;
    if (hasIds) {
      addClassNamesToElement(element, "__markOverlap");

      if (hasComment && hasMergeField) {
        addClassNamesToElement(element, "__markOverlapCPA");
      } else if (hasComment) {
        addClassNamesToElement(element, "__markComment");
        // Commented to prevent yellow color after resolving. Check
        // if this doesn't break anything else.
        // addClassNamesToElement(element, "__markOverlapC");
      } else if (this.__ids.some((i) => i.startsWith("cp"))) {
        addClassNamesToElement(element, "__markOverlapIC");
      } else if (this.__ids.some((i) => i.startsWith("ap"))) {
        addClassNamesToElement(element, "__markOverlapAP");
      } else if (hasMergeField) {
        addClassNamesToElement(element, "__markOverlapPA");
      } else if (hasComment) {
        addClassNamesToElement(element, "__markComment");
      } else if (this.__ids.some((i) => i.startsWith("cp"))) {
        addClassNamesToElement(element, "__markCommentInternal");
      } else if (this.__ids.some((i) => i.startsWith("ap"))) {
        addClassNamesToElement(element, "__markApproval");
      } else if (this.__ids.some((i) => i.startsWith("pa"))) {
        addClassNamesToElement(element, "__markParam");
      }
    }
    if (this.isResolved()) addClassNamesToElement(element, "__resolved");

    element.style.setProperty(
      "--party-color",
      getColorForPartyID(this.getMetadata().partyId)
    );

    element.style.setProperty(
      "--party-color-lighter",
      getColorForPartyID(this.getMetadata().partyId, "", "80")
    );
    return element;
  }

  /**
   * @param {*} prevNode
   * @param {*} element
   * @param {*} config
   */
  updateDOM(prevNode, element, config) {
    if (this.getIsVisible() === false) {
      addClassNamesToElement(element, "__invisible");
      return true;
    }

    const prevIDs = prevNode.__ids;
    const nextIDs = this.__ids;
    const prevIDsCount = prevIDs.length;
    const nextIDsCount = nextIDs.length;
    const overlapTheme = config.theme.markOverlap;

    if (prevIDsCount !== nextIDsCount) {
      if (prevIDsCount === 1) {
        if (nextIDsCount === 2) {
          addClassNamesToElement(element, overlapTheme);
        }
      } else if (nextIDsCount === 1) {
        removeClassNamesFromElement(element, overlapTheme);
      }
    }

    if (this.isResolved()) addClassNamesToElement(element, "__resolved");
    else removeClassNamesFromElement(element, "__resolved");

    return JSON.stringify(prevIDs) !== JSON.stringify(nextIDs);
  }

  importDOM() {
    return null;
  }

  /**
   * @param {string} id
   */
  hasID(id) {
    const ids = this.getIDs();
    for (let i = 0; i < ids.length; i++) {
      if (id === ids[i]) {
        return true;
      }
    }
    return false;
  }

  /** @returns {string[]} */
  getIDs() {
    const self = this.getLatest();
    return $isMarkNode(self) ? self.__ids : [];
  }

  /**
   * @param {string} id
   */
  addID(id) {
    const self = this.getWritable();
    if ($isMarkNode(self)) {
      const ids = self.__ids;
      self.__ids = ids;
      for (let i = 0; i < ids.length; i++) {
        // If we already have it, don't add again
        if (id === ids[i]) return;
      }
      ids.push(id);
    }
  }

  /**
   * @param {string} id
   */
  deleteID(id) {
    const self = this.getWritable();
    if ($isMarkNode(self)) {
      const ids = self.__ids;
      self.__ids = ids;
      for (let i = 0; i < ids.length; i++) {
        if (id === ids[i]) {
          ids.splice(i, 1);
          return;
        }
      }
    }
  }

  /**
   * @param {import("lexical").RangeSelection} selection
   */
  insertNewAfter(selection) {
    const element = this.getParentOrThrow().insertNewAfter(selection);

    if ($isElementNode(element)) {
      const linkNode = $createMarkNode(this.__ids, this.__metadata);
      element.append(linkNode);
      return linkNode;
    }
    return null;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  canBeEmpty() {
    return false;
  }

  isInline() {
    return true;
  }

  /**
   * @param {*} _
   * @param {import("lexical").RangeSelection} selection
   * @param {"clone" | "html"} destination
   */
  extractWithChild(_, selection, destination) {
    if (!$isRangeSelection(selection) || destination === "html") {
      return false;
    }
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = anchor.getNode();
    const focusNode = focus.getNode();
    const isBackward = selection.isBackward();
    const selectionLength = isBackward
      ? anchor.offset - focus.offset
      : focus.offset - anchor.offset;

    return (
      this.isParentOf(anchorNode) &&
      this.isParentOf(focusNode) &&
      this.getTextContent().length === selectionLength
    );
  }

  /**
   * @param {*} destination
   */
  excludeFromCopy(destination) {
    return destination !== "clone";
  }

  getMetadata() {
    const self = this.getLatest();
    return self.__metadata;
  }

  /**
   * @param {import("./RedlineNode").NodeMetadata} metadata
   */
  setMetadata(metadata) {
    const self = this.getWritable();
    self.__metadata = metadata;
  }

  getMergeField() {
    const self = this.getLatest();
    return self.__mergeField;
  }

  /**
   * @param {MergeField} mergeField
   */
  setMergeField(mergeField) {
    const self = this.getWritable();
    self.__mergeField = mergeField;
  }

  getResolvedIds() {
    const self = this.getLatest();
    return self.__resolvedIds;
  }

  /**
   * @param {string[]} resolvedIds
   */
  setResolvedIds(resolvedIds) {
    const self = this.getWritable();
    self.__resolvedIds = resolvedIds;
  }

  isResolved() {
    return this.getIDs().every((id) => this.getResolvedIds().includes(id));
  }

  getIsVisible() {
    const self = this.getLatest();
    return self.__isVisible;
  }

  /**
   * @param {boolean} isVisible
   */
  setIsVisible(isVisible) {
    const self = this.getWritable();
    self.__isVisible = isVisible;
  }
}

/**
 * @param {string[]} ids
 * @param {import("./RedlineNode").NodeMetadata} metadata
 * @param {MergeField} [mergeField]
 * @param {string[]} [resolvedIds]
 */
export function $createMarkNode(ids, metadata, mergeField, resolvedIds = []) {
  return new MarkNode(ids, metadata, mergeField, resolvedIds);
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @return {node is MarkNode}
 */
export function $isMarkNode(node) {
  return node instanceof MarkNode;
}

/**
 * @param {MarkNode} node
 */
export function $unwrapMarkNode(node) {
  const children = node.getChildren();
  let target = null;
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (target === null) {
      node.insertBefore(child);
    } else {
      target.insertAfter(child);
    }
    target = child;
  }
  node.remove();
}

/**
 * @param {import("lexical").RangeSelection} selection
 * @param {boolean} isBackward
 * @param {string} id
 * @param {import("./RedlineNode").NodeMetadata} metadata
 * @param {MergeField | *} [mergeField]
 * @returns {MarkNode}
 */
export function $wrapSelectionInMarkNode(
  selection,
  isBackward,
  id,
  metadata,
  mergeField
) {
  const nodes = selection.getNodes();
  const anchorOffset = selection.anchor.offset;
  const focusOffset = selection.focus.offset;
  const nodesLength = nodes.length;
  const startOffset = isBackward ? focusOffset : anchorOffset;
  const endOffset = isBackward ? anchorOffset : focusOffset;
  const endKey = isBackward ? selection.anchor.key : selection.focus.key;

  let currentNodeParent;
  let currentMarkNode;

  // We only want to wrap adjacent text nodes, line break nodes and inline element
  // nodes. For decorator nodes and block element nodes, we stop at their boundary
  // and start again aftewards, if there are more nodes.
  for (let i = 0; i < nodesLength; i++) {
    const node = nodes[i];
    if ($isElementNode(currentMarkNode) && currentMarkNode.isParentOf(node)) {
      continue;
    }
    const isFirstNode = i === 0;
    const isLastNode = i === nodesLength - 1;
    let targetNode;

    //console.log("nx", node.getTextContent(), node.getKey(), startKey, startOffset, endKey, endOffset)

    if ($isTextNode(node)) {
      const textContentSize = node.getTextContentSize();
      const startTextOffset = isFirstNode ? startOffset : 0;
      const endTextOffset = isLastNode ? endOffset : textContentSize;
      if (startTextOffset === 0 && endTextOffset === 0) {
        continue;
      }

      if (["redline"].includes(node.getType())) {
        const splittedRedlines = splitCustomNode(
          node,
          startTextOffset,
          endTextOffset,
          metadata
        );

        // Preserve previous styling on new redlines.
        for (const splittedRedline of splittedRedlines) {
          if ($isTextNode(splittedRedline)) {
            splittedRedline.setFormat(node.getFormat());
            splittedRedline.setDetail(node.getDetail());
            splittedRedline.setStyle(node.getStyle());
          }
        }

        node.replace(splittedRedlines[0]);

        selection.anchor.key = splittedRedlines[0].getKey();
        selection.anchor.offset = splittedRedlines[0].getTextContent().length;
        selection.focus = selection.anchor;
        selection.insertNodes(splittedRedlines.slice(1));

        targetNode =
          splittedRedlines.length > 1 &&
          (splittedRedlines.length === 3 ||
            (isFirstNode && !isLastNode) ||
            endTextOffset === textContentSize)
            ? splittedRedlines[1]
            : splittedRedlines[0];
      } else {
        // regular textNode
        const splitNodes = node.splitText(startTextOffset, endTextOffset);

        targetNode =
          splitNodes.length > 1 &&
          (splitNodes.length === 3 ||
            (isFirstNode && !isLastNode) ||
            endTextOffset === textContentSize)
            ? splitNodes[1]
            : splitNodes[0];
      }
    } else if ($isElementNode(node) && node.isInline()) {
      //console.log("NODE!", node.getChildren())
      if (["mark"].includes(node.getType())) {
        //let startCursor = node.getKey() !== startKey ? 0 : startOffset
        //let startCursor = node.getChildren().some((c) => c.getKey() === startKey) ? startOffset : 0
        let endCursor = node.getChildren().some((c) => c.getKey() === endKey)
          ? endOffset
          : node.getTextContent().length;
        //let endCursor = node.getKey() !== endKey ? node.getTextContent().length : endOffset

        //const textContentSize = node.getTextContentSize();
        //const startTextOffset = isFirstNode ? startOffset : 0;
        //const endTextOffset = isLastNode ? endOffset : textContentSize;
        //console.log("children", node.getChildren(), node.getTextContent(), startTextOffset, endTextOffset, endOffset)

        node.getChildren().forEach((child) => {
          if ($isTextNode(child)) {
            //console.log("CHILD", child)
            //if(startCursor !== 0) {} // todo: understand scenario - split whereby the child
            if (endCursor > 0 && endCursor < child.getTextContent().length) {
              let splittedNodes = child.splitText(endCursor);
              targetNode = splittedNodes[0];
            } else {
              endCursor = endCursor - child.getTextContent().length;
            }
          }
        });

        //let splittedNodes = node.splitText(0,2);
        //console.log("split!", splittedNodes)
      } else {
        targetNode = node;
      }
    }
    //console.log("cyclex", node, targetNode)
    if (targetNode !== undefined) {
      //console.log("cyclexx")
      if (targetNode && targetNode.is(currentNodeParent)) {
        continue;
      }
      const parentNode = targetNode.getParent();
      if (parentNode == null || !parentNode.is(currentNodeParent)) {
        currentMarkNode = undefined;
      }
      currentNodeParent = parentNode;
      if (currentMarkNode === undefined) {
        currentMarkNode = $createMarkNode([id], metadata, mergeField);

        if (
          $isMarkNode(parentNode) &&
          parentNode.getMarkType() === "mergeField"
        ) {
          const existingMergeField = parentNode.getMergeField();
          if (!existingMergeField) {
            throw new Error("Missing merge field from mark node.");
          }

          // If we are creating a comment on top of an existing merge field
          // we need to copy the merge field data into the mark node as well.
          currentMarkNode.setMergeField(existingMergeField);
        }

        targetNode.insertBefore(currentMarkNode);
      }

      currentMarkNode.append(targetNode);
    } else {
      currentNodeParent = undefined;
      currentMarkNode = undefined;
    }
  }

  if (!currentMarkNode) {
    throw new Error("Something went wrong when creating the mark node.");
  }

  // If the Merge Field display value has been manually changed and is different
  // from the current selection, we need to insert the changes as a redline.
  if (
    mergeField &&
    !mergeField.setValueLater &&
    mergeField.displayValue &&
    // Case insensitive comparison.
    mergeField.displayValue.toLowerCase() !==
      selection.getTextContent().toLowerCase()
  ) {
    const textNodes = currentMarkNode.getAllTextNodes();

    for (let index = 0; index < textNodes.length; index++) {
      const textNode = textNodes[index];
      const proposedDeletion = $createRedlineNode({
        partyID: "party0",
        redlineType: "del",
        date: new Date().toUTCString(),
        metadata,
        text: textNode.getTextContent(),
      });
      proposedDeletion.setFormat(textNode.getFormat());
      proposedDeletion.setDetail(textNode.getDetail());
      proposedDeletion.setStyle(textNode.getStyle());
      textNode.replace(proposedDeletion);

      const isLastItem = index === textNodes.length - 1;
      if (isLastItem) {
        const proposedAddition = $createRedlineNode({
          partyID: "party0",
          redlineType: "add",
          date: new Date().toUTCString(),
          metadata,
          text: mergeField.displayValue,
        });
        proposedAddition.setFormat(textNode.getFormat());
        proposedAddition.setDetail(textNode.getDetail());
        proposedAddition.setStyle(textNode.getStyle());

        currentMarkNode.append(proposedAddition);
      }
    }
  }

  return currentMarkNode;
}

/**
 *
 * @param {import("lexical").TextNode} node
 * @param {number} start
 * @param {number} end
 * @param {import("./RedlineNode").NodeMetadata} metadata
 * @returns
 */
function splitCustomNode(node, start, end, metadata) {
  let returnNodes = [];
  let type = node.getType();

  //type
  //$createMarkNode(ids)
  const newNode = ["redline"].includes(type)
    ? {
        redlineType: node.getRedlineType(),
        partyID: node.getPartyID(),
        metadata: node.getMetadata(),
        date: node.getDate(),
      }
    : {};

  const curIDs = ["mark"].includes(type) ? node.getIDs() : [];
  console.log("curIDs", curIDs);

  const actualStart =
    end !== undefined &&
    end !== null &&
    start !== undefined &&
    start !== null &&
    end < start
      ? end
      : start !== undefined && start !== null
      ? start
      : 0;
  const actualEnd =
    end !== undefined &&
    end !== null &&
    start !== undefined &&
    start !== null &&
    end < start
      ? start
      : end !== undefined &&
        end !== null &&
        end > start &&
        end < node.getTextContent().length
      ? end
      : null;

  if (actualStart > 0) {
    returnNodes.push(
      ["redline"].includes(type)
        ? // @ts-ignore
          $createRedlineNode({
            ...newNode,
            text: node.getTextContent().substring(0, actualStart),
          })
        : $createMarkNode(curIDs, metadata)
    );
  }

  if (actualEnd !== null) {
    returnNodes.push(
      ["redline"].includes(type)
        ? // @ts-ignore
          $createRedlineNode({
            ...newNode,
            text: node.getTextContent().substring(actualStart, actualEnd),
          })
        : $createMarkNode(curIDs, metadata)
    );
    returnNodes.push(
      ["redline"].includes(type)
        ? // @ts-ignore
          $createRedlineNode({
            ...newNode,
            text: node.getTextContent().substring(actualEnd),
          })
        : $createMarkNode(curIDs, metadata)
    );
  } else {
    returnNodes.push(
      ["redline"].includes(type)
        ? // @ts-ignore
          $createRedlineNode({
            ...newNode,
            text: node.getTextContent().substring(actualStart),
          })
        : $createMarkNode(curIDs, metadata)
    );
  }

  if (returnNodes.length === 0) {
    returnNodes.push(node);
  }

  return returnNodes;
}

/**
 * @param {import("lexical").LexicalNode} node
 * @param {number} offset
 */
export function $getMarkIDs(node, offset) {
  /** @type {import("lexical").LexicalNode | null} */
  let currentNode = node;
  while (currentNode !== null) {
    if ($isMarkNode(currentNode)) {
      return currentNode.getIDs();
    } else if (
      $isTextNode(currentNode) &&
      offset === currentNode.getTextContentSize()
    ) {
      const nextSibling = currentNode.getNextSibling();
      if ($isMarkNode(nextSibling)) {
        return nextSibling.getIDs();
      }
    }
    currentNode = currentNode.getParent();
  }
  return null;
}
