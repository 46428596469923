import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../state/store";
import { getCanveoTier } from "../../utils/getCanveoTier";
import SelectEntity from "../SelectEntity";
import SelectOrganization from "../SelectOrganization";
import SelectPartyRole from "../SelectPartyRole";
import { DialogEntity } from "../index";

/**
 * @typedef {object} PartyFormProps
 * @property {*} party
 * @property {*[]} roles
 * @property {(property: string, value: *) => void} handleChange
 * @property {*[]} selectedParties
 */

/**
 * @param {PartyFormProps} props
 */
export default function PartyForm({
  party,
  roles,
  handleChange,
  selectedParties,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const canveoTier = getCanveoTier(state?.user?.email);

  const [orgOptions, setOrgOptions] = useState(/** @type {*[]} */ ([]));
  const [entityOptions, setEntityOptions] = useState(/** @type {*[]} */ ([]));
  const [createEntity, setCreateEntity] = useState(
    /** @type {* | null} */ (null)
  );

  useEffect(
    () => {
      const allEntities = [...state.cpents, ...state.subs];

      const orgOptions = allEntities.reduce((accumulator, current) => {
        if (
          accumulator.some(
            (/** @type {{ _id: string; }} */ organization) =>
              organization._id === current.orgID
          )
        ) {
          return accumulator;
        }

        return [
          ...accumulator,
          {
            _id: current.orgID,
            shortName: current.shortName,
            logoURL: current.logoURL,
            entityCategory: current.entityCategory,
          },
        ];
      }, []);

      if (canveoTier === "stable" && selectedParties.length > 0) {
        const ownerOrgAlreadySelected = selectedParties.some(
          (sp) => sp.orgID === state.org._id
        );

        const filteredOrgOptions = ownerOrgAlreadySelected
          ? orgOptions.filter(
              (/** @type {{ _id: string; }} */ x) => x._id !== state.org._id
            )
          : orgOptions.filter(
              (/** @type {{ _id: string; }} */ x) => x._id === state.org._id
            );

        setOrgOptions(filteredOrgOptions);
        return;
      }

      setOrgOptions(orgOptions);
    },
    // Runs only when these change.
    [selectedParties, state.cpents, state.org._id, state.subs, canveoTier]
  );

  /**
   * @param {*} org
   */
  const onOrgSelection = (org) => {
    let entityOptions;
    if (org._id === state.org._id) {
      entityOptions = [...state.subs];
    } else {
      entityOptions = state.cpents.filter(
        (/** @type {{ orgID: string; }} */ e) => e.orgID === org._id
      );
    }

    handleChange("organization", org);
    setEntityOptions(entityOptions);
    handleChange(
      "entity",
      entityOptions.length === 1 ? entityOptions[0] : null
    );
  };

  /**
   * @param {*} value
   */
  const handleOrgChange = (value) => {
    // Start with creating of array "cptyEntOptions", basically
    // the entities for that organization that can be selected.
    if (!value?.isNetwork && !!value?._id) {
      onOrgSelection(value);
    } else if (value?.toBeCreated) {
      // If creating a new organization, reset the entity (if applicable).
      handleChange("entity", null);
      setCreateEntity({
        // @ts-ignore
        type: "newcp",
        entity: { shortName: value.shortName },
      });
    } else if (!value) {
      // If no organization is selected, then there are no counterparty entity options.
      handleChange("organization", null);
      handleChange("entity", null);
      setEntityOptions([]);
    }
  };

  /**
   * @param {*} value
   */
  const handleEntityChange = (value) => {
    if (value?.toBeCreated) {
      return setCreateEntity({
        // @ts-ignore
        type: "newcp",
        entity: {
          orgID: party.organization._id,
          ownerOrgID: state.org._id,
          shortName: party.organization.shortName,
          legalName: value?.legalName,
          logoURL: party.organization.logoURL,
        },
      });
    }
    handleChange("entity", value);
  };

  /**
   * @param {*} value
   */
  const handleRoleChange = (value) => {
    if (value?.label) handleChange("role", value.label);
    else handleChange("role", value);
  };

  /**
   * @param {*} _
   */
  const handleCreateEntity = ({ ent }) => {
    setCreateEntity(null);
    if (ent?.orgID) {
      handleChange("organization", {
        _id: ent.orgID,
        shortName: ent.shortName,
        logoURL: ent.logoURL,
      });
      handleChange("entity", ent);
    }
  };

  return (
    <>
      <Grid container direction="column" sx={{ gap: "15px" }}>
        <Grid item xs={12}>
          <SelectOrganization
            options={orgOptions.filter(
              (option) =>
                !selectedParties.some(
                  // @ts-ignore
                  (party) => party.shortName === option.shortName
                )
            )}
            value={party.organization}
            handleChange={handleOrgChange}
            openOnFocus
          />
        </Grid>

        {!!party.organization && party.entity?.entityCategory !== "Person" && (
          <Grid item xs={12}>
            <SelectEntity
              options={entityOptions}
              value={party.entity}
              handleChange={handleEntityChange}
              canCreate={party.organization._id !== state.org._id}
            />
          </Grid>
        )}

        {!!party.entity && (
          <Grid item xs={12}>
            <SelectPartyRole
              options={roles}
              value={party.role}
              handleChange={handleRoleChange}
            />
          </Grid>
        )}
      </Grid>

      <DialogEntity
        open={!!createEntity}
        closeDialog={handleCreateEntity}
        // @ts-ignore
        type={createEntity?.type}
        // @ts-ignore
        ent={createEntity?.entity}
      />
    </>
  );
}
