import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

/**
 * @typedef {object} DialogRunAiAnalysisProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {(props: { extractMetadata: boolean, complianceAnalysis: boolean }) => void} submit
 */

/**
 * @param {DialogRunAiAnalysisProps} props
 * @returns {JSX.Element}
 */
export default function DialogRunAiAnalysis({ open, close, submit }) {
  const [extractMetadata, setExtractMetadata] = useState(true);
  const [complianceAnalysis, setComplianceAnalysis] = useState(false);

  useEffect(() => {
    if (complianceAnalysis) {
      setExtractMetadata(true);
    }
  }, [complianceAnalysis]);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Run AI Analysis</DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Checkbox
              checked={extractMetadata}
              disabled={complianceAnalysis}
              onChange={(_event, checked) => setExtractMetadata(checked)}
            />
          </Grid>

          <Grid item>
            <Grid item>
              <Typography fontWeight="bold">Extract metadata</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={1} />

          <Grid item>
            <Grid item>This allows ... TBD</Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" mt={4}>
          <Grid item xs={1}>
            <Checkbox
              checked={complianceAnalysis}
              onChange={(_event, checked) => setComplianceAnalysis(checked)}
            />
          </Grid>

          <Grid item>
            <Grid item>
              <Typography fontWeight="bold">Compliance analysis</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={1} />

          <Grid item>
            <Grid item>This allows ... TBD</Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => {
            close();
            submit({ extractMetadata, complianceAnalysis });
          }}
          disabled={!extractMetadata}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
