import { Button, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { globalStore } from "../state/store";
import DialogRunAiAnalysis from "./dialogs/DialogRunAiAnalysis";

/**
 * @typedef {object} AnalyzeCurrentAgreementVersionButtonProps
 * @property {string} jobStatus
 * @property {(props: { extractMetadata: boolean; complianceAnalysis: boolean; }) => Promise<void>} analyzeUsingAi
 */

/**
 * @param {AnalyzeCurrentAgreementVersionButtonProps} props
 * @returns {JSX.Element}
 */
export function AnalyzeCurrentAgreementVersionButton({
  jobStatus,
  analyzeUsingAi,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [latestVersion] = state.drawerVersions.versions;

  const [openDialogRunAiAnalysis, setOpenDialogRunAiAnalysis] = useState(false);

  const canDisplayButton =
    ["Admin", "Legal"].includes(state?.user?.role?.name) &&
    state?.org?.integrations?.openAi?.apiKey &&
    latestVersion?._id === state?.drawerVersions?.active?._id &&
    !latestVersion.analyzeAgreementVersionJob;

  if (!canDisplayButton) return <></>;

  return (
    <Grid container justifyContent="center" mt={4} mb={3}>
      <Grid item>
        <Button
          variant="outlined"
          disableElevation
          sx={{ height: 30, fontWeight: "600" }}
          disabled={jobStatus === "ongoing"}
          onClick={(_event) => {
            setOpenDialogRunAiAnalysis(true);
          }}
        >
          {jobStatus === "ongoing"
            ? "Analyzing..."
            : "Analyze current agreement version ..."}
        </Button>

        {openDialogRunAiAnalysis && (
          <DialogRunAiAnalysis
            open={openDialogRunAiAnalysis}
            close={() => setOpenDialogRunAiAnalysis(false)}
            submit={analyzeUsingAi}
          />
        )}
      </Grid>
    </Grid>
  );
}
