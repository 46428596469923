import { faClone, faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { $getRoot } from "lexical";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { getCanveoTier } from "../../utils/getCanveoTier";
import DialogUploadVersion from "../dialogs/DialogUploadVersion";
import $convertLexicalToSfdt from "../editor/converters/convertLexicalToSfdt";
import { CanveoCircularProgress } from "../index";
import VersionDrawerItem from "./VersionDrawerItem";

/**
 * @typedef {object} VersionsDrawerProps
 * @property {boolean} isTemplate
 * @property {boolean} loading
 * @property {string} docID
 * @property {boolean} isInEffect
 * @property {(isFull?: boolean) => void} handleExport
 * @property {string} displayMode
 * @property {React.Dispatch<React.SetStateAction<string>>} setDisplayMode
 */

/**
 * @param {VersionsDrawerProps} props
 * @returns {React.JSX.Element}
 */
export default function VersionsDrawer({
  isTemplate,
  loading,
  docID,
  isInEffect,
  handleExport,
  displayMode,
  setDisplayMode,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const versionsOptions = state.drawerVersions.versions.map(
    (/** @type {*} */ version) => ({
      label: `Version ${version.version}`,
      author: version.creationBy.displayName,
      description: version.description,
      lastUpdated: `${DateTime.fromISO(version.lastUpdateDate).toFormat(
        "dd LLL yyyy"
      )} by ${version.lastUpdateBy.displayName}`,
      version,
    })
  );
  const canveoTier = getCanveoTier(state?.user?.email);
  const [editor] = useLexicalComposerContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const openNewVersionDialog = searchParams.get("openNewVersionDialog");
  let defaultOpenNewVersionDialog = false;
  if (Boolean(openNewVersionDialog)) {
    defaultOpenNewVersionDialog = true;

    if (searchParams.size === 1) setSearchParams({});
  }

  const [menuAnchor, setMenuAnchor] = useState(/** @type {* | null} */ (null));
  const [uploadVersion, setUploadVersion] = useState(
    defaultOpenNewVersionDialog
  );
  const [isMainBody, setIsMainBody] = useState(false);
  const [firstDocumentForComparison, setFirstDocumentForComparison] = useState(
    /** @type {* | null} */ (null)
  );
  const [secondDocumentForComparison, setSecondDocumentForComparison] =
    useState(/** @type {* | null} */ (null));
  const [processingComparison, setProcessingComparison] = useState(false);

  useEffect(
    () => {
      if (isTemplate) return;

      const agreement = state.agrs.find(
        (/** @type {{ _id: string; }} */ agr) => agr._id === docID
      );
      const isMainBody = !agreement?.parentID;

      setIsMainBody(isMainBody);
    },
    // Runs only when `docID` and `isTemplate` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [docID, isTemplate]
  );

  /**
   * Generates the SFDT of an agreement version. Can optionally include metadata so that the SFDT can then be
   * reimported as a new version of an existing agreement.
   *
   * @param {string} versionId
   * @param {{ agreementId: string; entityId: string; orgId: string; partyId: PartyId; } | {}} metadata
   */
  async function getSfdtFromVersionId(versionId, metadata = {}) {
    const response = await axios.get(
      `${state.settings.api}agrv/${versionId}/full`
    );

    const document = response?.data?.data;

    const commentsResponse = await axios.get(
      `${state.settings.api}document/${document._id}/comments`
    );

    const sfdt = editor.parseEditorState(document.content).read(() => {
      const root = $getRoot();
      const documentSfdt = document.sfdt;

      const comments = commentsResponse?.data?.data;
      const listsStructure = document?.contentMetadata?.listsStructure || [];

      const generatedSfdt = $convertLexicalToSfdt(
        root,
        documentSfdt,
        metadata,
        comments,
        listsStructure
      );
      return generatedSfdt;
    });

    return sfdt;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        p={2}
        sx={{
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <FontAwesomeIcon
              icon={faClone}
              color={theme.palette.primary.main}
              size="lg"
            />
            &nbsp;&nbsp;&nbsp;Versions
          </Typography>
        </Grid>

        {!isTemplate &&
          // Allow counterparty to also upload new versions.
          // state.agrs?.[0].owner === state.org._id &&
          !isInEffect &&
          canveoTier === "experimental" && (
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                padding: "0 8px",
              }}
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              color={theme.palette.grey[800]}
            />
          )}
      </Grid>

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        {loading && (
          <Box
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CanveoCircularProgress />
          </Box>
        )}

        {processingComparison ? (
          <Grid container justifyContent="center" my={4}>
            <CanveoCircularProgress />
          </Grid>
        ) : (
          <Grid container direction="column" px={2} mb={4}>
            <Grid item>
              <Typography>Version Comparison</Typography>
            </Grid>

            <Grid item mt={2}>
              <Autocomplete
                options={versionsOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="First Document for Comparison"
                    placeholder="Select first document ..."
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          variant="body1"
                          fontWeight="bolder"
                          color={theme.palette.grey[800]}
                        >
                          {option.label}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Author: {option.author}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Description: {option.description}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Last updated: {option.lastUpdated}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                value={firstDocumentForComparison}
                onChange={(event, value) => {
                  setFirstDocumentForComparison(value);
                }}
              />
            </Grid>

            <Grid item mt={2}>
              <Autocomplete
                options={versionsOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Second Document for Comparison"
                    placeholder="Select second document ..."
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          variant="body1"
                          fontWeight="bolder"
                          color={theme.palette.grey[800]}
                        >
                          {option.label}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Author: {option.author}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Description: {option.description}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Last updated: {option.lastUpdated}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                value={secondDocumentForComparison}
                onChange={(_event, value) => {
                  setSecondDocumentForComparison(value);
                }}
              />
            </Grid>

            <Grid item mt={2}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="text"
                    size="small"
                    disableElevation
                    disabled={
                      !firstDocumentForComparison ||
                      !secondDocumentForComparison
                    }
                    onClick={async () => {
                      try {
                        setProcessingComparison(true);

                        if (
                          !firstDocumentForComparison ||
                          !secondDocumentForComparison
                        ) {
                          return;
                        }

                        const versionsToCompare = {
                          leftSfdt: await getSfdtFromVersionId(
                            firstDocumentForComparison.version._id
                          ),
                          rightSfdt: await getSfdtFromVersionId(
                            secondDocumentForComparison.version._id
                          ),
                          saveFormat: "Word",
                        };

                        const result = await axios.post(
                          state.settings.api + "document/comparePdfs",
                          {
                            versionsToCompare,
                          }
                        );

                        const url = result.data.data.comparisonResultPdfUrl;
                        const fileResponse = await axios.get(url, {
                          responseType: "blob",
                        });
                        const downloadFileAnchor = document.createElement("a");
                        downloadFileAnchor.href = window.URL.createObjectURL(
                          fileResponse.data
                        );
                        downloadFileAnchor.download = `comparison.docx`;
                        downloadFileAnchor.click();
                      } catch (error) {
                        console.error(error);
                        dispatch({
                          type: "NEW_SNACKBAR",
                          payload: {
                            severity: "error",
                            message:
                              "An error occurred while generating the comparison PDF.",
                          },
                        });
                      } finally {
                        setProcessingComparison(false);
                      }
                    }}
                  >
                    Export to Word ...
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="text"
                    size="small"
                    disableElevation
                    disabled={
                      !firstDocumentForComparison ||
                      !secondDocumentForComparison
                    }
                    onClick={async () => {
                      try {
                        setProcessingComparison(true);

                        if (
                          !firstDocumentForComparison ||
                          !secondDocumentForComparison
                        ) {
                          return;
                        }

                        const versionsToCompare = {
                          leftSfdt: await getSfdtFromVersionId(
                            firstDocumentForComparison.version._id
                          ),
                          rightSfdt: await getSfdtFromVersionId(
                            secondDocumentForComparison.version._id
                          ),
                          saveFormat: "Pdf",
                        };

                        const result = await axios.post(
                          state.settings.api + "document/comparePdfs",
                          {
                            versionsToCompare,
                          }
                        );

                        const url = result.data.data.comparisonResultPdfUrl;
                        const fileResponse = await axios.get(url, {
                          responseType: "blob",
                        });
                        const downloadFileAnchor = document.createElement("a");
                        downloadFileAnchor.href = window.URL.createObjectURL(
                          fileResponse.data
                        );
                        downloadFileAnchor.download = `comparison.pdf`;
                        downloadFileAnchor.click();
                      } catch (error) {
                        console.error(error);
                        dispatch({
                          type: "NEW_SNACKBAR",
                          payload: {
                            severity: "error",
                            message:
                              "An error occurred while generating the comparison PDF.",
                          },
                        });
                      } finally {
                        setProcessingComparison(false);
                      }
                    }}
                  >
                    Export to PDF ...
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <List sx={{ p: 0, px: 2 }}>
          {state.drawerVersions.versions.map(
            (/** @type {*} */ version, /** @type {number} */ index) => (
              <VersionDrawerItem
                key={version._id}
                item={version}
                isTemplate={isTemplate}
                isInEffect={isInEffect}
                isLatestVersion={index === 0}
                handleExport={handleExport}
                displayMode={displayMode}
                setDisplayMode={setDisplayMode}
              />
            )
          )}
        </List>
      </Box>

      {!isTemplate && !isInEffect && (
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableScrollLock={true}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
        >
          <MenuItem
            onClick={() => {
              setUploadVersion(true);
              setMenuAnchor(null);
            }}
          >
            <ListItemText>Upload new version ...</ListItemText>
          </MenuItem>
        </Menu>
      )}

      {uploadVersion && !isTemplate && (
        <DialogUploadVersion
          open={uploadVersion}
          originVersion={state.drawerVersions.versions[0]}
          isOwner={state.agrs[0].owner === state.org._id}
          canSubmitSigned={state.agrs[0].avOwners.every(
            (/** @type {string} */ o) => o === state.org._id
          )}
          isMainBody={isMainBody}
          versionType={state.drawerVersions.versions[0].versionType}
          handleClose={() => setUploadVersion(false)}
          isTemplate={isTemplate}
        />
      )}
    </>
  );
}
