import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { globalStore } from "../../state/store";

/**
 * @typedef {object} DialogSettingsForGeneralLabelsProps
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 * @param {DialogSettingsForGeneralLabelsProps} props
 * @returns {JSX.Element}
 */
export default function DialogSettingsForGeneralLabels({ open, close }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [promptUsersToAddGeneralLabels, setPromptusersToAddGeneralLabels] =
    useState(state.org.promptUsersToAddGeneralLabels ?? false);

  /**
   * @param {boolean} promptUsersToAddGeneralLabels
   * @returns {Promise<void>}
   */
  async function updatePromptUsersToAddGeneralLabels(
    promptUsersToAddGeneralLabels
  ) {
    try {
      await axios.put(
        `${state.settings.api}org/${state.user.orgID}/promptUsersToAddGeneralLabels`,
        { promptUsersToAddGeneralLabels }
      );
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "success",
          message: "Agreement labels settings were updated",
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message: "An error occurred while saving your changes.",
        },
      });
    }
  }

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Settings for General Labels</DialogTitle>

      <DialogContent>
        <Grid container alignItems="baseline">
          <Grid item xs={1}>
            <Checkbox
              value={promptUsersToAddGeneralLabels}
              checked={promptUsersToAddGeneralLabels}
              onChange={(_event, checked) => {
                setPromptusersToAddGeneralLabels(checked);
              }}
            />
          </Grid>

          <Grid item xs={11}>
            <Typography variant="body1">
              Prompt users to add General Labels when creating a new agreement
            </Typography>

            <Typography variant="body2" color="#979797" mt={1}>
              This will add a step to the agreement creation flow, allowing to
              add General Labels to an agreement.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Cancel</Button>

        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => {
            updatePromptUsersToAddGeneralLabels(
              promptUsersToAddGeneralLabels
            ).then(() => {
              dispatch({
                type: "UPDATE_ORG",
                payload: {
                  ...state.org,
                  promptUsersToAddGeneralLabels,
                },
              });

              close();
            });
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
