import { faCircleInfo, faGears } from "@fortawesome/pro-solid-svg-icons";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $nodesOfType,
} from "lexical";
import { useCallback, useContext, useEffect, useState } from "react";
import { globalStore } from "../../../../state/store";
import { ASSIGN_TOPICS_COMMAND } from "../../commands";
import { ClauseNode } from "../../nodes";
import { $isClauseNode } from "../../nodes/ClauseNode";
import { analyzeNode } from "../../utils";

export default function useClauseOptions() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  const [anchorElClauseOptions, setAnchorElClauseOptions] = useState(null);

  const activeClauseItems = state.clauseOptions.activeClauseItems;

  /**
   * @param {*} newValue
   */
  const setActiveClauseItems = (newValue) => {
    dispatch({ type: "SET_ACTIVECLAUSEITEMS", payload: newValue });
  };
  const [activeClauseKey, setActiveClauseKey] = useState(
    /** @type {string | null} */ (null)
  );
  const [creatingInternalWorkflow, setCreatingInternalWorkflow] = useState(
    /** @type {string | null} */ (null)
  );
  const [isClauseLibrary, setIsClauseLibrary] = useState(false);
  const dialogClauseOpen = state.clauseOptions.dialogClauseOpen;
  const setDialogClauseOpen = useCallback(
    /**
     * @param {*} newValue
     */
    (newValue) => {
      dispatch({ type: "SET_DIALOGCLAUSE_OPEN", payload: newValue });
    },
    [dispatch]
  );
  const [dialogParamOpen, setDialogParamOpen] = useState(
    /** @type {* | null} */ (null)
  );
  const [expanded, setExpanded] = useState(null);
  const [openDialogNewClauseVariant, setOpenDialogNewClauseVariant] =
    useState(false);
  const [partialClause, setPartialClause] = useState({ id: "", text: "" });

  const [
    openDialogAssignPropertiesToClause,
    setOpenDialogAssignPropertiesToClause,
  ] = useState(false);

  useEffect(
    () => {
      if (state.selectedOpenIssue && state.selectedOpenIssue.id) {
        editor.getEditorState().read(() => {
          const dfs = $nodesOfType(ClauseNode);
          const clause = dfs.find((n) => n.id === state.selectedOpenIssue.id);
          if (clause) {
            setActiveClauseItems({
              cts: clause.getClauseTypes(),
              wfs: clause.getWorkflows(),
            });
            setActiveClauseKey(clause.getKey());
            setIsClauseLibrary(
              clause &&
                clause.getLibIDs() &&
                clause
                  .getLibIDs()
                  .some(
                    (clid) =>
                      clid.startsWith(state.org._id) &&
                      state.clauseLibItems.some(
                        (/** @type {{ _id: string; }} */ cli) =>
                          cli._id === clid.substring(clid.indexOf("_") + 1)
                      )
                  )
            );
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, state.clauseLibItems, state.selectedOpenIssue]
  );

  useEffect(
    () => {
      return mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              const node = selection.isBackward()
                ? selection.focus.getNode()
                : selection.anchor.getNode();
              const clause = $getNearestNodeOfType(node, ClauseNode);
              setActiveClauseItems({
                cts: clause ? clause.getClauseTypes() : [],
                wfs: clause ? clause.getWorkflows() : [],
              });
              setActiveClauseKey(clause ? clause.getKey() : null);
              setIsClauseLibrary(
                // @ts-ignore
                clause &&
                  clause.getLibIDs() &&
                  clause
                    .getLibIDs()
                    .some(
                      (clid) =>
                        clid.startsWith(state.org._id) &&
                        state.clauseLibItems.some(
                          (/** @type {{ _id: string; }} */ cli) =>
                            cli._id === clid.substring(clid.indexOf("_") + 1)
                        )
                    )
              );
            }
          });
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, state.clauseLibItems, state.org]
  );

  /**
   * @param {*} event
   */
  const handleOpenClauseOptionsMenu = (event) => {
    if (activeClauseKey) {
      editor.update(() => {
        let clauseNode = $getNodeByKey(activeClauseKey);
        if (!clauseNode) return;

        clauseNode.changeFilter("in");
        const element = editor.getElementByKey(activeClauseKey);
        if (!element) return;

        element.scrollIntoView({ block: "center" });
        setAnchorElClauseOptions(event.currentTarget);
      });
    }
  };

  const handleCloseClauseOptionsMenu = (filter = "none") => {
    if (activeClauseKey) {
      editor.update(() => {
        const clauseNode = $getNodeByKey(activeClauseKey);
        if (!clauseNode || !$isClauseNode(clauseNode)) {
          throw new Error("Invalid clause node.");
        }
        if (filter === "none") {
          clauseNode.changeFilter(filter);
        }
        const element = editor.getElementByKey(activeClauseKey);
        if (!element) {
          throw new Error("Could not find error.");
        }
        element.scrollIntoView({ block: "center" });
        setAnchorElClauseOptions(null);
      });
    }
  };

  /**
   *
   * @param {string} option
   * @param {"default" | "guidance"} mode
   */
  const handleClauseOptionsMenuOption = (option, mode = "default") => {
    if (option === "assignclausetype") {
      editor.dispatchCommand(ASSIGN_TOPICS_COMMAND, {
        key: activeClauseKey,
        ctids: ["empty"], // e.g., "653801ecf2dcf0fe231d4af2"
      });
      handleCloseClauseOptionsMenu("in");
    } else if (["insertLibrary", "alternativeClause"].includes(option)) {
      handleCloseClauseOptionsMenu();
      setDialogClauseOpen({
        type: ["alternativeClause"].includes(option)
          ? "alternativeclause"
          : ["insertLibrary"].includes(option)
          ? "insertclause"
          : "assignclausetype",
        mode,
        activeCTs: activeClauseItems.cts,
        activeClauseKey: activeClauseKey,
      });
    } else if (
      ["createInternalComment", "createInternalApproval"].includes(option)
    ) {
      setAnchorElClauseOptions(null);
      setCreatingInternalWorkflow(option);
    } else if (["saveLibrary"].includes(option)) {
      handleCloseClauseOptionsMenu();
      editor.getEditorState().read(() => {
        if (!activeClauseKey) return;

        let clauseNode = $getNodeByKey(activeClauseKey);
        if (!clauseNode) return;

        let clauseDetails = Boolean(clauseNode)
          ? analyzeNode(clauseNode.getTextContent())
          : null;
        if (!clauseDetails) return;

        let clauseText = Boolean(clauseDetails)
          ? clauseNode
              .getTextContent()
              .substring(clauseDetails.endPositionOfLead)
              .trim()
          : null;

        if (Boolean(clauseNode) && Boolean(clauseText)) {
          setDialogClauseOpen({
            type: "savelibrary",
            mode,
            activeCTs: activeClauseItems.cts,
            activeClauseKey: activeClauseKey,
            clauseText: clauseText,
          });
        }
      });
    } else if (option === "add-clause-variant") {
      const partialClause = editor.getEditorState().read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return;

        const selectionNode = selection.anchor.getNode();
        const clauseNode = $getNearestNodeOfType(selectionNode, ClauseNode);
        if (!clauseNode) return;

        const clauseText = clauseNode.getTextContent();
        return { id: clauseNode.id, text: clauseText };
      });
      if (partialClause) {
        setPartialClause(partialClause);
        setOpenDialogNewClauseVariant(true);
      }
    } else if (option === "assignProperties") {
      setOpenDialogAssignPropertiesToClause(true);
    }
  };

  /**
   * @param {*} action
   */
  const closeClauseDialog = (action) => {
    if (!!action) {
      editor.dispatchCommand(action.command, action.payload);
    }
    setDialogClauseOpen(null);
  };

  const handleClauseAlternatives = () => {
    handleClauseOptionsMenuOption("alternativeClause");
  };

  /**
   * @param {*} id
   */
  const expandItem = (id) => {
    setExpanded(id);
    if (id === null) {
      setCreatingInternalWorkflow(null);
      handleCloseClauseOptionsMenu();
    }
  };

  const closeParamDialog = () => {
    setDialogParamOpen(null);
  };

  /**
   * @param {string} paramID
   */
  const openParamDialog = (paramID) => {
    setDialogParamOpen({ type: "updateDocParam", paramID: paramID });
  };

  /**
   * @param {boolean} _isInEffect
   */
  const clauseOptions = (_isInEffect) => {
    // const isPreviousVersion =
    //   state.drawerVersions.active?._id !==
    //   state.drawerVersions.versions[0]?._id;

    const options = [
      {
        id: "assignProperties",
        name: "Assign Properties ...",
        icon: faGears,
        disabled: false,
      },
      {
        id: "assignclausetype",
        name: "Assign Topics ...",
        icon: faCircleInfo,
        disabled: false,
      },
      // {
      //   id: "saveLibrary",
      //   name: "Save to clause library",
      //   icon: faBooks,
      // },
      // {
      //   id: "insertLibrary",
      //   name: "Insert from library",
      //   icon: faBookCircleArrowRight,
      //   disabled: isInEffect || isPreviousVersion,
      // },
    ];

    // // Only display on templates.
    // if (!window.location.href.includes("agreement")) {
    //   options.push({
    //     id: "add-clause-variant",
    //     name: "Add Clause Variant ...",
    //     icon: faCodeMerge,
    //     disabled: isInEffect || isPreviousVersion,
    //   });
    // }

    return options;
  };

  return {
    expanded,
    activeClauseItems,
    activeClauseKey,
    creatingInternalWorkflow,
    isClauseLibrary,
    dialogClauseOpen,
    clauseOptions,
    anchorElClauseOptions,
    dialogParamOpen,
    handleClauseOptionsMenuOption,
    handleOpenClauseOptionsMenu,
    handleCloseClauseOptionsMenu,
    closeClauseDialog,
    handleClauseAlternatives,
    setActiveClauseItems,
    setActiveClauseKey,
    setIsClauseLibrary,
    expandItem,
    setExpanded,
    closeParamDialog,
    openParamDialog,
    setDialogClauseOpen,
    openDialogNewClauseVariant,
    setOpenDialogNewClauseVariant,
    partialClause,
    openDialogAssignPropertiesToClause,
    setOpenDialogAssignPropertiesToClause,
  };
}
