import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useContext } from "react";

import { useAgreementMetadata } from "../../../hooks/useAgreementMetadata";
import { globalStore } from "../../../state/store";
import { getCanveoTier } from "../../../utils/getCanveoTier";
import { AgreementMetadata } from "../../AgreementMetadata";

/**
 * @typedef {object} DialogDatesAndRenewalProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {string} agreementId
 * @property {*} document
 * @property {*[]} parameters
 */

/**
 * @param {DialogDatesAndRenewalProps} props
 * @returns {React.JSX.Element}
 */
export default function DialogDatesAndRenewal({
  open,
  close,
  agreementId,
  document,
  parameters,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const metadataState = useAgreementMetadata({
    agreementId,
    close,
  });

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Metadata</DialogTitle>

      <DialogContent>
        <AgreementMetadata
          document={document}
          parameters={parameters}
          metadataState={metadataState}
        />
      </DialogContent>

      <DialogActions>
        {!metadataState.isLoading && (
          <>
            <Button sx={{ marginRight: "auto" }} onClick={close}>
              Cancel
            </Button>

            {getCanveoTier(state?.user?.email) === "experimental" && (
              <FormGroup sx={{ paddingRight: "80px", paddingBottom: "5px" }}>
                <FormControlLabel
                  control={<Checkbox defaultChecked={false} />}
                  label={
                    <Grid container alignItems="center">
                      Use AI to Extract this data
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ fontSize: "12px", padding: "4px" }}
                      />
                    </Grid>
                  }
                />
              </FormGroup>
            )}

            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => metadataState.submit()}
            >
              Submit
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
