import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { globalStore } from "../../state/store";
import CanveoCircularProgress from "../CanveoCircularProgress";

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export default function DialogAgreementType({
  open,
  type,
  closeDialog,
  handleCreation,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const formSchema = Yup.object({
    shortName: Yup.string().required("Short name is required"),
    fullName: Yup.string().required("Long name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      shortName: type?.shortName ?? "",
      fullName: type?.longName ?? "",
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      let newType = {
        shortName: values.shortName,
        fullName: [values.fullName],
        orgID: state.org._id,
        active: true,
      };

      setLoading(true);

      // Create the new Agreement type
      axios
        .post(state.settings.api + "agrtype", { agrType: newType })
        .then((result) => {
          if (result.data.success) {
            // Add newly created agrType to the reducer
            // Create with the new AgrType
            dispatch({ type: "ADD_AGRTYPE", payload: result.data.data });
            handleCreation(result.data.data);
            closeDialog();

            setErrorMessage("");
            setLoading(false);
          } else {
            setErrorMessage(
              "An error occured while creating the agreement type - refresh your browser"
            );
            setLoading(false);
          }
        })
        .catch(() => {
          setErrorMessage("An error occured while creating the agreement type");
          setLoading(false);
        });
    },
  });

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={closeDialog}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Create New Agreement Type</DialogTitle>

      <DialogContent>
        {loading && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ my: 1 }}
          >
            <CanveoCircularProgress />{" "}
          </Grid>
        )}

        {!loading && (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ my: 1 }}
            >
              {!!errorMessage && (
                <Grid item container xs={12} justifyContent="center">
                  <Box mb={2}>
                    <Typography color="error">{errorMessage}</Typography>
                  </Box>
                </Grid>
              )}

              <Grid item container xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="longName"
                  label="Long Name"
                  placeholder="Long name, e.g. “Master Service Agreement”"
                  // @ts-ignore
                  name="longName"
                  {...formik.getFieldProps("fullName")}
                  error={!!(formik.touched.fullName && formik.errors.fullName)}
                  // @ts-ignore
                  helperText={
                    formik.touched.fullName && formik.errors.fullName
                      ? formik.errors.fullName
                      : ""
                  }
                />
              </Grid>

              <Grid item container xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="shortName"
                  label="Short Name"
                  placeholder="Abbreviation, e.g. “MSA”"
                  // @ts-ignore
                  name="shortName"
                  {...formik.getFieldProps("shortName")}
                  error={
                    !!(formik.touched.shortName && formik.errors.shortName)
                  }
                  // @ts-ignore
                  helperText={
                    formik.touched.shortName && formik.errors.shortName
                      ? formik.errors.shortName
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions>
        <Button sx={{ marginRight: "auto" }} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!formik.dirty}
          onClick={() => formik.resetForm()}
        >
          Reset
        </Button>

        {/* @ts-ignore */}
        <Button
          color="primary"
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
        >
          <FontAwesomeIcon icon={faPen} />
          &nbsp;&nbsp; Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
