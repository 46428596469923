import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import axios from "axios";
import { $getRoot } from "lexical";
import { forwardRef, useContext, useImperativeHandle } from "react";
import useAgreementData from "../../../hooks/useAgreementData";
import useFileService from "../../../hooks/useFileService";
import { globalStore } from "../../../state/store";
import $convertLexicalToSfdt from "../converters/convertLexicalToSfdt";

const config = {
  docx: {
    urlPath: "document/export",
    defaultDownloadFileName: "agreement.docx",
  },
  pdf: {
    urlPath: "document/exportPdf",
    defaultDownloadFileName: "agreement.pdf",
  },
};

export default forwardRef(
  /**
   * @param {{ agreement: *; agrVersion: *; partyId: string; isTemplate: boolean; }} props
   * @param {*} ref
   */
  // @ts-ignore
  ({ agreement, agrVersion, partyId, isTemplate }, ref) => {
    // @ts-ignore
    const [state] = useContext(globalStore);
    const [editor] = useLexicalComposerContext();
    const { downloadFile } = useFileService();

    const { sendAgreement } = useAgreementData(
      agreement,
      // Needs to be true for the collaborators to be preserved during sending while on an agreement. Always false
      // for templates.
      !isTemplate
    );

    /**
     * @param {keyof typeof config} type
     * @param {string} filename
     * @param {*[]} documentComments
     */

    useImperativeHandle(ref, () => ({
      /**
       * Used to export a document to Word or PDF.
       *
       * @param {{type: "docx" | "pdf"; filename: string; agrId: string; recipientParty: { _id: string; orgID: string; partyID: string; }; wordEditAuthorization: *, exportType: string}} document
       * @param {boolean} isAgreementOwner
       * @param {"read" | "full"} editMode
       */
      async export(
        {
          type,
          filename,
          agrId,
          recipientParty,
          wordEditAuthorization,
          exportType,
        },
        isAgreementOwner,
        editMode
      ) {
        const documentCommentsResponse = await axios({
          url: `${state.settings.api}document/${agrId}/comments`,
          method: "GET",
        });
        const documentComments = documentCommentsResponse.data.data;

        /** @type {Record<string, *>} */
        let metadata;

        if (exportType === "offline") {
          metadata = {
            agreementId: agrVersion.agrID,
            orgId: recipientParty.orgID,
            entityId: recipientParty._id,
            partyId: recipientParty.partyID,
          };

          await sendAgreement({
            skipEmails: true,
            sendOrigin: "Export",
          });
        } else if (exportType === "archival") {
          metadata = {};
        } else {
          metadata = {
            agreementId: agrVersion.agrID,
            entityId: state.parties.find(
              (/** @type {{ orgID: string; }} */ party) =>
                party.orgID === state.user.orgID
            )?._id,
            orgId: state.user.orgID,
            partyId,
          };
        }

        const sfdt = editor.getEditorState().read(() => {
          const root = $getRoot();
          // TODO: This should be sent via parameter.
          const documentSfdt =
            state?.drawerVersions?.active?.sfdt ?? agrVersion.sfdt;

          const listsStructure =
            state?.drawerVersions?.active?.contentMetadata?.listsStructure ??
            agrVersion?.contentMetadata?.listsStructure ??
            [];

          const generatedSfdt = $convertLexicalToSfdt(
            root,
            documentSfdt,
            metadata,
            documentComments,
            listsStructure
          );

          return generatedSfdt;
        });

        const isReadonly = !isAgreementOwner && editMode === "read";

        const downloadFileResponse = await axios.post(
          `${state.settings.api}${config[type].urlPath}`,
          {
            sfdt,
            isReadonly,
            wordEditAuthorization:
              type === "docx"
                ? wordEditAuthorization || "trackedChanges"
                : undefined,
            attachWordDocumentToEmail: true,
          }
        );
        const downloadFileResponseData = await downloadFileResponse.data;
        const fileResponse = await axios.get(
          downloadFileResponseData.data.url,
          { responseType: "blob" }
        );

        const downloadFileAnchor = document.createElement("a");
        downloadFileAnchor.href = window.URL.createObjectURL(fileResponse.data);
        downloadFileAnchor.download = filename
          ? `${filename}.${type}`
          : config[type].defaultDownloadFileName;
        downloadFileAnchor.click();

        if (exportType === "offline") window.location.reload();
      },
      /**
       * Exports entire document to PDF i.e., if the document has exhibits it combines
       * the main document plus all exhibits in one PDF.
       *
       * @param {{filename: string; agrId: string}} _
       * @param {boolean} isTemplate
       * @param {boolean} isInEffect
       * @param {*} [agreementVersion]
       */
      async fullExport(
        { filename, agrId },
        isTemplate,
        isInEffect,
        agreementVersion
      ) {
        // If the agreement is in status "In Effect" we download the signed PDF.
        if (isInEffect) {
          const res = await axios({
            url: `${state.settings.api}agr/${agrId}/download`, //your url
            method: "GET",
            validateStatus: () => true,
          });

          const { data } = res;

          const signedPdfExists = data.data;
          if (signedPdfExists) {
            const blobFile = new Blob([new Uint8Array(signedPdfExists.data)], {
              type: "application/pdf",
            });
            const href = URL.createObjectURL(blobFile);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", filename + ".pdf");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          } else if (
            agreementVersion &&
            agreementVersion.pdfIntention === "store" &&
            agreementVersion.pdfFileKey
          ) {
            await downloadFile(agreementVersion.pdfFileKey, filename + ".pdf");
          }
        }
        // Otherwise, we generate a PDF from the current version.
        else {
          const response = await axios.get(
            `${state.settings.api}${
              isTemplate ? "template" : "agr"
            }/exportDetails/${agrId}`
          );
          const documents = response?.data?.data;
          if (!documents?.length) {
            throw new Error("Error getting agreement details.");
          }

          for (const document of documents) {
            if (!document.content) continue;

            const commentsResponse = await axios.get(
              `${state.settings.api}document/${document._id}/comments`
            );

            document.sfdt = editor
              .parseEditorState(document.content)
              .read(() => {
                const root = $getRoot();
                const documentSfdt = document.sfdt;
                const metadata = {};
                const comments = commentsResponse?.data?.data;
                const listsStructure =
                  document?.contentMetadata?.listsStructure || [];

                const generatedSfdt = $convertLexicalToSfdt(
                  root,
                  documentSfdt,
                  metadata,
                  comments,
                  listsStructure
                );
                return generatedSfdt;
              });
          }

          const downloadFileResponse = await axios.post(
            `${state.settings.api}document/fullExport`,
            {
              agreements: documents,
            }
          );
          const downloadFileResponseData = await downloadFileResponse.data;
          const fileResponse = await axios.get(
            downloadFileResponseData.data.url,
            { responseType: "blob" }
          );

          const downloadFileAnchor = document.createElement("a");
          downloadFileAnchor.href = window.URL.createObjectURL(
            fileResponse.data
          );
          downloadFileAnchor.download = `${filename}.pdf`;
          downloadFileAnchor.click();
        }
      },
    }));
  }
);
