import axios from "axios";
import { useContext, useState } from "react";
import { globalStore } from "../state/store";

/**
 * @typedef {MergeField} Property
 */

export function useProperties() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(false);

  /**
   * @param {string} versionId
   * @returns {Promise<Property[]>}
   */
  async function getVersionProperties(
    versionId,
    includeTemplateProperties = false
  ) {
    try {
      setIsLoading(true);

      /** @type {Record<string, string | boolean>} */
      const params = {};

      if (includeTemplateProperties) {
        params.isTemplate = true;
      } else {
        params.versionId = versionId;
      }

      const getVersionPropertiesResponse = await axios.get(
        `${state.settings.api}properties`,
        {
          params,
        }
      );
      /** @type {Property[]} */
      const properties = getVersionPropertiesResponse.data.data;
      return properties;
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message:
            "Unable to retrieve version properties, try again or contact Canveo Support if the issue persists.",
        },
      });
      return [];
    } finally {
      setIsLoading(false);
    }
  }

  return {
    getVersionProperties,
    isLoading,
  };
}
