import { faCircleXmark, faPen } from "@fortawesome/pro-regular-svg-icons";
import {
  faEllipsisVertical,
  faFileContract,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { getCanveoTier } from "../../../utils/getCanveoTier";
import DialogExhibits from "../../DialogExhibits";
import {
  getExhibitsConditionSummary,
  getMergeFieldDisplayValue,
} from "../../MergeFieldMenu/utils";
import DialogCreateExhibit from "../../dialogs/DialogCreateExhibit";
import ExhibitDrawerItem from "../ExhibitDrawerItem";
import NewExhibitsConditionDialog from "./dialogs/NewExhibitsConditionDialog";
import { getExhibitTitle } from "./dialogs/NewExhibitsConditionDialog/utils/getExhibitTitle";

/**
 * @typedef {*} ExhibitsDrawerProps
 */

/**
 * @param {ExhibitsDrawerProps} _
 * @returns {React.JSX.Element}
 */
function ExhibitsDrawer({
  isTemplate,
  template,
  docID,
  hasPen,
  handleExport,
  isInEffect,
  displayMode,
  setDisplayMode,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [addingExhibit, setAddingExhibit] = useState(false);
  const [managingExhibit, setManagingExhibit] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [exhibits, setExhibits] = useState([]);
  const [mainBody, setMainBody] = useState(null);
  const [openNewConditionDialog, setOpenNewConditionDialog] = useState(false);
  const [conditions, setConditions] = useState(
    /** @type {import("../../MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition[]} */ (
      isTemplate ? template.conditions : []
    )
  );
  const [, setConditionToEdit] = useState(
    /** @type {import("../../MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition | null} */ (
      null
    )
  );
  const [taskId, setTaskId] = useState("");

  useEffect(() => {
    let exhibits;
    let mainBody;
    if (isTemplate) {
      exhibits = state.template.filter((/** @type {*} */ t) => t.parentID);
      mainBody = state.template.find((/** @type {*} */ t) => !t.parentID);
    } else {
      exhibits = state.agrs.filter((/** @type {*} */ a) => a.parentID);
      mainBody = state.agrs.find((/** @type {*} */ a) => !a.parentID);
    }

    setExhibits(exhibits);
    setMainBody(mainBody);
  }, [isTemplate, docID, state.agrs, state.template]);

  const isCounterparty = state.user.role.name === "Counterparty";

  useEffect(() => {
    const mainAgreement = state.agrs.find(
      (/** @type {*} */ agr) => !agr.parentID
    );

    const mainAgreementVersion = state.avs.find(
      (/** @type {*} */ version) => version.agrID === mainAgreement._id
    );
    const editMode = mainAgreementVersion?.owner?.find(
      (/** @type {*} */ o) => o.orgID === state.org._id
    )?.editMode;
    const canEdit = ["full", "edit"].includes(editMode) && hasPen;
    const isOwner = state.org._id === mainAgreement?.owner;

    const menuItems = [
      {
        text: "Add exhibit ...",
        action: async () => {
          const getTaskResponse = await axios.get(
            `${state.settings.api}task/?agreementId=${docID}&type=Ticket`
          );

          const tasks = getTaskResponse.data.data;
          if (tasks.length) {
            const [task] = tasks;
            if (task._id) setTaskId(task._id);
          }

          setAddingExhibit(true);
        },
        hidden: (!isTemplate && isCounterparty && !canEdit) || isInEffect,
      },
      {
        text: "Change order ...",
        action: () => setManagingExhibit(true),
        hidden:
          (!isTemplate &&
            ((isCounterparty && !canEdit) || (isOwner && !hasPen))) ||
          isInEffect,
      },
    ];

    if (getCanveoTier(state?.user?.email) === "experimental") {
      menuItems.push({
        text: "Make inclusion dependent on conditions ...",
        action: () => setOpenNewConditionDialog(true),
        hidden: !isTemplate,
      });
    }

    const /** @type {*} */ filteredMenuItems = menuItems.filter(
        (item) => !item.hidden
      );

    setMenuItems(filteredMenuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplate, state.agrs, state.org, state.user, hasPen]);

  const menuClick = (/** @type {*} */ handler) => {
    setMenuAnchor(null);
    handler();
  };

  const handleNewConditionDialogSubmit = (
    /** @type {import("../../MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} */ condition,
    /** @type {"create" | "update"} */ type
  ) => {
    switch (type) {
      case "create":
        setConditions((conditions) => [...conditions, condition]);
        break;

      case "update":
        const updatedConditions = [...conditions];
        let conditionToUpdateIndex = updatedConditions.findIndex(
          (x) => x.id === condition.id
        );

        if (conditionToUpdateIndex === -1) {
          throw new Error("Condition not found.");
        }

        updatedConditions[conditionToUpdateIndex] = condition;

        setConditions(updatedConditions);
        break;

      default:
        throw new Error("Invalid type.");
    }
  };

  const getConditionSummaryHtml = (
    /** @type {import("../../MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} */ condition
  ) => {
    const { baseValueName, conditions } =
      getExhibitsConditionSummary(condition);

    // @ts-ignore
    const selectedExhibits = condition?.selectedExhibits;

    return (
      <>
        <span
          style={{
            fontSize: "14px",
            wordBreak: "break-word",
            whiteSpace: "normal",
          }}
        >
          When <b>{baseValueName}</b>{" "}
          {conditions.map((condition, index) => {
            return (
              <Fragment key={index}>
                {condition.operatorLabel}{" "}
                <b>
                  {(() => {
                    if (condition.comparisonValues instanceof Array) {
                      return condition.comparisonValues.join(", ");
                    }

                    return getMergeFieldDisplayValue(
                      condition.comparisonValues
                    );
                  })()}
                </b>{" "}
              </Fragment>
            );
          })}
          then the included exhibits are{" "}
          {selectedExhibits?.map(
            (/** @type {*} */ exhibit, /** @type {number} */ index) => {
              return (
                <Fragment key={index}>
                  <b>{getExhibitTitle(exhibit)}</b>
                  {selectedExhibits.length > 1 &&
                    index !== selectedExhibits.length - 1 && (
                      <Fragment>{" and "}</Fragment>
                    )}
                </Fragment>
              );
            }
          )}
          .
        </span>
      </>
    );
  };

  const mainAg = state.agrs.find(
    (/** @type {{ parentID: string; }} */ a) => !a.parentID
  );
  const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id);
  const /** @type {boolean} */ isCurrentVersionOwner =
      !isAgrExec &&
      (isTemplate ||
        (!isTemplate &&
          Boolean(mainAg) &&
          mainAg.avOwners.some(
            (/** @type {string} */ owner) => owner === state.org._id
          )));

  const notVersionOwner =
    // isAgreementOwner &&
    !isCurrentVersionOwner &&
    !isAgrExec &&
    !["Execution", "InEffect"].includes(mainAg?.agrStatus);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        p={2}
        sx={{
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <FontAwesomeIcon
              icon={faFileContract}
              color={theme.palette.primary.main}
              size="lg"
            />
            &nbsp;&nbsp;&nbsp;Exhibits
          </Typography>
        </Grid>

        {!(
          getCanveoTier(state?.user?.email) === "stable" &&
          (isCounterparty || notVersionOwner)
        ) &&
          Boolean(menuItems.length) && (
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                padding: "0 8px",
              }}
              onClick={(/** @type {*} */ e) => setMenuAnchor(e.currentTarget)}
              color={theme.palette.grey[800]}
            />
          )}
      </Grid>

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        {Boolean(conditions?.length) && (
          <Box sx={{ mb: 2, px: 2 }}>
            <Typography
              sx={{
                mb: 2,
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              Conditions for inclusion
            </Typography>

            <Typography
              sx={{
                mb: 2,
                color: "grey",
                fontSize: "14px",
                whiteSpace: "normal",
              }}
            >
              When this template is being used, exhibits will be included as
              follows:
            </Typography>

            {conditions.map((condition, index) => (
              <Grid key={condition.id} container>
                <Grid container justifyContent={"center"}>
                  <div
                    style={{
                      padding: "20px 10px 5px 20px",
                      outline: "#7243dd dashed 1px",
                      borderRadius: "20px",
                      width: "286px",
                      color: "#7243dd",
                    }}
                  >
                    <Grid container direction="row">
                      <div>{getConditionSummaryHtml(condition)}</div>
                    </Grid>

                    <Grid container direction="row">
                      <Grid item xs={10} />

                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            setConditionToEdit(condition);
                            setOpenNewConditionDialog(true);
                          }}
                        >
                          <FontAwesomeIcon
                            color="#7243dd"
                            // @ts-ignore
                            icon={faPen}
                            style={{
                              fontSize: "14px",
                            }}
                          />
                        </IconButton>
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            setConditions([
                              ...conditions.slice(0, index),
                              ...conditions.slice(index + 1),
                            ]);
                          }}
                        >
                          <FontAwesomeIcon
                            color="#7243dd"
                            // @ts-ignore
                            icon={faCircleXmark}
                            style={{
                              fontSize: "14px",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {index !== conditions.length - 1 ? (
                  <Grid
                    container
                    sx={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <Grid
                      item
                      xs={5}
                      sx={{
                        paddingLeft: "38px",
                      }}
                    >
                      <hr
                        style={{
                          height: "1px",
                          backgroundColor: "#ccc",
                          border: "none",
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      sx={{
                        textAlign: "center",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      OR
                    </Grid>

                    <Grid item xs={5} sx={{ paddingRight: "38px" }}>
                      <hr
                        style={{
                          height: "1px",
                          backgroundColor: "#ccc",
                          border: "none",
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            ))}

            <Grid container>
              <br />
            </Grid>

            {/* TODO: Check the limit of conditions. Capped at 3 for the time being. */}
            {conditions.length < 3 && (
              <Grid container direction="row" justifyContent={"flex-end"}>
                <span
                  style={{
                    color: "#7243dd",
                    fontSize: "12px",
                    marginRight: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setConditionToEdit(null);
                    setOpenNewConditionDialog(true);
                  }}
                >
                  Add Condition ...
                </span>
              </Grid>
            )}
          </Box>
        )}

        {Boolean(mainBody) && (
          <Box sx={{ mb: 2, px: 2 }}>
            <Typography
              sx={{
                mb: 2,
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              {/* Main Body */}
            </Typography>
            <ExhibitDrawerItem
              exhibit={mainBody}
              isTemplate={isTemplate}
              hasPen={hasPen}
              mainBody={mainBody}
              handleExport={handleExport}
              isInEffect={isInEffect}
              displayMode={displayMode}
              setDisplayMode={setDisplayMode}
            />
          </Box>
        )}

        {Boolean(exhibits.length) && (
          <Box sx={{ my: 2, px: 2 }}>
            <Typography
              sx={{
                mb: 2,
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              Exhibits
            </Typography>
            {exhibits
              .sort((/** @type {*} */ a, /** @type {*} */ b) =>
                a.priority > b.priority ? 1 : -1
              )
              .map((/** @type {*} */ agr) => (
                <ExhibitDrawerItem
                  key={agr._id}
                  exhibit={agr}
                  isTemplate={isTemplate}
                  hasPen={hasPen}
                  mainBody={mainBody}
                  handleExport={handleExport}
                  isInEffect={isInEffect}
                  displayMode={displayMode}
                  setDisplayMode={setDisplayMode}
                />
              ))}
          </Box>
        )}
      </Box>

      {Boolean(menuItems.length) && (
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableScrollLock={true}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
        >
          {menuItems.map((/** @type {*} */ item, index) => (
            <MenuItem key={index} onClick={() => menuClick(item.action)}>
              <ListItemText>{item.text}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}

      {addingExhibit && (
        <DialogCreateExhibit
          open={addingExhibit}
          mainBody={mainBody}
          isTemplate={isTemplate}
          handleClose={() => setAddingExhibit(false)}
          taskId={taskId}
        />
      )}

      {managingExhibit && (
        <DialogExhibits
          open={managingExhibit}
          closeDialog={() => setManagingExhibit(false)}
          ags={[mainBody, ...exhibits]}
          mainBody={mainBody}
          isTemplate={isTemplate}
        />
      )}

      {openNewConditionDialog && (
        <NewExhibitsConditionDialog
          isList={false}
          listOptions={[]}
          condition={null}
          submit={handleNewConditionDialogSubmit}
          openNewConditionDialog={openNewConditionDialog}
          closeNewConditionDialog={() => {
            setOpenNewConditionDialog(false);
          }}
          isLoading={false}
          docId={docID}
          mergeFieldValueType={""}
          exhibits={exhibits}
        />
      )}
    </>
  );
}

export default ExhibitsDrawer;
