import { $dfs } from "@lexical/utils";
import { Box, Container, Grid, Stepper, Typography } from "@mui/material";
import axios from "axios";
import { $createTextNode, $isTextNode, createEditor } from "lexical";
import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Header } from "../components";
import CanveoCircularProgress from "../components/CanveoCircularProgress";
import CustomStep from "../components/CustomStep";
import AreaLabelSelector from "../components/createAgreement/AreaLabelSelector";
import CreateTicket from "../components/createAgreement/CreateTicket";
import DirectoryBuilderSelector from "../components/createAgreement/DirectoryBuilderSelector";
import LabelsSelector from "../components/createAgreement/LabelsSelector";
import MergeFieldSelector from "../components/createAgreement/MergeFieldSelector";
import PartySelector from "../components/createAgreement/PartySelector";
import TemplateSelector from "../components/createAgreement/TemplateSelector";
import TrackedChangesAuthorship from "../components/createAgreement/TrackedChangesAuthorship";
import { $isMarkNode } from "../components/editor/nodes/MarkNode";
import { $createRedlineNode } from "../components/editor/nodes/RedlineNode";
import { editorConfig } from "../components/editor/utils";
import useWidth from "../hooks/useWidth";
import { globalStore } from "../state/store";
import { randomString as generateRandomString } from "../utils";
import { getExhibit, getOrigin } from "../utils/agreementCreation";
import {
  calculateCondition,
  calculateMergeFieldValue,
} from "../utils/calculateRegularMergeFieldValue";
import { getCanveoTier } from "../utils/getCanveoTier";

/**
 * @typedef {object} AgreementStep
 * @property {"AreaLabels" | "Template" | "Parties" | "AgreementLabels" | "DirectoryBuilder" | "CreateTicket" | "TrackedChangesAuthorshipStep"} purpose
 * @property {string} title
 * @property {string} subtitle
 * @property {(agreement: *, handleAgreementChange: () => {}) => JSX.Element} component
 */

/**
 * @typedef {object} MergeFieldAgreementStep
 * @property {"MergeField"} purpose
 * @property {string} title
 * @property {string} subtitle
 * @property {() => JSX.Element} component
 * @property {import("../components/editor/nodes/MarkNode").MergeField} mergeField
 */

/**
 * @typedef {AgreementStep | MergeFieldAgreementStep } CreateAgreementStep
 */

/**
 * @param {string} email
 * @param {boolean} promptUsersToAddGeneralLabels
 * @returns {CreateAgreementStep[]}
 */
function getDefaultSteps(email, promptUsersToAddGeneralLabels = true) {
  /** @type {CreateAgreementStep[]} */
  const defaultSteps = [
    {
      purpose: "AreaLabels",
      title: "What area is your agreement for?",
      subtitle:
        "This helps us suggest the right templates, and gives the right people access to your agreement.",
      component: AreaLabelSelector,
    },
    {
      purpose: "Template",
      title: "What is the basis for your agreement?",
      subtitle:
        "Choose between your own templates or an uploaded Word file. You can also upload PDF files that were signed offline.",
      // KEEP FOR LATER, ONCE "FROM SCRATCH" IS MADE VISIBLE
      // subtitle:
      // "Choose between your own templates, an uploaded Word file, or a blank page. You can also upload PDF files that were signed offline.",
      component: TemplateSelector,
    },
    {
      purpose: "Parties",
      title: "Who are the parties to your agreement?",
      subtitle:
        "Select all organizations and/or persons that are party to your agreement. You can create new parties, or import them from your CRM.",
      component: PartySelector,
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    defaultSteps.push({
      purpose: "DirectoryBuilder",
      title: "Where do you want to store this agreement?",
      subtitle:
        "Leave the default location (as per your settings), or select a different location below.",
      component: DirectoryBuilderSelector,
    });
  }

  if (promptUsersToAddGeneralLabels) {
    defaultSteps.push({
      purpose: "AgreementLabels",
      title: "Do you want to add a label to this agreement?",
      subtitle:
        "General labels help organize your contracts. For example, you could apply a label ”Key Customer” to all contracts with important customers.",
      component: LabelsSelector,
    });
  }

  return defaultSteps;
}

// const hubSpotStep = {
//   purpose: "HubSpotTicket",
//   title: "What updates are needed in HubSpot?",
//   subtitle: "",
//   component: HubSpotSelector,
// };

/** @type {CreateAgreementStep} */
const createTicketStep = {
  purpose: "CreateTicket",
  title: "Do you want to add a HubSpot ticket to this agreement?",
  subtitle:
    "Adding a ticket allows you to assign work related to this agreement to someone else (for example, a person in Legal), and track the progress of that work.",
  component: CreateTicket,
};

/** @type {CreateAgreementStep} */
const trackedChangesAuthorshipStep = {
  purpose: "TrackedChangesAuthorshipStep",
  title: "Which party made the tracked changes contained in the document?",
  subtitle:
    "The uploaded file contains tracked changes or comments - we need to make sure to assign them to the correct party. \nIf you can't find that party in the dropdown, you need to first add it during the previous step.",
  component: TrackedChangesAuthorship,
};

/**
 * @typedef {object} AgreementData
 * @property {*} areaLabel
 * @property {*} tempAreaLabel
 * @property {*} template
 * @property {{ _id: string; parentID: string; fileType: string; file: string; content: string; agrTitle: string; agrTypeID: string; sfdt: *}[]} exhibits
 * @property {import("../components/createAgreement/TrackedChangesAuthorship").AgreementParty[]} parties
 * @property {string[]} labels
 * @property {import("../components/editor/nodes/MarkNode").MergeField[]} mergeFields
 * @property {{ label: string }[]} roles
 * @property {*} hubSpotTicketToUpdate
 * @property {import("../components/dialogs/DialogCreateTicket").Ticket[]} tickets
 * @property {import("../components/createAgreement/TrackedChangesAuthorship").AgreementParty | null} trackedChangesAuthorship
 */

export default function CreateAgreement() {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const navigate = useNavigate();
  const breakpoint = useWidth();
  const [searchParams] = useSearchParams();
  const hubSpotCompanyId = searchParams.get("hcid") || undefined;
  const hubSpotDealId = searchParams.get("hdid") || undefined;
  const ticketId = searchParams.get("ticketId") || undefined;

  const [activeStep, setActiveStep] = useState(0);
  const defaultLegalEntity = state.subs.find(
    (/** @type {{ defaultLegalEntity: boolean; }} */ subsidiary) =>
      subsidiary.defaultLegalEntity
  );
  /** @type {AgreementData} */
  const defaultAgreementData = {
    areaLabel: null,
    tempAreaLabel: null,
    template: null,
    exhibits: [],
    parties: defaultLegalEntity
      ? [
          {
            ...defaultLegalEntity,
            partyID: "party0",
            role: null,
          },
        ]
      : [],
    labels: [],
    mergeFields: [],
    roles: [],
    hubSpotTicketToUpdate: undefined,
    tickets: [],
    trackedChangesAuthorship: null,
  };
  const [agreementData, setAgreementData] = useState(defaultAgreementData);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  /** @type {CreateAgreementStep[]} */
  const initialSteps = state?.org?.integrations?.hubspot?.useOwnSubscription
    ? [
        ...getDefaultSteps(
          state?.user?.email,
          state?.org?.promptUsersToAddGeneralLabels
        ),
        ...(getCanveoTier(state?.user?.email) === "experimental"
          ? [createTicketStep]
          : []),
      ]
    : getDefaultSteps(
        state?.user?.email,
        state?.org?.promptUsersToAddGeneralLabels
      );

  const [steps, setSteps] = useState(initialSteps);

  const user = state.user;

  const metadata = {
    creatorId: user?._id,
    creatorEmail: user?.email,
    creatorDisplayName: user?.displayName,
    creatorPhotoUrl: user?.photoURL,
    creationDate: new Date().toUTCString(),
    partyId: "party0",
  };

  const handleNext = () => {
    if (agreementData.tempAreaLabel !== agreementData.areaLabel) {
      setAgreementData((prev) => ({
        ...prev,
        areaLabel: prev.tempAreaLabel,
        template: null,
        exhibits: [],
      }));
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * @param {"template" | "exhibits" | "tempAreaLabel" | "tickets"} property
   * @param {* | null} value
   * @param {"exhibits"} [secondProperty]
   * @param {*[]} [secondValue]
   */
  const agreementChange = async (
    property,
    value,
    secondProperty = undefined,
    secondValue = []
  ) => {
    const updateObject = {
      [property]: value,
    };

    if (secondProperty) {
      updateObject[secondProperty] = secondValue;
    }

    // If user selects the area again we need to reset the template and the merge fields.
    if (property === "tempAreaLabel") {
      updateObject.template = null;
      updateObject.mergeFields = [];
      setSteps(initialSteps);
    } else if (property === "template") {
      if (value) {
        // If the template was uploaded from the file system during agreement creation
        // and the document contains at least one revision or one comment we add a new
        // step to the creation agreement.
        if (
          value.uploadType === "import" &&
          (value?.sfdt?.comments?.length || value?.sfdt?.revisions?.length)
        ) {
          const partiesStepIndex = steps.findIndex(
            (step) => step.purpose === "Parties"
          );
          if (partiesStepIndex > -1) {
            const newSteps = [...steps];
            newSteps.splice(
              partiesStepIndex + 1,
              0,
              // @ts-ignore
              trackedChangesAuthorshipStep
            );
            setSteps(newSteps);
          } else {
            setSteps((previous) =>
              previous.filter(
                (step) => step.purpose !== "TrackedChangesAuthorshipStep"
              )
            );
          }

          setSteps((previous) => {
            return previous;
          });
        }

        // If we are updating an agreement that has exhibits.
        if (secondProperty === "exhibits") {
          const exhibitsIds = secondValue.map(
            (/** @type {{ _id: string; }} */ x) => x._id
          );

          const { documentMergeFields } =
            await loadTemplateMergeFieldsAndUpdateSteps(
              value._id,
              exhibitsIds,
              initialSteps
            );

          updateObject.mergeFields = documentMergeFields;
        }
        // If the agreement does not have exhibits.
        else {
          const exhibitsIds = (agreementData?.exhibits || []).map(
            // @ts-ignore
            (x) => x._id
          );
          const { documentMergeFields } =
            await loadTemplateMergeFieldsAndUpdateSteps(
              value._id,
              exhibitsIds,
              initialSteps
            );

          updateObject.mergeFields = documentMergeFields;
        }

        updateObject.roles = value.roles;

        const setOrganizationAsRoleMergeField = updateObject.mergeFields.find(
          (/** @type {{ setOrganizationAsRole: boolean; }} */ mergeField) =>
            mergeField.setOrganizationAsRole
        );
        if (setOrganizationAsRoleMergeField) {
          const entity = state.subs.find(
            (/** @type {{ orgID: string; }} */ x) =>
              x.orgID === setOrganizationAsRoleMergeField.organizationId
          );
          if (!entity) {
            throw new Error(
              `Entity must exist for organization ID ${setOrganizationAsRoleMergeField.organizationId}.`
            );
          }

          // If there is just one owner legal entity this entity will be set as the respective role for all
          // new agreements on the basis of this template (whether or not a default entity has been set).
          if (state.subs.length === 1) {
            const party = agreementData.parties.find(
              (p) => p._id === entity._id
            );
            // If the party was already added (e.g., it is the default legal entity) we update it instead.
            if (party) {
              party.role = setOrganizationAsRoleMergeField.partyRole;
              updateObject.parties = [...agreementData.parties];
            } else {
              updateObject.parties = [
                {
                  ...entity,
                  partyID: "party0",
                  role: setOrganizationAsRoleMergeField.partyRole,
                },
                ...agreementData.parties,
              ];
            }
          }

          // If there is more than one legal entity.
          else if (state.subs.length > 1) {
            // If a default entity has been set, this entity will be automatically assigned the respective role.
            if (defaultLegalEntity) {
              const party = agreementData.parties.find(
                (p) => p._id === defaultLegalEntity._id
              );
              // If the party was already added (e.g., it is the default legal entity) we update it instead.
              if (party) {
                party.role = setOrganizationAsRoleMergeField.partyRole;
                updateObject.parties = [...agreementData.parties];
              } else {
                updateObject.parties = [
                  {
                    ...defaultLegalEntity,
                    partyID: "party0",
                    role: setOrganizationAsRoleMergeField.partyRole,
                  },
                  ...agreementData.parties,
                ];
              }
            }

            // If no default entity has been set, then it is just the first entity that was created that will be used.
            else {
              const firstEntity = state.subs[0];
              const party = agreementData.parties.find(
                (p) => p._id === firstEntity._id
              );
              // If the party was already added (e.g., it is the default legal entity) we update it instead.
              if (party) {
                party.role = setOrganizationAsRoleMergeField.partyRole;
                updateObject.parties = [...agreementData.parties];
              } else {
                updateObject.parties = [
                  {
                    ...firstEntity,
                    partyID: "party0",
                    role: setOrganizationAsRoleMergeField.partyRole,
                  },
                  ...agreementData.parties,
                ];
              }
            }
          }
        }
      } else {
        setSteps(initialSteps);
      }
    }
    // If we add or remove an exhibit we need to recalculate the merge fields.
    else if (property === "exhibits") {
      const documentId = agreementData?.template?._id || "";
      const exhibitsIds = (value || []).map(
        (/** @type {{ _id: string; }} */ x) => x._id
      );

      const { documentMergeFields } =
        await loadTemplateMergeFieldsAndUpdateSteps(
          documentId,
          exhibitsIds,
          initialSteps
        );
      updateObject.mergeFields = documentMergeFields;
    }

    setAgreementData((prev) => ({ ...prev, ...updateObject }));

    // Trying to navigate automatically breaks agreement creation for some reason.
    // if (property === "tempAreaLabel") {
    //   // After picking the area label we automatically move to the next step.
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  };

  /**
   * @param {string | undefined} docId
   * @param {string[]} exhibitsIds
   * @param {*[]} initialSteps
   */
  const loadTemplateMergeFieldsAndUpdateSteps = async (
    docId,
    exhibitsIds = [],
    initialSteps = []
  ) => {
    // if (!docId) return { mergeFields: [], documentMergeFields: [] };

    const documentsIds = docId ? [docId, ...exhibitsIds] : [...exhibitsIds];

    const url = `${state.settings.api}document/query/documents/properties?documentsIds=${documentsIds}`;
    const response = await axios.get(url).catch((error) => {
      console.error(error);
    });

    if (!response) throw new Error("Error getting response.");
    /** @type {import("../components/editor/nodes/MarkNode").MergeField[]} */
    const documentMergeFields = response.data.data;
    if (!documentMergeFields) throw new Error("Error getting Merge Fields");

    if (!documentMergeFields.length) {
      return { mergeFields: [], documentMergeFields: [] };
    }

    const mergeFields = documentMergeFields
      // We do not display party information merge fields and merge fields
      // that have conditions in the agreement creation.
      .filter(
        (x) =>
          // x.documentId === docId &&
          x.type === "regular" && !x.conditions.length
      )
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const unpositionableMergeFields = mergeFields.filter(
      (x) => x.position === -1
    );

    // We use the same sorting order as the one in the questionnaire.
    const sortedMergeFields = [
      ...unpositionableMergeFields
        .filter((x) => x.scope === "questionnaire")
        .sort((a, b) => {
          return a.createdAt < b.createdAt ? 1 : -1;
        }),
      ...mergeFields
        .filter((x) => x.position > -1)
        .sort((previous, next) => (previous.position > next.position ? 1 : -1)),
      ...unpositionableMergeFields
        .filter((x) => x.scope === "document")
        .sort((a, b) => {
          return a.createdAt > b.createdAt ? 1 : -1;
        }),
    ];

    const mergeFieldsAgreementSteps = /** @type {CreateAgreementStep[]} */ (
      sortedMergeFields
        // // We don't display agreement parameters in the agreement flow but they are still part of the
        // // document merge fields and will still be added to the new agreement.
        // .filter((x) => x.scope === "document")
        // Do not include regular template merge fields.
        .filter((x) => !(x.type === "regular" && x.isTemplate === true))
        .map((mf) => ({
          title: mf.name,
          component: MergeFieldSelector,
          purpose: "MergeField",
          subtitle: "",
          mergeField: mf,
        }))
    );

    setSteps((prev) =>
      initialSteps.length > 0
        ? [...initialSteps, ...mergeFieldsAgreementSteps]
        : [...prev, ...mergeFieldsAgreementSteps]
    );

    let parameters = [];

    if (docId) {
      const getParametersResponse = await axios
        .get(`${state.settings.api}document/${docId}/parameters`)
        .catch((error) => {
          console.error(error);
        });

      if (!getParametersResponse) throw new Error("Error getting response.");

      parameters = getParametersResponse?.data?.data || [];
    }

    documentMergeFields.push(...parameters);
    const finalDocumentMergeFields = documentMergeFields.filter(
      (x) => x.scope !== "questionnaire"
    );
    finalDocumentMergeFields.push(...parameters);

    return { mergeFields, documentMergeFields: finalDocumentMergeFields };
  };

  /**
   * @param {*} purpose
   * @returns
   */
  const checkCanNext = (purpose) => {
    switch (purpose) {
      case "AreaLabels":
        return !!agreementData.tempAreaLabel;
      case "Template":
        return !!agreementData.template;
      case "TrackedChangesAuthorshipStep": {
        return !!agreementData.trackedChangesAuthorship;
      }
      default:
        return true;
    }
  };

  /**
   * @param {*} creationDate
   * @param {*} organizations
   * @param {*} entities
   * @param {boolean} withId
   */
  const getExhibits = (
    creationDate,
    organizations,
    entities,
    withId = false
  ) => {
    const exhibits = agreementData.exhibits || [];
    const populatedExhibits = exhibits.map((exhibit, index) => {
      const exhibitData = {
        ...exhibit,
        priority: (index + 1) * 10,
        organizations: organizations,
        entities: entities,
      };

      const hydratedExhibit = getExhibit(
        exhibitData,
        creationDate,
        state.user._id,
        state.org._id,
        withId
      );

      const populatedExhibit = {
        ...hydratedExhibit,
        agreementTemplateId: exhibit._id,
      };

      return populatedExhibit;
    });

    return populatedExhibits;
  };

  /**
   * @typedef {{ uid: any; partyID: string; entityName: any; email: any; order: null; }} Signer
   */

  /**
   * @param {boolean} isBackload
   */
  const extractPartyInfo = (isBackload) => {
    const organizations = [state.org._id];
    const entities = [];
    const /** @type {Signer[]} */ signers = [];
    const collaborators = [];

    let parties = agreementData.parties || [];
    // If no parties were set during agreement creation and organization
    // has configured a default entity, we use that as the entity for the
    // ghost party.
    if (!agreementData?.parties?.length) {
      const defaultLegalEntity = state.subs.find(
        (/** @type {{ defaultLegalEntity: boolean; }} */ x) =>
          x.defaultLegalEntity
      );
      if (defaultLegalEntity) {
        parties = [
          {
            ...defaultLegalEntity,
            partyID: "party0",
            role: null,
          },
        ];
      }
    }

    // Loop through the agreement parties to enrich the respective arrays with the necessary data.
    for (const party of parties) {
      organizations.push(party.orgID);
      entities.push({
        entID: party._id,
        role: party.role,
        partyID: party.partyID,
        myClient: party.myClient,
      });

      // @ts-ignore
      if (!!party.pers && !isBackload) {
        // Add any person automatically as a collaborator and signer.
        collaborators.push({
          // @ts-ignore
          uid: party.pers._id,
          // @ts-ignore
          email: party.pers.email,
          // @ts-ignore
          orgID: party.pers.orgID,
          partyID: party.partyID,
        });
        signers.push({
          // @ts-ignore
          uid: party.pers._id,
          partyID: party.partyID,
          // @ts-ignore
          entityName: party.ent.legalName,
          // @ts-ignore
          email: party.pers.email,
          order: null,
        });
      }

      if (party?.defaultSigners?.length) {
        const partySigners = party.defaultSigners.map((signer) => ({
          uid: signer._id,
          partyID: party.partyID,
          entityName: party.legalName,
          email: signer.email,
          order: null,
        }));
        signers.push(...partySigners);
      }
    }

    if (!parties.find((p) => p.orgID === state.org._id)) {
      entities.push({
        entID: state.subs[0]._id,
        partyID: "party0",
      });
    }

    collaborators.push({
      uid: state.user._id,
      email: state.user.email,
      orgID: state.user.orgID,
      partyID: "party0",
    });

    // Remove duplicated organization IDs (specific case where our current organization is also a party).
    // @ts-ignore
    const uniqueOrganizations = [...new Set(organizations)];

    return {
      organizations: uniqueOrganizations,
      entities,
      signers,
      collaborators,
    };
  };

  const getLabels = () => {
    const labels = agreementData.labels.map((label) => ({
      orgID: state.org._id,
      labelID: label,
    }));

    const areaLabels = [
      {
        orgID: agreementData.areaLabel.orgID,
        labelID: agreementData.areaLabel._id,
      },
    ];

    return { labels, areaLabels };
  };

  /**
   * @typedef {object} SfdtRevision
   * @property {string} author
   * @property {string} date
   * @property {string} revisionType
   * @property {string} revisionId
   */

  /**
   * @typedef {object} NodeMetadata
   * @property {string} partyId
   * @property {string} creatorDisplayName
   * @property {string} creationDate
   * @property {string} creatorEmail
   * @property {string} creatorId
   * @property {string} creatorPhotoUrl
   * @property {string} revisionId
   */

  /**
   * @typedef {{ type: string; children?: Node[]; partyID: string; metadata?: NodeMetadata}} Node
   */

  /**
   * Traverses the entire node tree with a depth-first search approach and changes the metadata
   * of mark nodes based on the authorship parameter by **MUTATING** the tree sent by parameter.
   *
   * @param {Node} node
   * @param {import("../components/createAgreement/TrackedChangesAuthorship").AgreementParty} authorship
   * @param {(node: Node, authorship: import("../components/createAgreement/TrackedChangesAuthorship").AgreementParty) => void} changeAuthorshipFunction
   */
  function updateNodesAuthorship(node, authorship, changeAuthorshipFunction) {
    /**
     * @param {Node} node
     */
    function traverse(node) {
      // Run custom function sent by parameter.
      changeAuthorshipFunction(node, authorship);
      // Traverse the children recursively.
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          traverse(child);
        }
      }
    }
    traverse(node);
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      setErrorMsg("");

      const { agreementId, exhibits } = await createMainAgreement();
      for (const exhibit of exhibits) {
        const exhibitToCreate = {
          ...exhibit,
          // Set the parentID to be the ID of the newly created agreement so that we associate
          // the exhibit with the agreement.
          parentID: agreementId,
        };

        await createExhibit(exhibitToCreate);
      }

      /** @type {import("react-router-dom").To} */
      const to = {
        pathname: `/agreement/${agreementId}`,
      };

      // Commented for now.
      // // If agreement has exhibits we want the exhibits drawer to open by default.
      // if (exhibits?.length) {
      //   to.search = createSearchParams({
      //     openedDrawer: "exhibits",
      //   }).toString();
      // }

      navigate(to);
    } catch (error) {
      console.error(error);
      setErrorMsg(
        "Unable to create the agreement, try again or contact Canveo Support if the issue persists"
      );
    } finally {
      setLoading(false);
    }
  }

  /**
   * @returns {Promise<{ agreementId: string, exhibits: * []}>}
   */
  async function createMainAgreement() {
    const isBackload = ["backload"].includes(agreementData.template.uploadType);
    const creationDate = new Date().toISOString();
    const { organizations, entities, signers, collaborators } =
      extractPartyInfo(isBackload);
    const { labels, areaLabels } = getLabels();
    const orgSettings = state.org?.orgSettings;
    const wLabel =
      !isBackload &&
      orgSettings?.whiteLabel?.level === "email" &&
      !!orgSettings?.whiteLabel?.color
        ? orgSettings.whiteLabel
        : null;
    const customURL = !isBackload ? generateRandomString(20) : null;
    const description = `Contains work done by ${state.org.shortName}`;
    let exhibits = getExhibits(creationDate, organizations, entities);
    const template = agreementData.template;
    const origin = getOrigin(template);
    const roles = agreementData?.parties
      ?.filter((party) => !!party.role)
      ?.map((party) => ({
        label: party.role,
      }));
    const creationFlowMergeFields = agreementData.mergeFields;
    const agreementTemplateId = template._id;
    const fileExtension =
      template?.fileName?.toLowerCase()?.split(".")?.pop() ||
      template?.origin?.import?.split("#")?.at(0)?.split(".")?.pop();
    const pdfFileKey = fileExtension === "pdf" ? template.file : "";

    /** @type {MergeField[]} */
    const documentMergeFields = [];
    if (agreementTemplateId) {
      const getDocumentMergeFieldsResponse = await axios
        .get(`${state.settings.api}document/${agreementTemplateId}/mergeFields`)
        .catch(() => {
          setLoading(false);
          setErrorMsg("Unable to fetch the agreement merge fields.");
        });

      if (!getDocumentMergeFieldsResponse) {
        throw new Error("Error fetching merge fields.");
      }

      documentMergeFields.push(
        ...(getDocumentMergeFieldsResponse?.data?.data?.filter(
          (/** @type {{ isTemplate: boolean; }} */ mf) =>
            mf.isTemplate === false
        ) || [])
      );
    }

    // Replace the template merge fields with the corresponding merge fields that have possibly been changed
    // during the creation flow as well as the base value for conditions.
    /** @type {MergeField[]} */
    const updatedDocumentMergeFields = [];
    for (const documentMergeField of documentMergeFields) {
      /** @type {Condition[]} */
      const updatedConditions = [];
      for (const condition of documentMergeField.conditions) {
        const updatedBaseValue = creationFlowMergeFields.find(
          (x) => x._id === condition.baseValue._id
        );
        if (updatedBaseValue) {
          updatedConditions.push({
            ...condition,
            baseValue: updatedBaseValue,
          });
        } else {
          updatedConditions.push(condition);
        }
      }

      const updatedDocumentMergeField = creationFlowMergeFields.find(
        (x) => x._id === documentMergeField._id
      );
      if (updatedDocumentMergeField) {
        updatedDocumentMergeFields.push({
          ...updatedDocumentMergeField,
          conditions: updatedConditions,
        });
      } else {
        updatedDocumentMergeFields.push({
          ...documentMergeField,
          conditions: updatedConditions,
        });
      }
    }

    const agreementParameters = creationFlowMergeFields.filter(
      (x) => x.scope === "questionnaire"
    );
    if (agreementParameters.length) {
      updatedDocumentMergeFields.push(...agreementParameters);
    }

    // Conditional assembly of exhibits.
    if (template?.conditions?.length) {
      for (const condition of template?.conditions) {
        const { baseValue, selectedExhibits } = condition;

        const mergeField = updatedDocumentMergeFields.find(
          (x) => x._id === baseValue._id
        );

        const updatedCondition = {
          ...condition,
          baseValue: mergeField ?? baseValue,
          mergeFieldValue: {
            success: true,
          },
        };

        const value = calculateCondition(updatedCondition);
        // @ts-ignore
        if (value?.success) {
          exhibits = getExhibits(creationDate, organizations, entities, true);
          exhibits = exhibits
            .filter((exhibit) =>
              selectedExhibits.find(
                (/** @type {*} */ se) => se._id === exhibit._id
              )
            )
            .map((x) => {
              return {
                ...x,
                _id: undefined,
              };
            });
        }

        break;
      }
    }

    const mainAgreement = {
      versionType: ["pdf", "docx", "xlsx", "pptx"].includes(
        agreementData.template?.fileType
      ) // AgreementVersion related
        ? agreementData.template?.fileType // This is an attachment
        : "canveo", // This is a Canveo Doc
      content: agreementData?.template?.content,
      contentMetadata: {
        listsStructure:
          agreementData.template.contentMetadata?.listsStructure ?? [],
      },
      agrTypeID: agreementData.template?.agrTypeID,
      agrTitle: agreementData.template?.agrTitle,
      agrStatus: isBackload ? "InEffect" : "Draft",
      orgs: organizations,
      ents: entities,
      collabs: collaborators,
      signers: signers,
      priority: 0,
      amendment: null,
      avOwners: [state.org._id],
      createdByOrg: state.org._id,
      owner: state.org._id,
      effectiveDate: null,
      renewalType: "auto",
      effectiveTerm: null,
      expiryDate: null,
      sigConfig: {
        // provider: state.org?.signMethod ?? "skribble",
        provider: state?.org?.integrations?.docusign?.useOwnSubscription
          ? "docusign"
          : "skribble",
        quality: "SES",
      },
      // exhibits,
      dealValue: [],
      labels: labels,
      areaLabels: areaLabels,
      customURL: customURL,
      whiteLabel: wLabel,
      creationBy: state.user._id,
      creationDate: creationDate,
      lastUpdateBy: state.user._id,
      lastUpdateDate: creationDate,
      fileSource: agreementData.template?.fileSource?.value,
      origin: origin,
      description: description,
      sfdt: agreementData.template?.sfdt,
      firstPageHeader: agreementData.template?.firstPageHeader,
      firstPageFooter: agreementData.template?.firstPageFooter,
      visibleTo: [state.org._id],
      tid: agreementData.template.versionId,
      hubSpotCompanyId,
      hubSpotDealId,
      hubSpotTicketToUpdate: agreementData.hubSpotTicketToUpdate,
      roles,
      tickets: agreementData.tickets,
      workflowCreationOrganization:
        agreementData.trackedChangesAuthorship?.orgID,
      pdfFileKey,
    };

    if (agreementData.trackedChangesAuthorship) {
      updateNodesAuthorship(
        mainAgreement.content.root,
        agreementData.trackedChangesAuthorship,
        (node, authorship) => {
          if (node.type === "mark" && node.metadata) {
            node.metadata.partyId = authorship.partyID;
          }

          if (node.type === "redline") {
            /**
             * @type {SfdtRevision}
             */
            const revision = mainAgreement.sfdt.revisions.find(
              (/** @type {{ date: string; }} */ revision) =>
                // Using the date because the revisionId for some reason is different.
                revision?.date === node?.metadata?.creationDate
            );

            if (node.metadata && revision) {
              node.partyID = authorship.partyID;
              node.metadata = {
                ...node.metadata,
                creationDate: revision.date,
                creatorDisplayName: revision.author,
                creatorEmail: revision.author,
                partyId: authorship.partyID,
                creatorPhotoUrl: authorship.logoURL,
              };
            }
          }
        }
      );
    }

    const createdAgreementResponse = await axios
      .post(state.settings.api + "agr", {
        agr: mainAgreement,
        // Pass the exhibits conditions.
        conditions: agreementData.template.conditions,
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg(
          "Unable to create the agreement, try again or contact Canveo Support if the issue persists"
        );
      });
    if (!createdAgreementResponse) {
      throw new Error("Error creating agreement.");
    }
    const createdAgreement = createdAgreementResponse.data.data.agr;

    /** @type {string} */
    const agreementId = createdAgreement._id;

    /** @type {{ _id: string; shortName: string }[]} */
    const organizationsWithData = [];
    for (const organizationId of organizations) {
      const getOrganizationWithDataResponse = await axios.get(
        `${state.settings.api}org/${organizationId}`
      );
      const organizationWithData = getOrganizationWithDataResponse.data.data;
      organizationsWithData.push(organizationWithData);
    }

    const agreementMergeFields = updatedDocumentMergeFields.map(
      (documentMergeField) => {
        let displayValue = documentMergeField.displayValue;
        // let setValueLater = documentMergeField.setValueLater;

        // // We only calculate the values if the merge field is of type party information
        // // or if the set value later was not enabled (unless it is a list, if it is a list
        // // we ignore that flag).
        // if (
        //   documentMergeField.type === "partyInformation" ||
        //   documentMergeField.isList ||
        //   !documentMergeField.setValueLater
        // ) {

        // Now we always calculate when creating an agreement.
        const calculatedValue = calculateMergeFieldValue(
          documentMergeField,
          agreementData.parties,
          organizationsWithData
        );

        if (calculatedValue) {
          displayValue = calculatedValue;
        }

        // If we are not able to calculate a party information merge field
        // we keep the set value later option enabled.
        // setValueLater =
        //   documentMergeField.type === "partyInformation" && !calculatedValue;
        // }

        return {
          ...documentMergeField,
          displayValue,
          // Update the document ID of the merge field with the new Agreement ID.
          documentId: agreementId,
          // Clear the ID from the template since this will be generated on the DB
          // for the new agreement merge fields.
          _id: undefined,
          setValueLater: false,
        };
      }
    );

    const createMergeFieldsResponse = await axios
      .post(state.settings.api + "mergefield/bulk", {
        mergeFields: agreementMergeFields,
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg(
          "Unable to create the Merge Fields associated with the agreement, try again or contact Canveo Support if the issue persists"
        );
      });
    if (!createMergeFieldsResponse) {
      throw new Error("Error creating Merge Fields");
    }
    /** @type {import("../components/editor/nodes/MarkNode").MergeField[]}} */
    const calculatedAgreementMergeFields = createMergeFieldsResponse.data.data;

    const agreementContent = mainAgreement.content;
    const editor = createEditor(editorConfig);
    editor.setEditorState(
      editor.parseEditorState(JSON.stringify(agreementContent))
    );

    let hasCalculations = false;

    // Updated editor content with new merge field values.
    await new Promise((resolve) => {
      editor.update(() => {
        const depthFirstSearch = $dfs();
        for (const { node } of depthFirstSearch) {
          if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
            // There can be no overlapping Merge Fields, but there can be Merge Fields
            // that overlap with comments. If that happens we just make sure we are
            // pulling the ID that corresponds to a Merge Field.
            const markNodeId = node.getIDs().find((id) => id.startsWith("pa"));

            const mergeField = calculatedAgreementMergeFields.find(
              (dmf) => dmf.editorMarkNodeId === markNodeId
            );

            if (!mergeField) {
              throw new Error(
                "Mark node does not have a corresponding merge field."
              );
            }

            // Update merge field inside the mark node with the new display value.
            node.setMergeField(mergeField);

            const displayValue = mergeField.displayValue;
            if (!displayValue) continue;

            const nodeTextContent = node.getTextContent();
            if (
              displayValue.toLowerCase() !== nodeTextContent.toLowerCase() &&
              // You would expect this value to be true, but in a previous step
              // we set it to false so that when we create the agreement it doesn't
              // show with the setValueLater checkbox selected.
              !mergeField.setValueLater
            ) {
              // Mark original text as a deletion.
              const deletion = $createRedlineNode({
                partyID: "party0",
                redlineType: "del",
                date: new Date().toUTCString(),
                metadata,
                text: node.getTextContent(),
              });

              // Mark new text as an addition.
              const addition = $createRedlineNode({
                partyID: "party0",
                redlineType: "add",
                date: new Date().toUTCString(),
                metadata,
                text: mergeField.displayValue,
              });

              const [textNode] = node.getChildren();
              if (textNode && $isTextNode(textNode)) {
                deletion.setFormat(textNode.getFormat());
                deletion.setDetail(textNode.getDetail());
                deletion.setStyle(textNode.getStyle());

                addition.setFormat(textNode.getFormat());
                addition.setDetail(textNode.getDetail());
                addition.setStyle(textNode.getStyle());
              }

              node.append(deletion, addition);

              const children = node.getChildren();
              // Remove previous children.
              const childrenToRemove = children.slice(0, children.length - 2);
              childrenToRemove.forEach((child) => child.remove());

              hasCalculations = true;
            }
          }
        }

        // Resolve the promise after all the editor updates.
        resolve(true);
      });
    });

    const updatedEditorStateContent = editor.getEditorState().toJSON();

    const agreementVersionsResponse = await axios.get(
      `${state.settings.api}agrv/${agreementId}`
    );
    if (!agreementVersionsResponse) {
      throw new Error("Error fetching agreement versions.");
    }

    const agreementVersions = agreementVersionsResponse.data.data;
    const [latestVersion] = agreementVersions;

    // Latest version gets the calculated values for the new merge fields, but
    // the original version keeps the merge fields as they were in the template.
    const updatedAgreementResponse = await axios
      .put(state.settings.api + `agrv/newcontent/${latestVersion._id}`, {
        newContent: updatedEditorStateContent,
        contentMetadata: {
          listsStructure:
            agreementData.template.contentMetadata?.listsStructure ?? [],
        },
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg(
          "Unable to create the Merge Fields associated with the agreement, try again or contact Canveo Support if the issue persists"
        );
      });
    if (!updatedAgreementResponse) {
      throw new Error("Error updating latest agreement version.");
    }

    // If there are merge field calculations we create an extra version with all
    // the redlines from the merge field calculations resolved.
    if (hasCalculations) {
      const agreementContent = mainAgreement.content;
      const editor = createEditor(editorConfig);
      editor.setEditorState(
        editor.parseEditorState(JSON.stringify(agreementContent))
      );

      // Updated editor content with new merge field values.
      await new Promise((resolve) => {
        editor.update(() => {
          const depthFirstSearch = $dfs();
          for (const { node } of depthFirstSearch) {
            if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
              // There can be no overlapping Merge Fields, but there can be Merge Fields
              // that overlap with comments. If that happens we just make sure we are
              // pulling the ID that corresponds to a Merge Field.
              const markNodeId = node
                .getIDs()
                .find((id) => id.startsWith("pa"));

              const mergeField = calculatedAgreementMergeFields.find(
                (dmf) => dmf.editorMarkNodeId === markNodeId
              );

              if (!mergeField) {
                throw new Error(
                  "Mark node does not have a corresponding merge field."
                );
              }

              // Update merge field inside the mark node with the new display value.
              node.setMergeField(mergeField);

              const displayValue = mergeField.displayValue;
              if (!displayValue) continue;

              const nodeTextContent = node.getTextContent();
              if (
                displayValue.toLowerCase() !== nodeTextContent.toLowerCase() &&
                // You would expect this value to be true, but in a previous step
                // we set it to false so that when we create the agreement it doesn't
                // show with the setValueLater checkbox selected.
                !mergeField.setValueLater
              ) {
                const [redlineNode] = node.getChildren();

                // Mark new text as an addition.
                const textNode = $createTextNode(mergeField.displayValue);
                if (redlineNode && $isTextNode(redlineNode)) {
                  textNode.setFormat(redlineNode.getFormat());
                  textNode.setDetail(redlineNode.getDetail());
                  textNode.setStyle(redlineNode.getStyle());
                }

                node.append(textNode);

                // Retrieve children again to take into account the node that was appended.
                const children = node.getChildren();
                // Remove previous children.
                const childrenToRemove = children.slice(0, children.length - 1);
                childrenToRemove.forEach((child) => child.remove());
              }
            }
          }

          // Resolve the promise after all the editor updates.
          resolve(true);
        });
      });

      const updatedEditorStateContent = editor.getEditorState().toJSON();

      const fullLatestVersionResponse = await axios.get(
        `${state.settings.api}agrv/${latestVersion._id}/full`
      );
      if (!fullLatestVersionResponse) {
        throw new Error("Error getting full latest agreement version.");
      }

      const fullLatestVersion = fullLatestVersionResponse.data.data;

      // TODO: Add approved merge fields.

      await axios.post(`${state.settings.api}agrv/duplicate`, {
        versionID: latestVersion._id,
        newDetails: {
          ...fullLatestVersion,
          content: updatedEditorStateContent,
          origin: undefined,
          _id: undefined,
          version: undefined,
        },
      });
    }

    // If there is a ticket ID we associate the agreement with the ticket.
    if (ticketId) {
      await axios.put(`${state.settings.api}task/${ticketId}`, {
        agreementId,
      });
    }

    return { agreementId, exhibits: exhibits || [] };
  }

  /**
   * @param {*} exhibit
   */
  async function createExhibit(exhibit) {
    const isBackload = ["backload"].includes(agreementData.template.uploadType);
    const { organizations } = extractPartyInfo(isBackload);
    const creationFlowMergeFields = agreementData.mergeFields;
    const agreementTemplateId = exhibit.agreementTemplateId;

    /** @type {import("../components/editor/nodes/MarkNode").MergeField[]} */
    const documentMergeFields = [];

    if (agreementTemplateId) {
      const getDocumentMergeFieldsResponse = await axios
        .get(`${state.settings.api}document/${agreementTemplateId}/mergeFields`)
        .catch(() => {
          setLoading(false);
          setErrorMsg("Unable to fetch the agreement merge fields.");
        });

      if (!getDocumentMergeFieldsResponse) {
        throw new Error("Error fetching merge fields.");
      }

      documentMergeFields.push(
        ...(getDocumentMergeFieldsResponse?.data?.data?.filter(
          (/** @type {{ isTemplate: boolean; }} */ mf) =>
            mf.isTemplate === false
        ) || [])
      );
    }

    // Replace the template merge fields with the corresponding merge fields that
    // have possibly been changed during the creation flow as well as the base value
    // for conditions.
    /** @type {import("../components/editor/nodes/MarkNode").MergeField[]} */
    const updatedDocumentMergeFields = [];
    for (const documentMergeField of documentMergeFields) {
      /** @type {import("../components/MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition[]} */
      const updatedConditions = [];
      for (const condition of documentMergeField.conditions) {
        const updatedBaseValue = creationFlowMergeFields.find(
          (x) => x._id === condition.baseValue._id
        );
        if (updatedBaseValue) {
          updatedConditions.push({
            ...condition,
            baseValue: updatedBaseValue,
          });
        } else {
          updatedConditions.push(condition);
        }
      }

      const updatedDocumentMergeField = creationFlowMergeFields.find(
        (x) => x._id === documentMergeField._id
      );
      if (updatedDocumentMergeField) {
        updatedDocumentMergeFields.push({
          ...updatedDocumentMergeField,
          conditions: updatedConditions,
        });
      } else {
        updatedDocumentMergeFields.push({
          ...documentMergeField,
          conditions: updatedConditions,
        });
      }
    }

    const agreementParameters = creationFlowMergeFields.filter(
      (x) => x.scope === "questionnaire"
    );
    if (agreementParameters.length) {
      updatedDocumentMergeFields.push(...agreementParameters);
    }

    const mainAgreement = exhibit;

    if (agreementData.trackedChangesAuthorship) {
      updateNodesAuthorship(
        mainAgreement.content.root,
        agreementData.trackedChangesAuthorship,
        (node, authorship) => {
          if (node.type === "mark" && node.metadata) {
            node.metadata.partyId = authorship.partyID;
          }

          if (node.type === "redline") {
            /**
             * @type {SfdtRevision}
             */
            const revision = mainAgreement.sfdt.revisions.find(
              (/** @type {{ date: string; }} */ revision) =>
                // Using the date because the revisionId for some reason is different.
                revision?.date === node?.metadata?.creationDate
            );

            if (node.metadata && revision) {
              node.partyID = authorship.partyID;
              node.metadata = {
                ...node.metadata,
                creationDate: revision.date,
                creatorDisplayName: revision.author,
                creatorEmail: revision.author,
                partyId: authorship.partyID,
                creatorPhotoUrl: authorship.logoURL,
              };
            }
          }
        }
      );
    }

    const createdAgreementResponse = await axios
      .post(state.settings.api + "agr", {
        agr: mainAgreement,
        // Pass the exhibits conditions.
        conditions: agreementData.template.conditions,
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg(
          "Unable to create the agreement, try again or contact Canveo Support if the issue persists"
        );
      });
    if (!createdAgreementResponse) {
      throw new Error("Error creating agreement.");
    }
    const createdAgreement = createdAgreementResponse.data.data.agr;

    /** @type {string} */
    const agreementId = createdAgreement._id;

    /** @type {{ _id: string; shortName: string }[]} */
    const organizationsWithData = [];
    for (const organizationId of organizations) {
      const getOrganizationWithDataResponse = await axios.get(
        `${state.settings.api}org/${organizationId}`
      );
      const organizationWithData = getOrganizationWithDataResponse.data.data;
      organizationsWithData.push(organizationWithData);
    }

    const agreementMergeFields = updatedDocumentMergeFields.map(
      (documentMergeField) => {
        let displayValue = documentMergeField.displayValue;
        // let setValueLater = documentMergeField.setValueLater;

        // // We only calculate the values if the merge field is of type party information
        // // or if the set value later was not enabled (unless it is a list, if it is a list
        // // we ignore that flag).
        // if (
        //   documentMergeField.type === "partyInformation" ||
        //   documentMergeField.isList ||
        //   !documentMergeField.setValueLater
        // ) {

        // Now we always calculate when creating an agreement.
        const calculatedValue = calculateMergeFieldValue(
          documentMergeField,
          agreementData.parties,
          organizationsWithData
        );

        if (calculatedValue) {
          displayValue = calculatedValue;
        }

        // If we are not able to calculate a party information merge field
        // we keep the set value later option enabled.
        // setValueLater =
        //   documentMergeField.type === "partyInformation" && !calculatedValue;
        // }

        return {
          ...documentMergeField,
          displayValue,
          // Update the document ID of the merge field with the new Agreement ID.
          documentId: agreementId,
          // Clear the ID from the template since this will be generated on the DB
          // for the new agreement merge fields.
          _id: undefined,
          setValueLater: false,
        };
      }
    );

    const createMergeFieldsResponse = await axios
      .post(state.settings.api + "mergefield/bulk", {
        mergeFields: agreementMergeFields,
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg(
          "Unable to create the Merge Fields associated with the agreement, try again or contact Canveo Support if the issue persists"
        );
      });
    if (!createMergeFieldsResponse) {
      throw new Error("Error creating Merge Fields");
    }
    /** @type {import("../components/editor/nodes/MarkNode").MergeField[]}} */
    const calculatedAgreementMergeFields = createMergeFieldsResponse.data.data;

    const agreementContent = mainAgreement.content;
    const editor = createEditor(editorConfig);
    editor.setEditorState(
      editor.parseEditorState(JSON.stringify(agreementContent))
    );

    let hasCalculations = false;

    // Updated editor content with new merge field values.
    await new Promise((resolve) => {
      editor.update(() => {
        const depthFirstSearch = $dfs();
        for (const { node } of depthFirstSearch) {
          if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
            // There can be no overlapping Merge Fields, but there can be Merge Fields
            // that overlap with comments. If that happens we just make sure we are
            // pulling the ID that corresponds to a Merge Field.
            const markNodeId = node.getIDs().find((id) => id.startsWith("pa"));

            const mergeField = calculatedAgreementMergeFields.find(
              (dmf) => dmf.editorMarkNodeId === markNodeId
            );

            if (!mergeField) {
              throw new Error(
                "Mark node does not have a corresponding merge field."
              );
            }

            // Update merge field inside the mark node with the new display value.
            node.setMergeField(mergeField);

            const displayValue = mergeField.displayValue;
            if (!displayValue) continue;

            const nodeTextContent = node.getTextContent();
            if (
              displayValue.toLowerCase() !== nodeTextContent.toLowerCase() &&
              // You would expect this value to be true, but in a previous step
              // we set it to false so that when we create the agreement it doesn't
              // show with the setValueLater checkbox selected.
              !mergeField.setValueLater
            ) {
              // Mark original text as a deletion.
              const deletion = $createRedlineNode({
                partyID: "party0",
                redlineType: "del",
                date: new Date().toUTCString(),
                metadata,
                text: node.getTextContent(),
              });

              // Mark new text as an addition.
              const addition = $createRedlineNode({
                partyID: "party0",
                redlineType: "add",
                date: new Date().toUTCString(),
                metadata,
                text: mergeField.displayValue,
              });

              const [textNode] = node.getChildren();
              if (textNode && $isTextNode(textNode)) {
                deletion.setFormat(textNode.getFormat());
                deletion.setDetail(textNode.getDetail());
                deletion.setStyle(textNode.getStyle());

                addition.setFormat(textNode.getFormat());
                addition.setDetail(textNode.getDetail());
                addition.setStyle(textNode.getStyle());
              }

              node.append(deletion, addition);

              const children = node.getChildren();
              // Remove previous children.
              const childrenToRemove = children.slice(0, children.length - 2);
              childrenToRemove.forEach((child) => child.remove());

              hasCalculations = true;
            }
          }
        }

        // Resolve the promise after all the editor updates.
        resolve(true);
      });
    });

    const updatedEditorStateContent = editor.getEditorState().toJSON();

    const agreementVersionsResponse = await axios.get(
      `${state.settings.api}agrv/${agreementId}`
    );
    if (!agreementVersionsResponse) {
      throw new Error("Error fetching agreement versions.");
    }

    const agreementVersions = agreementVersionsResponse.data.data;
    const [latestVersion] = agreementVersions;

    // Latest version gets the calculated values for the new merge fields, but
    // the original version keeps the merge fields as they were in the template.
    const updatedAgreementResponse = await axios
      .put(state.settings.api + `agrv/newcontent/${latestVersion._id}`, {
        newContent: updatedEditorStateContent,
        contentMetadata: {
          listsStructure:
            agreementData.template.contentMetadata?.listsStructure ?? [],
        },
      })
      .catch(() => {
        setLoading(false);
        setErrorMsg(
          "Unable to create the Merge Fields associated with the agreement, try again or contact Canveo Support if the issue persists"
        );
      });
    if (!updatedAgreementResponse) {
      throw new Error("Error updating latest agreement version.");
    }

    // If there are merge field calculations we create an extra version with all
    // the redlines from the merge field calculations resolved.
    if (hasCalculations) {
      const agreementContent = mainAgreement.content;
      const editor = createEditor(editorConfig);
      editor.setEditorState(
        editor.parseEditorState(JSON.stringify(agreementContent))
      );

      // Updated editor content with new merge field values.
      await new Promise((resolve) => {
        editor.update(() => {
          const depthFirstSearch = $dfs();
          for (const { node } of depthFirstSearch) {
            if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
              // There can be no overlapping Merge Fields, but there can be Merge Fields
              // that overlap with comments. If that happens we just make sure we are
              // pulling the ID that corresponds to a Merge Field.
              const markNodeId = node
                .getIDs()
                .find((id) => id.startsWith("pa"));

              const mergeField = calculatedAgreementMergeFields.find(
                (dmf) => dmf.editorMarkNodeId === markNodeId
              );

              if (!mergeField) {
                throw new Error(
                  "Mark node does not have a corresponding merge field."
                );
              }

              // Update merge field inside the mark node with the new display value.
              node.setMergeField(mergeField);

              const displayValue = mergeField.displayValue;
              if (!displayValue) continue;

              const nodeTextContent = node.getTextContent();
              if (
                displayValue.toLowerCase() !== nodeTextContent.toLowerCase() &&
                // You would expect this value to be true, but in a previous step
                // we set it to false so that when we create the agreement it doesn't
                // show with the setValueLater checkbox selected.
                !mergeField.setValueLater
              ) {
                const [redlineNode] = node.getChildren();

                // Mark new text as an addition.
                const textNode = $createTextNode(mergeField.displayValue);
                if (redlineNode && $isTextNode(redlineNode)) {
                  textNode.setFormat(redlineNode.getFormat());
                  textNode.setDetail(redlineNode.getDetail());
                  textNode.setStyle(redlineNode.getStyle());
                }

                node.append(textNode);

                // Retrieve children again to take into account the node that was appended.
                const children = node.getChildren();
                // Remove previous children.
                const childrenToRemove = children.slice(0, children.length - 1);
                childrenToRemove.forEach((child) => child.remove());
              }
            }
          }

          // Resolve the promise after all the editor updates.
          resolve(true);
        });
      });

      const updatedEditorStateContent = editor.getEditorState().toJSON();

      const fullLatestVersionResponse = await axios.get(
        `${state.settings.api}agrv/${latestVersion._id}/full`
      );
      if (!fullLatestVersionResponse) {
        throw new Error("Error getting full latest agreement version.");
      }

      const fullLatestVersion = fullLatestVersionResponse.data.data;

      // TODO: Add approved merge fields.

      await axios.post(`${state.settings.api}agrv/duplicate`, {
        versionID: latestVersion._id,
        newDetails: {
          ...fullLatestVersion,
          content: updatedEditorStateContent,
          origin: undefined,
          _id: undefined,
          version: undefined,
        },
      });
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CanveoCircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Header page={"New"} />

      <Container sx={{ my: 8, mx: "auto" }} maxWidth={breakpoint}>
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="stretch"
          sx={{ flexWrap: "nowrap" }}
        >
          <Grid item>
            <Box sx={{ mt: 7, mb: 4 }}>
              <Typography align="center" variant="h4">
                Create New Agreement
              </Typography>
              {errorMsg && (
                <Typography
                  align="center"
                  // @ts-ignore
                  variant="subtitle"
                  color="error"
                >
                  {errorMsg}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => {
                const Component = step.component;

                // // If the user has the role "Business" he can only proceed if he has added a ticket.
                // const canProceed =
                //   index === steps.length - 1 &&
                //   state.user.role.name === "Business"
                //     ? Boolean(agreementData.tickets.length)
                //     : checkCanNext(step.purpose);

                const canProceed = checkCanNext(step.purpose);

                return (
                  <CustomStep
                    key={index}
                    stepIndex={index}
                    backText={""}
                    title={step.title}
                    subtitle={step.subtitle}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={
                      index !== steps.length - 1 ? handleNext : handleSubmit
                    }
                    canProceed={canProceed}
                    nextText={index === steps.length - 1 && "Create Agreement"}
                  >
                    {step.purpose === "MergeField" ? (
                      <Component
                        agreement={agreementData}
                        handleAgreementChange={agreementChange}
                        // @ts-ignore
                        mergeFieldId={step.mergeField._id}
                      />
                    ) : (
                      <Component
                        agreement={agreementData}
                        handleAgreementChange={agreementChange}
                      />
                    )}
                  </CustomStep>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
