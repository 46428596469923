import { Box, Container, Grid, Stepper, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CanveoCircularProgress, Header } from "../../components";
import CustomStep from "../../components/CustomStep";
import LabelsSelector from "../../components/createAgreement/LabelsSelector";
import useWidth from "../../hooks/useWidth";
import { globalStore } from "../../state/store";
import AreaLabelSelector from "./components/AreaLabelSelector";
import TemplateSelector from "./components/TemplateSelector";
import { NewPlaybookContext } from "./contexts/NewPlaybookContext";
import { createPlaybook } from "./utils/createPlaybook";

export default function NewPlaybook() {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const steps = [
    {
      title: "What area is your playbook for?",
      subtitle:
        "This helps us organize your playbooks and serve you the right one in the right context.",
      purpose: "AreaLabel",
      component: AreaLabelSelector,
    },
  ];

  // if (state.org.iAmALawFirm) {
  //   steps.push({
  //     purpose: "Parties",
  //     title: "Who are the parties to your agreement?",
  //     subtitle:
  //       "Select all organizations and/or persons that are party to your agreement. You can create new parties, or import them from your CRM.",
  //     // @ts-ignore
  //     component: PartySelector,
  //   });
  // }
  //
  steps.push(
    {
      purpose: "Template",
      title: "What is the basis for your playbook?",
      subtitle:
        "Start from an existing playbook in Microsoft Word and upload it.",
      // @ts-ignore
      component: TemplateSelector,
    },
    {
      purpose: "AgreementLabels",
      title: "Do you want to add any labels to your playbook?",
      subtitle: "General labels help organize your playbooks.",
      component: LabelsSelector,
    }
  );

  const navigate = useNavigate();
  const breakpoint = useWidth();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [playbook, setPlaybook] = useState(createPlaybook());

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  /**
   * @param {ReturnType<typeof createPlaybook>} playbook
   * @param {string} purpose
   * @returns {boolean}
   */
  function checkCanNext(playbook, purpose) {
    switch (purpose) {
      case "AreaLabel":
        return Boolean(playbook.areaLabelId);
      case "Template":
        return Boolean(playbook.template);
      default: {
        return true;
      }
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      setErrorMsg("");

      const template = playbook.template;

      const playbookToCreate = {
        playbook: {
          name: playbook.template.playbookName,
          areaLabel: playbook.areaLabelId,
          content: template.content,
          contentMetadata: template.contentMetadata,
          sfdt: template.sfdt,
          firstPageHeader: template.firstPageHeader,
          firstPageFooter: template.firstPageFooter,
          origin: template.origin,
          labels: playbook.labels,
          agreementType: template.agrTypeID,
          // @ts-ignore
          entities: playbook.parties.map((party) => party._id),
        },
      };

      await axios.post(state.settings.api + "playbook", playbookToCreate);
      navigate("/templates/playbook");
    } catch (error) {
      console.error(error);
      setErrorMsg(
        "Unable to create the ticket, try again or contact Canveo Support if the issue persists."
      );
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param {"template" | "exhibits" | "tempAreaLabel" | "tickets"} property
   * @param {* | null} value
   */
  const agreementChange = async (property, value) => {
    setPlaybook((prev) => ({
      ...prev,
      ...{
        [property]: value,
      },
    }));
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CanveoCircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Header page={"New"} />

      <Container sx={{ my: 8, mx: "auto" }} maxWidth={breakpoint}>
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="stretch"
          sx={{ flexWrap: "nowrap" }}
        >
          <Grid item>
            <Box sx={{ mt: 7, mb: 4 }}>
              <Typography align="center" variant="h4">
                Create New Playbook
              </Typography>
              {errorMsg && (
                <Typography
                  align="center"
                  // @ts-ignore
                  variant="subtitle"
                  color="error"
                >
                  {errorMsg}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item>
            <NewPlaybookContext.Provider value={{ playbook, setPlaybook }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map(
                  (
                    { title, subtitle, purpose, component: StepComponent },
                    index
                  ) => {
                    const canProceed = checkCanNext(playbook, purpose);
                    return (
                      <CustomStep
                        key={index}
                        stepIndex={index}
                        backText={""}
                        title={title}
                        subtitle={subtitle}
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={
                          index !== steps.length - 1 ? handleNext : handleSubmit
                        }
                        canProceed={canProceed}
                        nextText={
                          index === steps.length - 1 && "Create Playbook"
                        }
                      >
                        <StepComponent
                          active={activeStep === index}
                          agreement={playbook}
                          handleAgreementChange={agreementChange}
                        />
                      </CustomStep>
                    );
                  }
                )}
              </Stepper>
            </NewPlaybookContext.Provider>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
