import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useAgreementMetadata } from "../../../../hooks/useAgreementMetadata";
import useSummaryService from "../../../../hooks/useSummaryService";
import { AgreementMetadata } from "../../../AgreementMetadata";
import { AnalyzeCurrentAgreementVersionButton } from "../../../AnalyzeCurrentAgreementVersionButton";

/**
 * @typedef {object} KeyDatesTabProps
 * @property {string} agreementId
 * @property {string} jobStatus
 * @property {(props: { extractMetadata: boolean; complianceAnalysis: boolean; }) => Promise<void>} analyzeUsingAi
 */

/**
 * @param {KeyDatesTabProps} props
 */
export function KeyDatesTab({ agreementId, jobStatus, analyzeUsingAi }) {
  const metadataState = useAgreementMetadata({
    agreementId,
    close: () => {},
  });

  const { document, parameters } = useSummaryService(agreementId, false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box
      minHeight="600px"
      maxHeight="calc(100% - 400px)"
      sx={{ overflowY: "auto", overflowX: "hidden" }}
    >
      <Grid container direction="column">
        <Grid item ml={6}>
          <AnalyzeCurrentAgreementVersionButton
            jobStatus={jobStatus}
            analyzeUsingAi={analyzeUsingAi}
          />
        </Grid>

        <Grid item mx={-10}>
          <AgreementMetadata
            document={document}
            parameters={parameters}
            metadataState={metadataState}
          />
        </Grid>

        <Grid item ml={6} mt={2} mb={2}>
          <Grid container>
            <Grid item>
              <Button
                disableElevation
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    metadataState.submit();
                  } finally {
                    setIsLoading(false);
                  }
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
