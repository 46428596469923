import { faDownload, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import docx from "../assets/img/docx.png";
import pdf from "../assets/img/pdf.png";
import xlsx from "../assets/img/xlsx.png";
import useAgreementData from "../hooks/useAgreementData";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import getFileNameFromPathBuilder from "./PathBuilder/utils/getFilenameFromPathBuilder";
import { DialogManageParties } from "./dialogs/DialogManageParties";
import { wordEditAuthorizationOptions } from "./dialogs/SendComponents/ReadySignSection";

const StyledImage = styled("img", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "disabled",
  // @ts-ignore
})(({ disabled }) => ({
  margin: "0px 40px 0px 40px",
  height: "120px",
  width: "auto",
  "&:hover": {
    cursor: !disabled && "pointer",
  },
  opacity: disabled ? "0.5" : "1",
}));

/**
 * @typedef {object} ExportDocumentDialogProps
 * @property {{ origin: "drawer" | "versionCard" | "exhibitCard" } | false} openDialog
 * @property {boolean} isLoading
 * @property {() => void} closeDialog
 * @property {(event: string, payload: Record<string, string>) => void} onEvent
 * @property {any} agr
 * @property {string} versionType
 * @property {boolean} isTemplate
 * @property {boolean} isAgreementOwner
 * @property {boolean} isInEffect
 * @property {"read" | "full" } editMode
 */

/**
 * Dialog component used to export templates and agreements to PDF or Word files inside the editor.
 *
 * @param {ExportDocumentDialogProps} props
 * @returns {JSX.Element}
 */
export default function ExportDocumentDialog({
  openDialog,
  isLoading,
  closeDialog,
  onEvent,
  agr: agreement,
  versionType,
  isTemplate,
  isAgreementOwner,
  editMode,
  isInEffect,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [docType, setDocType] = useState("pdf");
  const [filename, setFilename] = useState("");
  const [exportType, setExportType] = useState(
    /** @type {"offline" | "archival"} */ (
      isInEffect || (openDialog && openDialog.origin !== "drawer")
        ? "archival"
        : "offline"
    )
  );
  const [recipientParty, setRecipientParty] = useState(
    /** @type {* | null} */ (null)
  );

  useEffect(
    () => {
      const party = state.parties.find(
        (/** @type {{ orgID: string; }} */ p) => p?.orgID !== state?.org?._id
      );
      if (!party) {
        setRecipientParty(null);
      } else {
        setRecipientParty(party);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.parties]
  );

  const [wordEditAuthorization, setWordEditAuthorization] = useState(
    isInEffect || (openDialog && openDialog.origin !== "drawer")
      ? "fullEditing"
      : "trackedChanges"
  );

  const [openDialogManageParties, setOpenDialogManageParties] = useState(false);

  const {
    roles,
    setRoles,
    handlePartyChange,
    setAgreementUpdate,
    updateAgreement,
  } = useAgreementData(agreement, openDialog);

  useEffect(
    () => {
      if (!openDialog) return;

      getFileNameFromPathBuilder(agreement, state, isTemplate).then(
        (filename) => setFilename(filename)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const fileNameIsEmpty = () => {
    return filename === "";
  };

  const exportDocument = () => {
    onEvent("Export", {
      type: docType,
      filename,
      agrId: agreement._id,
      recipientParty,
      wordEditAuthorization,
      exportType:
        exportType === "offline" && state.org._id === recipientParty?.orgID
          ? ""
          : exportType,
    });
  };

  const close = () => {
    setDocType("pdf");
    closeDialog();
  };

  const getExportDocumentInfoLabel = () => {
    // If we are exporting a Word document.
    if (docType === "docx") {
      if (isAgreementOwner) {
        return 'The exported Microsoft Word file will be protected for tracked changes, with the password "canveo".';
      }
      // If the user belongs to a counterparty.
      else {
        if (editMode === "read") {
          return "The exported Microsoft Word file will have read-only protection.";
        } else {
          return "The exported Microsoft Word file will be protected for tracked changes.";
        }
      }
    }

    // If we are exporting a PDF document.
    if (agreement.agrStatus === "InEffect") {
      return "This will export the signed version of this agreement.";
    } else {
      return "If this contract contains tracked changes or comments, those will be accepted and removed in the exported PDF file.";
    }
  };

  return (
    <Dialog
      open={openDialog && !!openDialog.origin}
      onClose={close}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>Export Agreement</DialogTitle>

      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogContent>
        {isLoading ? (
          <Box>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Tooltip
                  title={
                    agreement.agrStatus === "InEffect"
                      ? "Export Signed Agreement"
                      : "Export to PDF"
                  }
                  arrow
                  placement="bottom"
                  open={docType === "pdf"}
                >
                  <StyledImage
                    src={pdf}
                    alt="Export to PDF"
                    onClick={() => setDocType("pdf")}
                  />
                </Tooltip>
              </Grid>

              {(versionType === "docx" || versionType === "canveo") && (
                <Grid item>
                  {openDialogManageParties ? (
                    <>
                      <StyledImage
                        src={docx}
                        alt="Export to MS Word"
                        onClick={() => setDocType("docx")}
                      />
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title="Export to MS Word"
                        arrow
                        placement="bottom"
                        open={docType === "docx"}
                        hidden={openDialogManageParties}
                      >
                        <StyledImage
                          src={docx}
                          alt="Export to MS Word"
                          onClick={() => setDocType("docx")}
                        />
                      </Tooltip>
                    </>
                  )}
                </Grid>
              )}

              {versionType === "xlsx" && (
                <Grid item>
                  <Tooltip
                    title="Export to MS Excel"
                    arrow
                    placement="bottom"
                    open={docType === "xlsx"}
                  >
                    <StyledImage
                      src={xlsx}
                      alt="Export to MS Excel"
                      onClick={() => setDocType("xlsx")}
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>

            <Grid container px={4}>
              <Grid
                container
                direction="row"
                style={{ marginTop: "50px", textAlign: "left", width: "100%" }}
              >
                {docType !== "xlsx" && docType !== "docx" && (
                  <Alert
                    severity="info"
                    style={{ fontSize: "14px", width: "100%" }}
                  >
                    <b>{getExportDocumentInfoLabel()}</b>
                  </Alert>
                )}

                {docType === "docx" &&
                  openDialog &&
                  openDialog?.origin !== "drawer" && (
                    <Alert
                      severity="info"
                      style={{ fontSize: "14px", width: "100%" }}
                    >
                      <b>
                        This will export a Microsoft Word version for your
                        company records.
                      </b>
                    </Alert>
                  )}
              </Grid>

              {docType === "docx" &&
                openDialog &&
                openDialog.origin === "drawer" && (
                  <>
                    <Grid container mt={4}>
                      {/* <Typography fontWeight="bold">Export:</Typography> */}

                      <RadioGroup
                        defaultValue="offline"
                        value={exportType}
                        onChange={(_event, value) =>
                          setExportType(
                            /** @type {"offline" | "archival"} */ (value)
                          )
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="offline"
                          disabled={isInEffect}
                          label={
                            <Box mb={-6}>
                              <Box component="span" fontWeight="400">
                                This document will be reviewed offline
                              </Box>{" "}
                              <br />
                              <Box
                                component="span"
                                color="#989898"
                                fontSize={14}
                              >
                                Select this option if you want to continue the
                                review flow in Microsoft Word (locks the current
                                version in Canveo, and unlocks it upon
                                re-import)
                              </Box>
                            </Box>
                          }
                        />

                        <FormControlLabel
                          control={<Radio />}
                          value="archival"
                          label={
                            <Box pt={6} mt={2} mb={2}>
                              <Box component="span" fontWeight="400">
                                Copy for archival purposes only
                              </Box>{" "}
                              <br />
                              <Box
                                component="span"
                                color="#989898"
                                fontSize={14}
                              >
                                Select this option if you just want to export a
                                Word version of the agreement for your records.
                              </Box>
                            </Box>
                          }
                        />
                      </RadioGroup>
                    </Grid>

                    {exportType === "offline" && (
                      <>
                        <Grid container direction="column" mt={4} gap={2}>
                          {/* <Grid item>
                          Who will review this document?
                          <Box component="div" color="#989898" fontSize={14}>
                            Any new changes or comments in the document will be
                            assigned to this party upon re-import.
                          </Box>
                        </Grid> */}

                          <Grid item>
                            <Autocomplete
                              options={state.parties}
                              value={recipientParty}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Recipient Party"
                                  placeholder="Select party ..."
                                />
                              )}
                              getOptionLabel={(option) => option.legalName}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={option.logoURL}
                                    alt=""
                                  />
                                  {option.legalName} ({option.role})
                                </Box>
                              )}
                              onChange={(_event, value) =>
                                setRecipientParty(value)
                              }
                            />
                          </Grid>

                          <Grid item>
                            {/* Who will review this document? */}
                            <Box component="div" color="#989898" fontSize={14}>
                              Note: Any new changes or comments in the document
                              will be assigned to this party upon re-import.
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container mb={2} gap={2} justifyContent="right">
                          <Button
                            onClick={() => {
                              setOpenDialogManageParties(true);
                            }}
                          >
                            Manage Parties ...
                          </Button>
                          {openDialogManageParties && (
                            <DialogManageParties
                              open={openDialogManageParties}
                              close={() => {
                                setOpenDialogManageParties(false);
                              }}
                              roles={roles}
                              setRoles={setRoles}
                              owner={agreement.owner}
                              handlePartyChange={handlePartyChange}
                              setAgreementUpdate={setAgreementUpdate}
                              submit={async () => {
                                await updateAgreement();
                                setOpenDialogManageParties(false);
                              }}
                            />
                          )}
                        </Grid>

                        <Grid
                          container
                          mt={2}
                          mb={4}
                          direction="column"
                          gap={2}
                        >
                          {/* <Grid item>
                          What's the desired edit authorization?
                          <Box component="div" color="#989898" fontSize={14}>
                            The exported document will be protected for this
                            edit authorization, using the password
                            "orgShortName".
                          </Box>
                        </Grid> */}

                          <Grid item>
                            <Autocomplete
                              disableClearable
                              value={wordEditAuthorizationOptions.find(
                                (x) => x.value === wordEditAuthorization
                              )}
                              onChange={(_event, value) => {
                                setWordEditAuthorization(value?.value);
                              }}
                              options={wordEditAuthorizationOptions}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Word Edit Authorization"
                                  placeholder="Protect document for ..."
                                />
                              )}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <Grid container direction="column">
                                    <Grid item>
                                      <Typography
                                        variant="body1"
                                        fontWeight="bolder"
                                        color={theme.palette.grey[800]}
                                      >
                                        {option.label}
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Typography
                                        variant="subtitle2"
                                        color={theme.palette.grey[500]}
                                      >
                                        {option.description}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}

              <Grid container mt={2} mb={4} direction="column" gap={2}>
                {/* <Grid item>
                  What's the file name?
                  <Box component="div" color="#989898" fontSize={14}>
                    There's no need to include the file extension.
                  </Box>
                </Grid> */}

                <Grid item>
                  <TextField
                    error={fileNameIsEmpty()}
                    required
                    fullWidth
                    id="standard-helperText"
                    label="Exported Filename"
                    value={filename}
                    helperText={
                      fileNameIsEmpty() ? "Filename cannot be empty." : ""
                    }
                    onChange={(event) => {
                      setFilename(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={
            isLoading ||
            (() => {
              if (docType === "pdf") return false;

              if (exportType === "offline") {
                return fileNameIsEmpty() || !recipientParty;
              } else {
                return fileNameIsEmpty();
              }
            })()
          }
          disableElevation
          color="primary"
          variant="contained"
          onClick={exportDocument}
        >
          Export&nbsp;&nbsp;
          <FontAwesomeIcon icon={faDownload} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
