import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

/**
 * @typedef {object} DirectoryBuilderSelectorProps
 * @property {function} handleAgreementChange
 */

/**
 * @param {DirectoryBuilderSelectorProps} props
 */
export default function DirectoryBuilderSelector(props) {
  // @ts-ignore
  // const [state] = useContext(globalStore);

  // Commented for David demo.
  // const path = "root folder / " + state.org.folderStructureBuilder
  //   .flatMap(
  //     (/** @type {{ selectedOption: { label: string; }; }} */ x) =>
  //       x.selectedOption.label
  //   )
  //   .reduce(
  //     (/** @type {string} */ p, /** @type {string} */ n) =>
  //       n === "/               [new subfolder]" ? p + " / " : p + n,
  //     ""
  //   );
  const path = "Sharepoint / Client Contracts / Counterparty 1";
  // const path = "Sharepoint / Supplier Contracts / Counterparty 1";
  // const path = "Google Drive / Customer Contracts / Counterparty 1";

  return (
    <Grid container mt={2} alignItems="center">
      <Grid item>
        <Typography
          fontWeight="bold"
          color={theme.palette.primary.main}
          sx={{
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {path}
        </Typography>
      </Grid>
      <Grid item ml={4}>
        <Button variant="outlined">Change ...</Button>
      </Grid>
    </Grid>
  );
}
