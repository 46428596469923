import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { MuiColorInput, matchIsValidColor } from "mui-color-input";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { companySizeRanges, countries, industries } from "../assets/static";
import {
  CanveoCircularProgress,
  DialogEntity,
  FabStandard,
  Header,
  UploadImg,
} from "../components";
import PathBuilder from "../components/PathBuilder";
import CoreDrawer from "../components/drawer/CoreDrawer";
import { getAdminDrawerItems } from "../components/drawer/getDrawerItems";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { countryToFlag, getAddressForEntity } from "../utils";
import { getCanveoTier } from "../utils/getCanveoTier";
import Integrations from "./subviews/Integrations";
import Roles from "./subviews/Roles";
import Signers from "./subviews/Signers";
import Users from "./subviews/Users";

/**
 * @returns {React.JSX.Element}
 */
export default function Admin() {
  const navigate = useNavigate();
  const { section } = useParams();
  const pageSize = 10;

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const canveoTier = getCanveoTier(state?.user?.email);
  const [loading, setLoading] = React.useState(false);
  const [curPage, setCurPage] = React.useState(1);
  //const [users, setUsers] = React.useState([])
  const [dialogEntityOpen, setDialogEntityOpen] = React.useState(
    /** @type {* | null} */ (null)
  );
  // {ent, user}
  const [whiteLabelErrMsg, setWhiteLabelErrMsg] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [whiteLabelColor, setWhiteLabelColor] = React.useState(
    state.org.orgSettings !== undefined &&
      state.org.orgSettings.whiteLabel !== undefined &&
      state.org.orgSettings.whiteLabel !== null &&
      state.org.orgSettings.whiteLabel.color !== undefined
      ? state.org.orgSettings.whiteLabel.color
      : theme.palette.secondary.main
  );
  const [whiteLabelContrast, setWhiteLabelContrast] = React.useState(
    state.org.orgSettings !== undefined &&
      state.org.orgSettings.whiteLabel !== undefined &&
      state.org.orgSettings.whiteLabel !== null &&
      state.org.orgSettings.whiteLabel.contrastColor !== undefined
      ? state.org.orgSettings.whiteLabel.contrastColor
      : theme.palette.secondary.contrastText
  );
  const [drawerItems, setDrawerItems] = useState([]);

  useEffect(() => {
    const isSuperAdmin = [
      "dbuser@getcanveo.com",
      "owner1@getcanveo.com",
    ].includes(state.user.email); // TODO: we need to get rid of this

    const drawerItems = getAdminDrawerItems(
      // @ts-ignore
      section,
      (target) => navigate(target),
      state?.user?.email,
      isSuperAdmin
    );
    // @ts-ignore
    setDrawerItems(drawerItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  /*
  const dbusers = [
    { _id: "usr1", firstName: "David", lastName: "Buser", displayName: "David Buser", title: 'Co-Founder', email: "dbuser@getcanveo.com", phone: "+447514926760", photoURL: "https://storage.googleapis.com/ctimages/rOw5mC1yo8d5shlhzqu0", role: "Admin", readOnly: false, active: true },
    { _id: "usr2", firstName: "Remy", lastName: "Sharp", displayName: "Remy Sharp", title: 'Sales Manager', email: "remy@getcanveo.com", phone: "", photoURL: "https://mui.com/static/images/avatar/1.jpg", role: "Business", readOnly: false, active: true },
    { _id: "usr3", firstName: "Travis", lastName: "Howard", displayName: "Travis Howard", title: 'Legal', email: "travis@getcanveo.com", phone: "", photoURL: "", role: "Legal", readOnly: false, active: true },
    { _id: "usr4", firstName: "Agnes", lastName: "Walker", displayName: "Agnes Walker", title: 'Sales associate', email: "agnes@getcanveo.com", phone: "+1234567789", photoURL: "https://mui.com/static/images/avatar/4.jpg", role: "Business", readOnly: true, active: true },
  ]*/
  /*
  useEffect(() => {
    if(section === 'users') {
        //todo: pull from DB when loading "users" section, can pull only active
        //setUsers(state.users.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1))

    } else
    if(section === 'signers') {
      let signerIdsToPull = []
      state.subs
      .filter((s) => s.active)
      .forEach((s) => {
        if(s.defaultSigners !== undefined && s.defaultSigners !== null && s.defaultSigners.length > 0) {
          s.defaultSigners.forEach((ds) => {
            if(!signerIdsToPull.some((sitp) => sitp === ds)) {
              signerIdsToPull.push(ds);
            }
          })
        }
      })
      // Here comes the pulling of users: signerIdsToPull
      // todo: pull users that are default signers
      setUsers(state.users.filter((dbu) => signerIdsToPull.some((sitp) => sitp === dbu._id)))

    }

  }, [section])*/

  const closeEntityDialog = (/** @type {string} */ action) => {
    if (["snackEntCreated", "snackEntUpdated"].includes(action)) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: ["snackEntCreated"].includes(action)
            ? "The entity was created"
            : ["snackEntUpdated"].includes(action)
            ? "The entity was updated"
            : "",
          severity: "success",
        },
      });
    }
    setDialogEntityOpen(null);
  };

  const handleSettingsChange = (
    /** @type {string} */ setting,
    /** @type {string} */ newVal
  ) => {
    // todo: update ORG in state
    let newOrg = state.org;
    let newOrgSettings =
      newOrg.orgSettings !== undefined && newOrg.orgSettings !== null
        ? newOrg.orgSettings
        : {};

    if (["whiteLabel"].includes(setting)) {
      // Default the whiteLabel setting if it didn't fully exist yet
      let newWL =
        newOrgSettings.whiteLabel !== undefined &&
        newOrgSettings.whiteLabel !== null &&
        newOrgSettings.whiteLabel.orgName !== undefined &&
        newOrgSettings.whiteLabel.logoURL !== undefined &&
        newOrgSettings.whiteLabel.color !== undefined &&
        newOrgSettings.whiteLabel.contrastColor !== undefined
          ? newOrgSettings.whiteLabel
          : {
              orgName: state.org.shortName,
              logoURL: state.org.logoURL,
              color: whiteLabelColor,
              contrastColor: whiteLabelContrast,
            };

      newWL.level = newVal;
      newOrgSettings.whiteLabel = newWL;
    } else {
      newOrgSettings[setting] = newVal;
    }
    newOrg.orgSettings = newOrgSettings;
    console.log("newOrg", newOrg);
    pushOrg(newOrg);
  };

  const handleWhiteLabelColorChange = (
    /** @type {string} */ type,
    /** @type {string} */ newColor
  ) => {
    let newOrg = state.org;

    let newWhiteLabel = newOrg.orgSettings.whiteLabel;

    if (!newColor.startsWith("#")) {
      newColor = "#" + newColor;
    }

    if (
      newWhiteLabel === undefined ||
      newWhiteLabel === null ||
      !["color", "contrast"].includes(type) ||
      !matchIsValidColor(newColor)
    ) {
      setWhiteLabelErrMsg("Please provide a valid HEX color code");
      return;
    }
    setWhiteLabelErrMsg(null);

    if (["color"].includes(type)) {
      newWhiteLabel.color = newColor;
      setWhiteLabelColor(newColor);
    } else if (["contrast"].includes(type)) {
      newWhiteLabel.contrastColor = newColor;
      setWhiteLabelContrast(newColor);
    }

    newOrg.whiteLabel = newWhiteLabel;
    pushOrg(newOrg);
    //dispatch({ type: "UPDATE_ORG", payload: newOrg })
    //console.log("TODO UPDATE DB WHITE NEW WHITELABEL")
  };

  const handleSubmitImage = (/** @type {any} */ child) => {
    if (child) {
      // A new logoURL was submitted
      setLoading(true);
      axios
        .put(state.settings.api + "org/orglogo/" + state.org._id, {
          newLogo: child,
        })
        .then((res) => {
          if (res.data.success) {
            let newOrg = state.org;
            newOrg.logoURL = child;
            dispatch({ type: "UPDATE_ORG", payload: newOrg });
            state.subs.forEach((/** @type {{ logoURL: string; }} */ s) => {
              s.logoURL = child;
              dispatch({ type: "UPDATE_CPENT", payload: s });
            });
            setLoading(false);
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message: "Company settings were updated",
                severity: "success",
              },
            });
          } else {
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message:
                  "An error occurred while updating the company settings",
                severity: "error",
              },
            });
          }
        })
        .catch(() => {
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "An error occurred while updating the company settings",
              severity: "error",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCompanySizeChange = (/** @type {any} */ newValue) => {
    if (!newValue) return;

    const newOrg = { ...state.org, size: newValue };

    pushOrg(newOrg);
  };

  const handleIndustryChange = (/** @type {any} */ newValue) => {
    if (!newValue) return;

    const newOrg = { ...state.org, industries: [newValue] };

    pushOrg(newOrg);
  };

  const handleIAmALawFirmChange = (/** @type {boolean} */ newValue) => {
    const newOrg = { ...state.org, iAmALawFirm: newValue };
    pushOrg(newOrg);
  };

  const pushOrg = (/** @type {{ _id: string; }} */ newOrg) => {
    axios
      .put(state.settings.api + "org/" + newOrg._id, { org: newOrg })
      .then((resOrg) => {
        if (resOrg.data.success) {
          dispatch({ type: "UPDATE_ORG", payload: resOrg.data.data });
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "Company settings were updated",
              severity: "success",
            },
          });
        } else {
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "An error occurred while updating the company settings",
              severity: "error",
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while updating the company settings",
            severity: "error",
          },
        });
      });
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex", //justifyContent: 'center',
          width: "100%",
          margin: "0px",
          padding: "0px" /*, backgroundColor: '#ffeeff'*/,
        }}
      >
        <Header page={"Admin"} />
        <CoreDrawer drawerItems={drawerItems} />

        <Box sx={{ my: 8, mx: 3, width: "100%" }}>
          {loading ? (
            <Box>
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{ mt: 15 }}
              >
                <CanveoCircularProgress />
              </Grid>
            </Box>
          ) : // Manage Entities
          // @ts-ignore
          ["entities"].includes(section) ? (
            <Box>
              <FabStandard
                click={() => setDialogEntityOpen({ type: "newsub" })}
                text="New"
                icon={faCirclePlus}
                sx={{
                  left: "100px",
                  top: "80px",
                  right: "unset",
                }}
              />
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{ my: 5 }}
              >
                <Grid item>
                  <Typography variant="h4">Manage Companies</Typography>
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <Typography variant="body1" display="block" align="center">
                    Your company (or companies) - the legal entities that enter
                    into contracts with your counterparties
                  </Typography>
                </Grid>
                <Grid item sx={{ mt: 5 }}>
                  <List sx={{ width: "400px" }}>
                    {state.subs
                      .filter((/** @type {{ active: any; }} */ s) => s.active)
                      .sort(
                        (
                          /** @type {{ legalName: number; }} */ a,
                          /** @type {{ legalName: number; }} */ b
                        ) => (a.legalName > b.legalName ? 1 : -1)
                      )
                      .slice((curPage - 1) * pageSize, curPage * pageSize)
                      .map(
                        (
                          /** @type {{ _id: React.Key | null | undefined; logoURL: string | undefined; legalName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }} */ s
                        ) => (
                          <ListItemButton
                            key={s._id}
                            onClick={() => {
                              setDialogEntityOpen({ type: "updsub", ent: s });
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={s.logoURL}
                                style={{
                                  backgroundColor:
                                    theme.palette.primary.contrastText,
                                }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={s.legalName}
                              secondary={
                                countries.filter(
                                  (c) =>
                                    c.code ===
                                    getAddressForEntity(s, "billto").country
                                )[0] !== undefined ? (
                                  <>
                                    {
                                      countries.filter(
                                        (c) =>
                                          c.code ===
                                          getAddressForEntity(s, "billto")
                                            .country
                                      )[0].label
                                    }
                                    &nbsp;&nbsp;
                                    {countryToFlag(
                                      getAddressForEntity(s, "billto").country,
                                      false
                                    )}
                                  </>
                                ) : (
                                  "Country not defined"
                                )
                              }
                            />
                          </ListItemButton>
                        )
                      )}
                  </List>
                </Grid>
                <Grid item sx={{ mt: 5 }}>
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(
                        state.subs.filter(
                          (/** @type {{ active: any; }} */ s) => s.active
                        ).length / pageSize
                      )}
                      defaultPage={1}
                      page={curPage}
                      onChange={(e, newVal) => setCurPage(newVal)}
                      siblingCount={0}
                      boundaryCount={2}
                      renderItem={(item) => <PaginationItem {...item} />}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ) : // Manage Users
          // @ts-ignore
          ["users"].includes(section) ? (
            <Users />
          ) : //Manage Roles
          // @ts-ignore
          ["roles"].includes(section) ? (
            <Roles />
          ) : // Manage Default Signers
          // @ts-ignore
          ["signers"].includes(section) ? (
            <Signers />
          ) : // Manage Branding
          // @ts-ignore
          ["branding"].includes(section) ? (
            <Box>
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{ my: 5 }}
              >
                <Grid item>
                  <Typography variant="h4" display="block" align="center">
                    Branding
                  </Typography>
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <Typography variant="body1" display="block" align="center">
                    Add company branding to the emails you send to your
                    counterparties
                  </Typography>
                </Grid>
                <Grid item sx={{ mt: 2, mb: 2 }}>
                  <UploadImg
                    type="logo"
                    handleSubmitImage={handleSubmitImage}
                    initialImg={state.org.logoURL}
                  />
                </Grid>
                <Grid item>
                  <Box sx={{ mb: 3 }}>
                    <FormControl>
                      <FormLabel>PDF Branding</FormLabel>
                      <RadioGroup
                        row
                        name="headerInPdf"
                        value={
                          !!(
                            state.org.orgSettings !== undefined &&
                            state.org.orgSettings !== null &&
                            state.org.orgSettings.headerInPdf
                          )
                        }
                        onChange={(e) =>
                          handleSettingsChange("headerInPdf", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="None"
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={"Include our logo and address in header"}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <FormControl>
                      <FormLabel>Word Branding</FormLabel>
                      <RadioGroup
                        row
                        name="headerInWord"
                        value={
                          !!(
                            state.org.orgSettings !== undefined &&
                            state.org.orgSettings !== null &&
                            state.org.orgSettings.headerInWord
                          )
                        }
                        onChange={(e) =>
                          handleSettingsChange("headerInWord", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="None"
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={"Include our logo and address in header"}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 5 }}>
                    <FormControl>
                      <FormLabel>Email Branding</FormLabel>
                      <RadioGroup
                        name="whiteLabel"
                        row
                        value={
                          state.org.orgSettings !== undefined &&
                          state.org.orgSettings !== null &&
                          state.org.orgSettings.whiteLabel !== undefined &&
                          state.org.orgSettings.whiteLabel !== null &&
                          ["email"].includes(
                            state.org.orgSettings.whiteLabel.level
                          )
                            ? state.org.orgSettings.whiteLabel.level
                            : "none"
                        }
                        onChange={(e) =>
                          handleSettingsChange("whiteLabel", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="none"
                          control={<Radio />}
                          label="None"
                        />
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label="Include your logo and colors in email notifications"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Collapse
                  in={
                    state.org.orgSettings !== undefined &&
                    state.org.orgSettings.whiteLabel !== undefined &&
                    state.org.orgSettings.whiteLabel !== null &&
                    ["email"].includes(state.org.orgSettings.whiteLabel.level)
                  }
                >
                  <Grid item container direction="column" alignItems={"center"}>
                    {whiteLabelErrMsg !== null && (
                      <Grid item sx={{ mt: 5 }}>
                        <Typography align="center" color="error">
                          {whiteLabelErrMsg}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <MuiColorInput
                        id="outlined-name"
                        label="Accent Color"
                        placeholder="HEX Code ..."
                        format="hex"
                        isAlphaHidden
                        value={whiteLabelColor}
                        onChange={(color) => setWhiteLabelColor(color)}
                        onBlur={(e) =>
                          handleWhiteLabelColorChange("color", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item sx={{ mt: 2 }}>
                      <MuiColorInput
                        id="outlined-name"
                        label="Contrast Color"
                        placeholder="HEX Code ..."
                        format="hex"
                        isAlphaHidden
                        value={whiteLabelContrast}
                        onChange={(color) => setWhiteLabelContrast(color)}
                        onBlur={(e) =>
                          handleWhiteLabelColorChange(
                            "contrast",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item sx={{ mt: 5 }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          backgroundColor: whiteLabelColor,
                          color: whiteLabelContrast,
                          width: "160px",
                          padding: "6px 15px 6px 15px",
                          borderRadius: "20px",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                        Sample Button
                      </div>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
          ) : // Manage Integrations
          // @ts-ignore
          ["integrations"].includes(section) ? (
            <Integrations pushOrg={pushOrg} />
          ) : // @ts-ignore
          ["profile"].includes(section) ? (
            <Box>
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{ my: 5 }}
              >
                <Grid item>
                  <Typography variant="h4">Organization Settings</Typography>
                </Grid>

                <Grid item sx={{ mt: 2 }}>
                  <Typography variant="body1" display="block" align="center">
                    Settings and information relating to your organization's
                    account
                  </Typography>
                </Grid>

                <Grid item>
                  <FormGroup>
                    <Box sx={{ my: 5 }} style={{ width: "600px" }}>
                      <Card
                        sx={{
                          pt: 4,
                          pb: 4,
                          px: 4,
                          mx: "auto",
                          borderRadius: "15px",
                          backgroundColor: theme.palette.grey[100],
                          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <Box mt={1} mb={1}>
                          <Autocomplete
                            sx={{ width: "100%" }}
                            options={companySizeRanges}
                            value={state.org.size}
                            autoHighlight
                            onChange={(e, value) =>
                              handleCompanySizeChange(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Company Size"}
                                variant="outlined"
                                inputProps={{
                                  // to avoid autosuggest leakage, include this in the text field and remove "id" or make it non-obvious
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Box>

                        <Box mt={1} mb={1}>
                          <Autocomplete
                            sx={{ width: "100%" }}
                            options={industries}
                            value={
                              state.org.industries?.length
                                ? state.org.industries[0]
                                : null
                            }
                            autoHighlight
                            onChange={(e, value) => handleIndustryChange(value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Industry"}
                                variant="outlined"
                                inputProps={{
                                  // to avoid autosuggest leakage, include this in the text field and remove "id" or make it non-obvious
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Card>

                      {canveoTier === "experimental" && (
                        <Card
                          sx={{
                            mt: 4,
                            pt: 4,
                            pb: 4,
                            px: 4,
                            mx: "auto",
                            borderRadius: "15px",
                            backgroundColor: theme.palette.grey[100],
                            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={state.org.iAmALawFirm}
                                  onChange={() => {
                                    handleIAmALawFirmChange(
                                      !state.org.iAmALawFirm
                                    );
                                  }}
                                />
                              }
                              label="We are a law firm"
                            />
                            <Typography variant="body2" color={"gray"}>
                              This allows you to tag a contractual party as your
                              client, helping you and others to keep track of
                              client relationships.
                            </Typography>
                          </FormGroup>
                        </Card>
                      )}

                      <Card
                        sx={{
                          mt: 4,
                          pt: 4,
                          pb: 4,
                          px: 4,
                          mx: "auto",
                          borderRadius: "15px",
                          backgroundColor: theme.palette.grey[100],
                          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <Box mb={1}>
                          <Grid container direction="row">
                            <b>
                              Default File Name Builder (for any file export):
                            </b>
                          </Grid>

                          <PathBuilder
                            key={"defaultFileNameBuilder"}
                            builder={state.org.defaultFileNameBuilder}
                            isLawFirm={state.org.iAmALawFirm}
                            onChange={(value) => {
                              const organizationUpdate = {
                                ...state.org,
                                defaultFileNameBuilder: value,
                              };
                              pushOrg(organizationUpdate);
                            }}
                            isFile={true}
                          />
                        </Box>

                        <Box mt={6} mb={1}>
                          <Grid container direction="row">
                            <b>
                              Folder Structure Builder (for connected file
                              storage systems) (Beta):
                            </b>
                          </Grid>

                          <PathBuilder
                            key={"folderStructureBuilder"}
                            builder={state.org.folderStructureBuilder}
                            isLawFirm={state.org.iAmALawFirm}
                            onChange={(value) => {
                              const organizationUpdate = {
                                ...state.org,
                                folderStructureBuilder: value,
                              };
                              pushOrg(organizationUpdate);
                            }}
                            isFile={false}
                          />
                        </Box>
                      </Card>
                    </Box>
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>

      <DialogEntity
        open={Boolean(dialogEntityOpen)}
        closeDialog={closeEntityDialog}
        type={
          dialogEntityOpen !== null && dialogEntityOpen.type !== undefined
            ? dialogEntityOpen.type
            : ""
        }
        ent={
          dialogEntityOpen !== null && dialogEntityOpen.ent !== undefined
            ? dialogEntityOpen.ent
            : ""
        }
      />
    </div>
  );
}
