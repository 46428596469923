import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createDOMRange, createRectsFromDOMRange } from "@lexical/selection";
import {
  $dfs,
  $getNearestNodeOfType,
  mergeRegister,
  registerNestedElementResolver,
} from "@lexical/utils";
import axios from "axios";
import {
  $getNodeByKey,
  $getPreviousSelection,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { globalStore } from "../../../state/store";
import { greatPatternFinder } from "../../../utils";
import CommentMenu from "../../CommentMenu";
import MergeFieldMenu from "../../MergeFieldMenu";
import {
  ADD_WORKFLOW_COMMAND,
  INSERT_INLINE_COMMAND,
  REMOVE_MARK,
} from "../commands";
import { ClauseNode } from "../nodes";
import {
  $createMarkNode,
  $getMarkIDs,
  $isMarkNode,
  $unwrapMarkNode,
  $wrapSelectionInMarkNode,
  MarkNode,
} from "../nodes/MarkNode";
import { $createRedlineNode } from "../nodes/RedlineNode";
import { getClauseNode } from "../utils";
import useClauseOptions from "./ClauseOptionsPlugin/useClauseOptions";
import {
  PREVENT_EVENT_PROPAGATION,
  PROPAGATE_EVENT,
} from "./TrackChangesPlugin/utils";

// const filter = createFilterOptions();

// /**
//  * @param {*} _
//  */
// function CommentInputBox({
//   type,
//   partyID,
//   docID,
//   isTemplating,
//   editor,
//   user,
//   cancelAddWorkflow,
//   createWorkflowAndAttachToDOM,
//   attachWorkflowToDOM,
//   predictedParam,
// }) {
//   // @ts-ignore
//   const [state, dispatch] = useContext(globalStore);

//   const [userRole] = useState(state.user.role?.name);
//   const [content, setContent] = useState("");
//   const [canSubmit, setCanSubmit] = useState(false);
//   const [subscribers, setSubscribers] = useState([]);
//   const boxRef = useRef(null);
//   const selectionState = useMemo(
//     () => ({
//       container: document.createElement("div"),
//       elements: [],
//     }),
//     []
//   );

//   const updateLocation = useCallback(() => {
//     editor.getEditorState().read(() => {
//       const selection = $getSelection();

//       if ($isRangeSelection(selection)) {
//         const anchor = selection.anchor;
//         const focus = selection.focus;
//         const range = createDOMRange(
//           editor,
//           anchor.getNode(),
//           anchor.offset,
//           focus.getNode(),
//           focus.offset
//         );
//         const boxElem = boxRef.current;
//         if (range !== null && boxElem !== null) {
//           const { left, bottom, width } = range.getBoundingClientRect();

//           const selectionRects = createRectsFromDOMRange(editor, range);

//           let correctedLeft =
//             selectionRects.length === 1 ? left + width / 2 - 125 : left - 125;
//           if (correctedLeft < 10) {
//             correctedLeft = 10;
//           }
//           boxElem.style.left = `${correctedLeft}px`;
//           boxElem.style.top = `${bottom + 20 + window.scrollY}px`;
//           boxElem.style.position = "absolute";
//           const selectionRectsLength = selectionRects.length;
//           const { elements, container } = selectionState;
//           const elementsLength = elements.length;

//           for (let i = 0; i < selectionRectsLength; i++) {
//             const selectionRect = selectionRects[i];
//             let elem = elements[i];
//             if (elem === undefined) {
//               elem = document.createElement("span");
//               elements[i] = elem;
//               container.appendChild(elem);
//             }
//             const color = ["approval"].includes(type)
//               ? "15,160,90"
//               : ["param"].includes(type)
//               ? "75,140,245"
//               : "255, 212, 0";
//             const style = `position:absolute;top:${
//               selectionRect.top + window.scrollY
//             }px;left:${selectionRect.left}px;height:${
//               selectionRect.height
//             }px;width:${
//               selectionRect.width
//             }px;background-color:rgba(${color}, 0.3);pointer-events:none;z-index:5;`;
//             elem.style.cssText = style;
//           }
//           for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
//             const elem = elements[i];
//             container.removeChild(elem);
//             elements.pop();
//           }
//         }
//       }
//     });
//   }, [editor, selectionState]);

//   useLayoutEffect(() => {
//     updateLocation();
//     const container = selectionState.container;
//     const body = document.body;
//     if (body !== null) {
//       body.appendChild(container);
//       return () => {
//         body.removeChild(container);
//       };
//     }
//   }, [selectionState.container, updateLocation]);

//   useEffect(() => {
//     window.addEventListener("resize", updateLocation);

//     return () => {
//       window.removeEventListener("resize", updateLocation);
//     };
//   }, [updateLocation]);

//   const onEscape = (event) => {
//     event.preventDefault();
//     cancelAddWorkflow();
//     return true;
//   };

//   const submitParam = () => {
//     let isParamFromLibrary =
//       !["new"].includes(param.libid) &&
//       state.params.lib.some((li) => li._id === param.libid);
//     let newParam = param;
//     let newLibParam =
//       !isParamFromLibrary && newParam.isLibItem ? newParam : null;

//     // If Applicable: CREATE Parameter for library
//     if (newLibParam) {
//       // This param needs to be duplicated into a Library Param
//       newLibParam.docID = null;
//       newLibParam.lid = null;
//       delete newLibParam.libid;

//       axios
//         .post(state.settings.api + "param", { param: newLibParam }) // POST the library Param
//         .then((resLibParam) => {
//           if (resLibParam.data.success) {
//             dispatch({
//               type: "ADD_PARAM",
//               payload: { type: "lib", item: resLibParam.data.data },
//             });
//           } else {
//             console.log("unable to create Library Param");
//           }
//         })
//         .catch((err) => {
//           console.log("err creating Library Param", err);
//         });
//     }

//     // CREATE Parameter and attach to DOM
//     newParam.lid = "pa" + partyID.substring(5) + "_" + generateRandomString(5);
//     newParam.isLibItem = false;
//     delete newParam.libid;

//     axios
//       .post(state.settings.api + "param", { param: newParam }) // POST the regular Param
//       .then((resParam) => {
//         if (resParam.data.success) {
//           dispatch({
//             type: "ADD_PARAM",
//             payload: { type: "doc", item: resParam.data.data },
//           });
//           attachWorkflowToDOM(newParam.lid);
//         } else {
//           console.log("unable to create Param");
//         }
//       })
//       .catch((err) => {
//         console.log("err creating Param", err);
//       });
//   };

//   const submitComment = () => {
//     let clauseHTML = editor.getEditorState().read(() => {
//       const anchor = $getSelection() ? $getSelection().anchor : null;
//       const clauseNode = anchor ? getClauseNode(anchor.getNode()) : null;
//       const html = clauseNode ? getClauseHTML(clauseNode) : null;
//       return html ? html : null;
//     });

//     let quote = editor.getEditorState().read(() => {
//       const selection = $getSelection();
//       return selection !== null ? selection.getTextContent() : "";
//     });

//     if (quote.length > 100) {
//       quote = quote.slice(0, 99) + "…";
//     }

//     let wfType = ["approval"].includes(type)
//       ? "approval"
//       : isInternal
//       ? "ithread"
//       : "pthread";

//     let prefix = ["approval"].includes(wfType)
//       ? "ap" + partyID.substring(5) + "_"
//       : ["ithread"].includes(wfType)
//       ? "cp" + partyID.substring(5) + "_"
//       : "cp_";

//     let allSubs = subscribers;
//     let emailRecipients = [];

//     let doc = isTemplating
//       ? state.templates.filter((t) => t._id === docID)[0]
//       : state.agrs.filter((a) => a._id === docID)[0];
//     let assignee = null;

//     if (["ithread", "pthread"].includes(wfType)) {
//       subscribers
//         .filter((sub) => state.users.find((u) => u._id === sub._id))
//         .forEach((s) => {
//           const { password, role } = state.users.filter((u) => u._id === s)[0];
//           if (doc) {
//             emailRecipients.push({
//               doc,
//               // @ts-ignore
//               recipient: { ...s, role, password },
//               content,
//               isInternal,
//               isTemplating,
//               clauseHTML,
//             });
//           }
//         });
//     } else if (["approval"].includes(wfType) && !!approverSelected) {
//       let recipient = approverSelected;
//       emailRecipients.push({
//         doc,
//         recipient,
//         content,
//         isInternal,
//         isTemplating,
//         clauseHTML,
//       });
//       assignee = [
//         {
//           uid: approverSelected._id,
//           displayName: approverSelected.displayName,
//           status: "pending",
//         },
//       ];
//     }

//     if (!allSubs.some((s) => s._id === user._id)) {
//       allSubs.unshift(user._id);
//     } // Insert creator as subscriber
//     createWorkflowAndAttachToDOM(
//       createWorkflow(
//         wfType,
//         prefix,
//         quote,
//         content,
//         user,
//         assignee,
//         allSubs,
//         docID,
//         partyID
//       ),
//       emailRecipients,
//       true
//     );
//   };

//   const onChange = useOnChange(setContent, setCanSubmit, setSubscribers);
//   const [isInternal, setIsInternal] = React.useState(false);
//   const [step, setStep] = React.useState(0);
//   const [approverSelected, setApproverSelected] = React.useState(null);
//   const [param, setParam] = React.useState({
//     orgID: state.org._id,
//     libid: null,
//     name: "",
//     ref: "",
//     type: null,
//     val1: "",
//     val2: "",
//     valCalc: "",
//     note: "",
//     createWizard: false,
//     createWizardQuestion: "",
//     isLibItem: false,
//     docID: docID,
//   });

//   useEffect(() => {
//     if (Boolean(predictedParam) && Boolean(predictedParam.type)) {
//       setParam({
//         ...param,
//         type: predictedParam.type,
//         val1: predictedParam.val1,
//         val2: predictedParam.val2,
//       });
//     }
//   }, [predictedParam]);

//   const handleParamValueChange = (sfid, valnumber, newval) => {
//     if (valnumber === "val1") {
//       setParam({ ...param, val1: newval });
//     } else if (valnumber === "val2") {
//       setParam({ ...param, val2: newval });
//     }
//   };

//   return (
//     <div className="CommentPlugin_CommentInputBox" ref={boxRef}>
//       {
//         // Param - Step 0
//         /*
//       stp 1: Name + note<br/>
//       stp 2: Type + value<br/>
//       stp 3: Config (wiz1/2, reuse, replic)
//       */
//         ["param"].includes(type) && step === 0 ? (
//           <div style={{ padding: "20px 20px 0px 20px" }}>
//             <Box sx={{ mt: 1, mb: 1 }}>
//               <Autocomplete
//                 //value={param.value}
//                 onChange={(event, newValue) => {
//                   if (typeof newValue === "string") {
//                     setParam({ ...param, libid: "new", name: newValue });
//                   } else if (newValue && newValue.inputValue) {
//                     // Create a new value from the user input
//                     setParam({
//                       ...param,
//                       libid: "new",
//                       name: newValue.inputValue,
//                     });
//                   } else {
//                     setParam({
//                       ...param,

//                       createWizard: newValue.createWizard,
//                       createWizardQuestion: newValue.createWizardQuestion,
//                       name: newValue.name,
//                       note: newValue.note,
//                       ref: newValue.ref,
//                       type:
//                         newValue.orgID === "CANVEO"
//                           ? newValue.type
//                           : param.type,
//                       val1:
//                         newValue.orgID === "CANVEO"
//                           ? newValue.val1
//                           : param.val1,
//                       val2:
//                         newValue.orgID === "CANVEO"
//                           ? newValue.val2
//                           : param.val2,
//                       libid: newValue.orgID === "CANVEO" ? "canveo" : "library",
//                     });
//                   }
//                 }}
//                 filterOptions={(options, params) => {
//                   const filtered = filter(options, params);
//                   const { inputValue } = params;
//                   // Suggest the creation of a new value
//                   //const isExisting = options.some((option) => inputValue === option.name);
//                   if (inputValue !== "" /* && !isExisting*/) {
//                     filtered.push({
//                       inputValue,
//                       name: `Create "${inputValue}"`,
//                     });
//                   }
//                   return filtered;
//                 }}
//                 selectOnFocus
//                 clearOnBlur
//                 handleHomeEndKeys
//                 options={state.params.lib.sort((a, b) =>
//                   a.name > b.name ? 1 : -1
//                 )}
//                 getOptionLabel={(option) => {
//                   // Value selected with enter, right from the input
//                   if (typeof option === "string") {
//                     return option;
//                   }
//                   // Add "xxx" option created dynamically
//                   if (option.inputValue) {
//                     return option.inputValue;
//                   }
//                   // Regular option
//                   return option.name;
//                 }}
//                 renderOption={(props, option) => (
//                   <li {...props} style={{ fontSize: "14px" }}>
//                     {trunc(option.name, 24)}
//                     {option.orgID === "CANVEO" ? (
//                       <img
//                         src={logo}
//                         alt="Canveo Managed"
//                         style={{
//                           width: "12px",
//                           height: "12px",
//                           marginLeft: "10px",
//                         }}
//                       />
//                     ) : (
//                       ""
//                     )}
//                   </li>
//                 )}
//                 sx={{ width: "100%" }}
//                 freeSolo
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label="Property name"
//                     placeholder={"Select or create ..."}
//                   />
//                 )}
//               />
//             </Box>

//             {
//               /*param.libid !== null && */ Boolean(param.libid) &&
//               !["canveo"].includes(param.libid) ? (
//                 <Box sx={{ mb: 0.5 }}>
//                   <TextField
//                     label={"Reference"}
//                     placeholder='e.g. "For Customers"'
//                     value={param.ref}
//                     onChange={(e) =>
//                       setParam({ ...param, ref: e.target.value })
//                     }
//                     style={{ width: "100%" }}
//                   />
//                   <Box sx={{ mt: 1 }}>
//                     <TextField
//                       label={"Internal note"}
//                       placeholder={"Provide internal note ..."}
//                       value={param.note}
//                       onChange={(e) =>
//                         setParam({ ...param, note: e.target.value })
//                       }
//                       style={{ width: "100%" }}
//                       rows={3}
//                       multiline
//                     />
//                   </Box>
//                 </Box>
//               ) : (
//                 ""
//               )
//             }
//           </div>
//         ) : // Param - Step 1
//         ["param"].includes(type) && step === 1 ? (
//           <div style={{ padding: "20px 20px 0px 20px" }}>
//             <Box sx={{ mt: 1, mb: 1 }}>
//               <FormControl variant="outlined" fullWidth>
//                 {/*<InputLabel style={{backgroundColor: theme.palette.grey[100], padding: '0px 2px 0px 2px'}}>Parameter Type</InputLabel>
//                  */}
//                 <Select
//                   value={param.type}
//                   onChange={(e) => setParam({ ...param, type: e.target.value })}
//                   renderValue={(selected) => {
//                     let s =
//                       paramtypes.filter((tc) => tc.value === selected)[0] !==
//                       undefined
//                         ? paramtypes.filter((tc) => tc.value === selected)[0]
//                             .type
//                         : "";
//                     return <span>{s}</span>;
//                   }}
//                 >
//                   {paramtypes
//                     //.filter((tc) => props.templating || tc.value !== 'auto')
//                     .map((pt, i) => (
//                       <MenuItem key={i} value={pt.value}>
//                         <span
//                           style={{ fontWeight: "700", marginRight: "10px" }}
//                         >
//                           {pt.type}
//                         </span>
//                         {pt.desc}
//                       </MenuItem>
//                     ))}
//                 </Select>
//               </FormControl>
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <ParamEditable
//                 fromClause={false}
//                 type={param.type}
//                 val1={param.val1}
//                 val2={param.val2}
//                 onFieldChange={handleParamValueChange}
//                 sfid={"newparam"}
//               />
//             </Box>
//           </div>
//         ) : // Param - Step 2
//         // TODO: find-all and replicate
//         ["param"].includes(type) && step === 2 ? (
//           <div style={{ padding: "20px 20px 0px 20px" }}>
//             <Box sx={{ mt: 1, mb: 1 }}>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     size="small"
//                     color="primary"
//                     checked={param.createWizard}
//                     onChange={(e) =>
//                       setParam({ ...param, createWizard: e.target.checked })
//                     }
//                   />
//                 }
//                 label={<Typography>Include in Wizard</Typography>}
//               />
//               <Collapse in={param.createWizard}>
//                 <Box sx={{ mt: 1 }}>
//                   <TextField
//                     label={"Wizard Question"}
//                     placeholder={"Provide question ..."}
//                     value={param.createWizardQuestion}
//                     onChange={(e) =>
//                       setParam({
//                         ...param,
//                         createWizardQuestion: e.target.value,
//                       })
//                     }
//                     style={{ width: "100%" }}
//                     rows={3}
//                     multiline
//                   />
//                 </Box>
//               </Collapse>
//             </Box>
//             <Box sx={{ mt: 1, mb: 2 }}>
//               {!["library", "canveo"].includes(param.libid) ? (
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       size="small"
//                       color="primary"
//                       checked={param.isLibItem}
//                       onChange={(e) =>
//                         setParam({ ...param, isLibItem: e.target.checked })
//                       }
//                     />
//                   }
//                   label={<Typography>Save to Properties library</Typography>}
//                 />
//               ) : (
//                 ""
//               )}
//             </Box>
//           </div>
//         ) : // Approval - Step 0
//         ["approval"].includes(type) && step === 0 ? (
//           <div style={{ padding: "20px 20px 0px 20px" }}>
//             <Box sx={{ mt: 1, mb: 2 }}>
//               <Typography variant="h6" align="center">
//                 Select Approver
//               </Typography>
//             </Box>

//             <Box sx={{ mb: ["Counterparty"].includes(userRole) ? 0 : 2 }}>
//               <SelectUserForOrg
//                 orgID={state.org._id}
//                 //cpUsers={state.users.filter((u) => u.active && u._id !== state.user._id && (!isTemplating || ['Admin', 'Legal'].includes(u.role)))}
//                 handleSelectUser={(e) => setApproverSelected(e)}
//                 hiddenUsers={state.users.filter(
//                   (u) => isTemplating && ["Business"].includes(u.role)
//                 )}
//                 userSelected={approverSelected}
//               />
//             </Box>

//             {["Counterparty"].includes(userRole) ? (
//               <Box sx={{ mt: 1, textAlign: "center" }}>
//                 <Button color="secondary">
//                   Create Collaborator&nbsp;&nbsp;
//                   <FontAwesomeIcon icon={faUserPlus} />
//                 </Button>
//               </Box>
//             ) : (
//               ""
//             )}
//           </div>
//         ) : (
//           // Send / Add Message
//           <PlainTextEditor
//             onEscape={onEscape}
//             onChange={onChange}
//             placeholder={
//               ["approval"].includes(type) ? "Add a message ..." : null
//             }
//             isTemplate={isTemplating}
//             isReply={false}
//             isApproval={["approval"].includes(type)}
//           />
//         )
//       }

//       <div className="CommentPlugin_CommentInputBox_Buttons">
//         <div style={{ marginRight: "auto", margin: "1px auto 0px 6px" }}>
//           {
//             /*['comment'].includes(type) ?
//           <FormControlLabel
//             control={<Checkbox size="small" color="primary" checked={isInternal}
//             disabled={isTemplating}
//             onMouseDown={e => { e.preventDefault(); setIsInternal(!isInternal)}} // preventDefault to keep focus in text
//             />}
//             label={<Typography variant="subtitle2">Internal</Typography>}
//           />
//           :*/
//             ["approval", "param"].includes(type) && [1, 2].includes(step) ? (
//               <Button onClick={(e) => setStep(0)}>
//                 <FontAwesomeIcon icon={faArrowLeft} />
//                 &nbsp;&nbsp;Back
//               </Button>
//             ) : (
//               ""
//             )
//           }
//         </div>

//         {(["approval"].includes(type) && [0].includes(step)) ||
//         (["param"].includes(type) &&
//           [0, 1].includes(step) &&
//           !["canveo"].includes(param.libid)) ? (
//           <Button
//             onClick={(e) => setStep(step + 1)}
//             disabled={
//               (["approval"].includes(type) && !approverSelected) ||
//               (["param"].includes(type) &&
//                 ((step === 0 && !param.name) ||
//                   (step === 0 && param.libid === "new" && !param.ref) ||
//                   (step === 0 &&
//                     param.libid === "new" &&
//                     state.params.lib.some(
//                       (li) => li.name === param.name && li.ref === param.ref
//                     ))))
//             }
//           >
//             Next&nbsp;&nbsp;
//             <FontAwesomeIcon icon={faArrowRight} />
//           </Button>
//         ) : (
//           <Button
//             onClick={["param"].includes(type) ? submitParam : submitComment}
//             disabled={!canSubmit && !["param"].includes(type)}
//           >
//             Submit&nbsp;&nbsp;
//             <FontAwesomeIcon icon={faPaperPlane} />
//           </Button>
//         )}
//       </div>
//     </div>
//   );
// }

/**
 * @typedef {object} WorkflowPluginProps
 * @property {string} partyID
 * @property {string} docID
 * @property {string} agrvID
 * @property {boolean} isTemplating
 * @property {*} user
 * @property {boolean} [canAddComments]
 * @property {string} agreementTypeId
 * @property {string[]} agreementLabelsIds
 */

/**
 * @param {WorkflowPluginProps} props
 * @returns {JSX.Element}
 */
export default function WorkflowPlugin({
  partyID,
  docID,
  agrvID,
  isTemplating,
  user,
  // @ts-ignore
  canAddComments = true,
  agreementTypeId,
  agreementLabelsIds,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  const /** @type {string} */ organizationName = state.org.shortName;
  const /** @type {string} */ organizationId = state.org._id;

  /** @type {import("../nodes/RedlineNode").NodeMetadata} */
  const metadata = {
    creatorId: user?._id,
    creatorEmail: user?.email,
    creatorDisplayName: user?.displayName,
    creatorPhotoUrl: user?.photoURL,
    creationDate: new Date().toUTCString(),
    partyId: partyID,
  };

  const markNodeMap = useMemo(() => {
    return new Map();
  }, []);
  const [, setActiveAnchorKey] = useState(null);
  const { setIsClauseLibrary, setActiveClauseKey, setActiveClauseItems } =
    useClauseOptions();

  const [activeIDs, setActiveIDs] = useState([]);
  const [, setShowCommentInput] = useState(false);
  const [, setShowApprovalInput] = useState(false);
  const [createComment, setCreateComment] = useState(
    /** @type {* | null} */ (null)
  );
  const [, setPredictedParam] = useState(null);
  const [mergeFieldText, setMergeFieldText] = useState(null);
  const [openMergeFieldMenu, setOpenMergeFieldMenu] = useState(false);
  const [existingMergeField, setExistingMergeField] = useState(
    /** @type {import("../nodes/MarkNode").MergeField | undefined} */ (
      undefined
    )
  );

  useEffect(() => {
    if (state?.selectedMergeField?.event === "OPEN_MERGE_FIELD_MENU") {
      delete state.selectedMergeField.event;

      if (!state?.selectedMergeField?._id) {
        throw new Error("Missing Merge Field ID");
      }

      axios
        .get(
          `${state.settings.api}mergefield/${state?.selectedMergeField?._id}`
        )
        .then((response) => {
          setExistingMergeField(response.data.data);
          setOpenMergeFieldMenu(true);
        });
    }
  }, [state.selectedMergeField, state.settings.api]);

  /**
   * Mimics text selection when focus is moved away from the editor by
   * creating absolute elements with opacity on top of the selected text.
   *
   * @param {import("lexical").RangeSelection} selection
   * @param {import("lexical").LexicalEditor} editor
   *
   * @returns {boolean} `true` if highlight was successfully created, otherwise returns `false`.
   */
  const createMergeFieldHighlight = (selection, editor) => {
    const anchor = selection.anchor;
    const focus = selection.focus;

    const range = createDOMRange(
      editor,
      anchor.getNode(),
      anchor.offset,
      focus.getNode(),
      focus.offset
    );

    if (!range) return false;

    const selectionRects = createRectsFromDOMRange(editor, range);

    for (const selectionRect of selectionRects) {
      const element = document.createElement("div");
      element.className = "mergeFieldHighlight";

      const style = `position:absolute;top:${
        selectionRect.top + window.scrollY
      }px;left:${selectionRect.left}px;height:${selectionRect.height}px;width:${
        selectionRect.width
      }px;background-color:rgba(50,151,253,0.3);pointer-events:none;z-index:5;`;
      element.style.cssText = style;

      document.body.appendChild(element);
    }

    return true;
  };

  const removeMergeFieldHighlight = () => {
    const boxes = document.querySelectorAll(".mergeFieldHighlight");
    boxes.forEach((box) => {
      box.remove();
    });
  };

  const cancelAddWorkflow = useCallback(
    (filter = "none") => {
      editor.update(() => {
        const selection = $getSelection();
        // Restore selection.
        if (selection !== null) selection.dirty = true;

        // Remove the clause pop in if it's applicable.
        // @ts-ignore
        if (filter === "none" && createComment?.clauseKey) {
          // @ts-ignore
          addClauseFilter(createComment.clauseKey, filter);
        }
      });

      setCreateComment(null);
      setShowCommentInput(false);
      setShowApprovalInput(false);
      setPredictedParam(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, createComment]
  );

  /*
  const deleteComment = useCallback(
    (comment, thread) => {
      setComments((_comments) => {
        const nextComments = Array.from(_comments);

        if (thread !== undefined) {
          for (let i = 0; i < nextComments.length; i++) {
            const nextComment = nextComments[i];
            if (nextComment.type === 'thread' && nextComment.id === thread.id) {
              const newThread = cloneThread(nextComment);
              nextComments.splice(i, 1, newThread);
              const threadComments = newThread.comments;
              const index = threadComments.indexOf(comment);
              threadComments.splice(index, 1);
              if (threadComments.length === 0) {
                const threadIndex = nextComments.indexOf(newThread);
                nextComments.splice(threadIndex, 1);
                // Remove ids from associated marks
                const id = thread !== undefined ? thread.id : comment.id;
                const markNodeKeys = markNodeMap.get(id);
                if (markNodeKeys !== undefined) {
                  // Do async to avoid causing a React infinite loop
                  setTimeout(() => {
                    editor.update(() => {
                      for (const key of markNodeKeys) {
                        const node = $getNodeByKey(key);
                        if ($isMarkNode(node)) {
                          node.deleteID(id);
                          if (node.getIDs().length === 0) {
                            $unwrapMarkNode(node);
                          }
                        }
                      }
                    });
                  });
                }
              }
              break;
            }
          }
        } else {
          const index = nextComments.indexOf(comment);
          nextComments.splice(index, 1);
        }
        return nextComments;
      });
    },
    [editor, markNodeMap],
  );*/

  const createWorkflowAndAttachToDOM = useCallback(
    /**
     * @param {import("./OpenIssuesPlugin").Workflow} newWF
     * @param {any[]} emailRecipients
     * @param {boolean} isInlineComment
     */
    (newWF, emailRecipients, isInlineComment) => {
      axios
        .post(state.settings.api + "workflow", { workflow: newWF })
        .then((resWF) => {
          if (resWF.data.success) {
            // Add newly created WF to the reducer
            let createdWF = resWF.data.data;
            dispatch({ type: "ADD_WORKFLOW", payload: createdWF });
            if (isInlineComment) {
              // @ts-ignore
              attachWorkflowToDOM(resWF.data.data.lid);

              // Now send emails to recipients
              emailRecipients.forEach((r) => {
                const partyFullString = state.parties.find(
                  // @ts-ignore
                  (p) => p.orgID === r.recipient.orgID
                )?.legalName;

                axios.post(state.settings.api + "mail/informcomm", {
                  doc: r.doc,
                  whiteLabel: r.isTemplating ? null : null, // TODO
                  partyFullString,
                  recipient: r.recipient,
                  isPublic: !r.isInternal,
                  isTemplating: r.isTemplating,
                  isApproval: ["approval"].includes(newWF.wfType),
                  comment: r.content,
                  clauseHTML: r.clauseHTML,
                  wfid: createdWF._id,
                  lid: createdWF.lid,
                });
              });
              cancelAddWorkflow();
            }
          }
        })
        .catch((err) => {
          console.log("err saving to workflow", err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  /**
   *
   * @param {*} newWF
   * @param {*} emailRecipients
   * @param {*} clauseKey
   */
  const createWorkflowAndAttachToClause = (
    newWF,
    emailRecipients,
    clauseKey
  ) => {
    axios
      .post(state.settings.api + "workflow", { workflow: newWF })
      .then((resWF) => {
        if (resWF.data.success) {
          // Add newly created workflow to the reducer.
          let createdWF = resWF.data.data;

          editor.dispatchCommand(ADD_WORKFLOW_COMMAND, {
            key: clauseKey,
            wfid: newWF.lid,
          });
          dispatch({ type: "ADD_WORKFLOW", payload: createdWF });

          setTimeout(() => {
            dispatch({
              type: "NEW_OPEN_ISSUE_SELECTION",
              payload: {
                id: `${clauseKey}_${newWF.lid}`,
                type: "navigation",
                status: "completed",
              },
            });
          }, 50);

          // Send emails to recipients.
          const legalNames = state.parties.map(
            (/** @type {{ legalName: string; }} */ p) => p.legalName
          );
          const partyFullString =
            state.parties.length === 2
              ? legalNames.join(" and ")
              : legalNames.join(", ");
          emailRecipients.forEach(
            (
              /** @type {{ doc: any; isTemplating: any; recipient: any; isInternal: any; content: any; clauseHTML: any; }} */ r
            ) => {
              axios.post(state.settings.api + "mail/informcomm", {
                doc: r.doc,
                whiteLabel: r.isTemplating ? null : null, // TODO
                partyFullString: partyFullString,
                recipient: r.recipient,
                isPublic: !r.isInternal,
                isTemplating: r.isTemplating,
                isApproval: ["approval"].includes(newWF.wfType),
                comment: r.content,
                clauseHTML: r.clauseHTML,
                wfid: createdWF._id,
                lid: createdWF.lid,
              });
            }
          );

          cancelAddWorkflow("in");
        }
      });
  };

  /**
   * Wraps current selection in a Mark node.
   *
   * @param {string} newLid
   * @param {import("../nodes/MarkNode").MergeField} mergeField
   */
  const attachWorkflowToDOM = (newLid, mergeField) => {
    editor.update(() => {
      // $getSelection returns null on Firefox but $getPreviousSelection works so we use it as a fallback.
      const selection = $getSelection() ?? $getPreviousSelection();
      if ($isRangeSelection(selection)) {
        const markNode = $wrapSelectionInMarkNode(
          selection,
          selection.isBackward(),
          newLid,
          metadata,
          mergeField
        );

        // If the Mark node is a Merge Field we want to open it in the Open Issue
        // navigation menu after creating it.
        if (["mergeField", "publicComment"].includes(markNode.getMarkType())) {
          setTimeout(() => {
            dispatch({
              type: "NEW_OPEN_ISSUE_SELECTION",
              payload: {
                id: `${markNode.getKey()}_${newLid}`,
                type: "navigation",
                status: "ongoing",
              },
            });
          }, 50);
        }
      }
      setShowCommentInput(false);
    });
  };

  /**
   * Updates existing Mark Nodes on the document text with the updated Merge Field.
   *
   * @param {string} editorMarkNodeId
   * @param {import("../nodes/MarkNode").MergeField} mergeField
   */
  const updateDomWorkflow = (editorMarkNodeId, mergeField) => {
    editor.update(() => {
      const depthFirstSearch = $dfs();

      for (const { node } of depthFirstSearch) {
        // Find all the Mark Nodes that correspond to the Merge Field.
        if (
          $isMarkNode(node) &&
          node.getMarkType() === "mergeField" &&
          node.getIDs().find((id) => id === editorMarkNodeId)
        ) {
          // Update the Merge Field on the node.
          node.setMergeField(mergeField);

          if (
            mergeField.displayValue.toLowerCase() !==
            node.getTextContent().toLowerCase()
          ) {
            // Mark original text as a deletion.
            const deletion = $createRedlineNode({
              partyID: "party0",
              redlineType: "del",
              date: new Date().toUTCString(),
              metadata,
              text: node.getTextContent(),
            });

            // Mark new text as an addition.
            const addition = $createRedlineNode({
              partyID: "party0",
              redlineType: "add",
              date: new Date().toUTCString(),
              metadata,
              text: mergeField.displayValue,
            });

            node.append(deletion, addition);

            const children = node.getChildren();
            // Remove previous children.
            const childrenToRemove = children.slice(0, children.length - 2);
            childrenToRemove.forEach((child) => child.remove());
          }
        }
      }
    });
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          removeMergeFieldHighlight();
          setMergeFieldText(null);
          setExistingMergeField(undefined);
          setOpenMergeFieldMenu(false);
          return PROPAGATE_EVENT;
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor]);

  useEffect(() => {
    // @ts-ignore
    const changedElems = [];
    for (let i = 0; i < activeIDs.length; i++) {
      // @ts-ignore
      const id = activeIDs[i];
      const keys = markNodeMap.get(id);
      if (keys !== undefined) {
        for (const key of keys) {
          const elem = editor.getElementByKey(key);
          if (elem !== null) {
            elem.classList.add("selected");
            changedElems.push(elem);
          }
        }
      }
    }
    return () => {
      for (let i = 0; i < changedElems.length; i++) {
        // @ts-ignore
        const changedElem = changedElems[i];
        changedElem.classList.remove("selected");
      }
    };
  }, [activeIDs, editor, markNodeMap]);

  /**
   * @param {*} clauseKey
   * @param {*} filter
   */
  const addClauseFilter = (clauseKey, filter) => {
    const clause = $getNodeByKey(clauseKey);
    // @ts-ignore
    clause.changeFilter(filter);
    const element = editor.getElementByKey(clauseKey);
    // @ts-ignore
    element.scrollIntoView({ block: "center" });
  };

  useEffect(
    () => {
      const markNodeKeysToIDs = new Map();

      return mergeRegister(
        registerNestedElementResolver(
          editor,
          MarkNode,
          (from) => {
            const markNode = $createMarkNode(from.getIDs(), metadata);

            if (from.getMarkType() === "mergeField") {
              const mergeField = from.getMergeField();
              if (!mergeField) {
                throw new Error("Missing merge field for mark node.");
              }
              markNode.setMergeField(mergeField);
            }

            return markNode;
          },
          (from, to) => {
            // Merge the IDs
            const ids = from.getIDs();
            ids.forEach((id) => {
              to.addID(id);
            });
          }
        ),
        editor.registerMutationListener(MarkNode, (mutations) => {
          editor.update(() => {
            for (const [key, mutation] of mutations) {
              const node = $getNodeByKey(key);
              let ids = [];

              if (mutation === "destroyed") {
                ids = markNodeKeysToIDs.get(key) || [];
              } else if ($isMarkNode(node)) {
                ids = node.getIDs();
              }

              for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                let markNodeKeys = markNodeMap.get(id);
                markNodeKeysToIDs.set(key, ids);

                if (mutation === "destroyed") {
                  if (markNodeKeys !== undefined) {
                    markNodeKeys.delete(key);
                    if (markNodeKeys.size === 0) {
                      markNodeMap.delete(id);
                    }
                  }
                } else {
                  if (markNodeKeys === undefined) {
                    markNodeKeys = new Set();
                    markNodeMap.set(id, markNodeKeys);
                  }
                  if (!markNodeKeys.has(key)) {
                    markNodeKeys.add(key);
                  }
                }
              }
            }
          });
        }),
        editor.registerUpdateListener(({ editorState, tags }) => {
          editorState.read(() => {
            const selection = $getSelection();
            let hasActiveIds = false;
            let hasAnchorKey = false;

            if ($isRangeSelection(selection)) {
              const anchorNode = selection.anchor.getNode();

              // Assign Active Workflow IDs (Comments, Approvals, Params)
              if ($isTextNode(anchorNode)) {
                const commentIDs = $getMarkIDs(
                  anchorNode,
                  selection.anchor.offset
                );
                if (commentIDs !== null) {
                  // @ts-ignore
                  setActiveIDs(commentIDs);
                  hasActiveIds = true;
                }
                if (!selection.isCollapsed()) {
                  // @ts-ignore
                  setActiveAnchorKey(anchorNode.getKey());
                  hasAnchorKey = true;
                }
              }

              // Assign Active Clause Types
              //if ($isTextNode(anchorNode)) {
              let cNode = getClauseNode(anchorNode);
              setActiveClauseItems({
                cts: cNode ? cNode.getClauseTypes() : [],
                wfs: [],
              });
              // @ts-ignore
              setActiveClauseKey(cNode ? cNode.getKey() : null);
              setIsClauseLibrary(
                // @ts-ignore
                cNode &&
                  cNode.getLibIDs() &&
                  cNode
                    .getLibIDs()
                    .some(
                      (clid) =>
                        clid.startsWith(state.org._id) &&
                        state.clauseLibItems.some(
                          (/** @type {{ _id: string; }} */ cli) =>
                            cli._id === clid.substring(clid.indexOf("_") + 1)
                        )
                    )
              );
            } else {
              setActiveClauseItems({ cts: [], wfs: [] });
              setActiveClauseKey(null);
              setIsClauseLibrary(false);
            }

            if (!hasActiveIds) {
              setActiveIDs((_activeIds) =>
                _activeIds.length === 0 ? _activeIds : []
              );
            }
            if (!hasAnchorKey) {
              setActiveAnchorKey(null);
            }
          });
          if (!tags.has("collaboration")) {
            setShowCommentInput(false);
            setShowApprovalInput(false);
          }
        }),
        editor.registerCommand(
          INSERT_INLINE_COMMAND,
          // @ts-ignore
          (type) => {
            const domSelection = window.getSelection();
            if (!domSelection) return PREVENT_EVENT_PROPAGATION;
            domSelection.removeAllRanges();
            const selection = $getSelection();
            // @ts-ignore
            const selectedText = selection.getTextContent();

            switch (type) {
              case "comment":
                setCreateComment({
                  isPrivate: false,
                  selectedText: selectedText,
                });
                setShowCommentInput(true);
                break;

              case "approval":
                setShowApprovalInput(true);
                break;

              case "internalComment":
                // @ts-ignore
                const anchorNode = selection.anchor.getNode();
                const clauseNode = $getNearestNodeOfType(
                  anchorNode,
                  ClauseNode
                );

                if (!clauseNode) return;

                editor.update(() => addClauseFilter(clauseNode.getKey(), "in"));

                setCreateComment({
                  isPrivate: true,
                  selectedText: selectedText,
                  clauseKey: clauseNode.getKey(),
                });
                break;

              case "param":
                if (!$isRangeSelection(selection)) {
                  return PREVENT_EVENT_PROPAGATION;
                }

                if (!createMergeFieldHighlight(selection, editor)) {
                  return PREVENT_EVENT_PROPAGATION;
                }

                const values = greatPatternFinder(selectedText);
                // @ts-ignore
                setPredictedParam(values);
                // @ts-ignore
                setMergeFieldText({ editorValue: selectedText });
                break;

              default:
                throw new Error("Invalid type.");
            }

            return PREVENT_EVENT_PROPAGATION;
          },
          COMMAND_PRIORITY_EDITOR
        ),
        editor.registerCommand(
          REMOVE_MARK,
          (lid) => {
            const nodeMapArray = [...editor.getEditorState()._nodeMap];
            nodeMapArray
              .filter(
                (n) => n[1].getType() === "mark" && n[1].getIDs().includes(lid)
              )
              .forEach((nodeKey) => {
                let node = nodeKey[1];
                node.deleteID(lid);
                if (node.getIDs().length === 0) {
                  // @ts-ignore
                  $unwrapMarkNode(node);
                }
              });
            return true;
          },
          COMMAND_PRIORITY_EDITOR
        )
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, markNodeMap, state.clauseLibItems]
  );

  /*
  const getClauseTypesForNode = (node) => {
    let cts = []

    if(node.getType() === 'clause') {
      cts = node.getClauseTypes();
      let key = node.getKey();
      cts.forEach((ct) => ct = {...ct, key: key })
    } else if(Boolean(node.getParent())){
      cts = getClauseTypesForParent(node.getParent());
    }
    return cts;

  }

  function getClauseTypesForParent(node) {
    let cts = []

    if(node.getType() === 'root') {
      cts = []
    } else if(node.getType() === 'clause') {
      cts = node.getClauseTypes();
      let key = node.getKey();
      cts.forEach((ct) => ct = {...ct, key: key })
    } else if(Boolean(node.getParent())){
      cts = getClauseTypesForParent(node.getParent());
    }
    return cts;
  }

  function getClauseKey(node) {
    let key = null;
    if(node.getType() === 'root') {
      key = null
    } else if(node.getType() === 'clause') {
      key = node.getKey();
    } else if(Boolean(node.getParent())){
      key = getClauseKey(node.getParent());
    }
    return key;
  }*/

  // const onAddComment = () => {
  //   editor.dispatchCommand(INSERT_INLINE_COMMAND, "comment");
  // };

  // const onAddApproval = () => {
  //   editor.dispatchCommand(INSERT_INLINE_COMMAND, "approval");
  // };

  return (
    <>
      {/* Old context menus; uncomment to see them. */}
      {/* {showApprovalInput &&
        canAddComments &&
        createPortal(
          <CommentInputBox
            type={showApprovalInput ? "approval" : "comment"}
            predictedParam={predictedParam}
            partyID={partyID}
            docID={agrvID ?? docID}
            isTemplating={isTemplating}
            editor={editor}
            user={state.user}
            cancelAddWorkflow={cancelAddWorkflow}
            createWorkflowAndAttachToDOM={createWorkflowAndAttachToDOM}
            attachWorkflowToDOM={attachWorkflowToDOM}
          />,
          document.body
        )}

      {activeAnchorKey === null &&
        canAddComments &&
        Boolean(activeClauseKey) &&
        createPortal(
          <ContextItems
            visible={true}
            publicComments={[]}
            internalComments={[]}
            //approvals={activeIDs.filter((id) => id.startsWith('ap' + props.partyID.substring(5)))}
            params={activeIDs.filter((id) =>
              id.startsWith("pa" + partyID.substring(5))
            )}
            activeClauseItems={activeClauseItems}
            activeClauseKey={activeClauseKey}
            isClauseLibrary={isClauseLibrary}
            isTemplating={isTemplating}
            partyID={partyID}
            docID={docID}
          />,
          document.body
        )} */}

      {createComment && (
        <CommentMenu
          partyID={partyID}
          docID={docID}
          isTemplate={isTemplating}
          editor={editor}
          {...createComment}
          createWorkflowAndAttachToDOM={createWorkflowAndAttachToDOM}
          createWorkflowAndAttachToClause={createWorkflowAndAttachToClause}
          handleClose={cancelAddWorkflow}
        />
      )}

      {(openMergeFieldMenu || !!mergeFieldText) && (
        <MergeFieldMenu
          open={true}
          organizationId={organizationId}
          organizationName={organizationName}
          isQuestionnaire={false}
          existingMergeField={existingMergeField}
          // @ts-ignore
          mergeFieldText={mergeFieldText?.editorValue || ""}
          documentIsTemplate={isTemplating}
          partyId={partyID}
          docId={agrvID ?? docID}
          handleSubmit={attachWorkflowToDOM}
          handleUpdate={updateDomWorkflow}
          handleClose={() => {
            removeMergeFieldHighlight();
            setMergeFieldText(null);
            setExistingMergeField(undefined);
            setOpenMergeFieldMenu(false);
          }}
          agreementTypeId={agreementTypeId}
          agreementLabelsIds={agreementLabelsIds}
        />
      )}
    </>
  );
}
