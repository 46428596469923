import { faFile } from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faBookCircleArrowUp,
  faFileContract,
  faSearch,
  faTimesCircle,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useSearchParams } from "react-router-dom";
import docx from "../assets/img/docx.png";
import pdf from "../assets/img/pdf.png";
import xlsx from "../assets/img/xlsx.png";
import {
  ButtonGroupPills,
  CanveoCircularProgress,
  CoverFlow,
  SelectOrCreateAgrType,
} from "../components";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { randomString as generateRandomString } from "../utils";
import { getCanveoTier } from "../utils/getCanveoTier";
import DialogFileConversionErrorDetails from "./dialogs/DialogFileConverionErrorDetails";
import defaultSfdt from "./editor/converters/defaultSfdt";
import { convertSfdtToLexical } from "./editor/utils";

const fileAppTypes = {
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const fileSources = [
  { label: "Template by counterparty", value: "Counterparty" },
  {
    label: "Template by us",
    value: "Owner",
  },
  { label: "Other", value: "Other" },
];

const minTemplateCountForSearch = 3;

/**
 * @typedef {*} SelectOrUploadTemplateProps
 */

/**
 * @param {SelectOrUploadTemplateProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectOrUploadTemplate(props) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const newAgreementFromFilename = searchParams.get("newAgreementFromFilename");
  const newVersionFromFilename = searchParams.get("newVersionFromFilename");
  const ticketId = searchParams.get("ticketId") || props?.taskId || undefined;

  const [userRole] = useState(state.user.role?.name);
  const [dropHover, setDropHover] = React.useState(false);

  const [templateSource, setTemplateSource] = React.useState(
    ticketId
      ? "ticket"
      : newAgreementFromFilename
      ? "import"
      : userRole === "Counterparty"
      ? "import"
      : "company"
  );
  const [stagedUpload, setStagedUpload] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(
    /** @type {string | null} */ (null)
  );
  const [filterText, setFilterText] = useState("");
  const [task, setTask] = useState(/** @type {* | undefined} */ (undefined));

  /**
   * @param {string} filename
   */
  async function loadTemplate(filename) {
    try {
      setLoading(true);
      setTemplateSource("import");
      const importResult = await axios.get(
        `${state.settings.api}document/import/${filename}`
      );

      if (!importResult?.data?.data) {
        throw new Error("Error getting file.");
      }

      const lexical = await convertSfdtToLexical(importResult.data.data, state);

      const uploadFile = {
        fileType: "docx",
        file: filename,
        fileName: "Agreement.docx",
        uploadType: "import",
        agrType: null,
        fileSource: fileSources[0], // "Template by counterparty" is always the default option.
        tempKey: generateRandomString(20),
        // @ts-ignore
        content: lexical?.content ?? [],
        contentMetadata: {
          listsStructure: lexical.listsStructure,
        },
        sfdt: importResult.data.data,
        firstPageHeader: lexical.header,
        firstPageFooter: lexical.footer,
      };
      setStagedUpload(uploadFile);
    } catch (error) {
      setErrMsg("An error occurred while converting the file (interim step)");
    } finally {
      setLoading(false);
    }
  }

  useEffect(
    () => {
      if (ticketId) {
        loadTicket(ticketId);
      }
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   *
   * @param {string} ticketId
   */
  async function loadTicket(ticketId) {
    try {
      setLoading(true);

      const getTaskByIdResponse = await axios.get(
        `${state.settings.api}task/${ticketId}`
      );
      const task = getTaskByIdResponse.data.data;

      setTask(task);
    } catch (error) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while retrieving the task.",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param {string} filename
   */
  async function openNewVersionFromFilename(filename) {
    try {
      setLoading(true);

      const importResult = await axios.get(
        `${state.settings.api}document/import/${filename}`
      );

      if (!importResult?.data?.data) {
        throw new Error("Error getting file.");
      }

      const lexical = await convertSfdtToLexical(importResult.data.data, state);
      const agreementId = lexical?.metadata?.agreementId;
      if (agreementId) {
        navigate(
          `/agreement/${agreementId}/?openNewVersionDialog=true&newVersionFromFilename=${newVersionFromFilename}`
        );
      }
    } catch (error) {
      setErrMsg("An error occurred while converting the file (interim step)");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (newAgreementFromFilename) {
      loadTemplate(newAgreementFromFilename);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAgreementFromFilename]);

  useEffect(() => {
    if (newVersionFromFilename) {
      openNewVersionFromFilename(newVersionFromFilename);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVersionFromFilename]);

  const filteredTemplates = useMemo(() => {
    const lowerText = filterText.toLowerCase();
    if (!props.label) {
      return state.templates.filter(
        (/** @type {{ active: boolean; reference: string; }} */ t) =>
          t.active && t.reference.toLowerCase().includes(lowerText)
      );
    }

    return state.templates.filter(
      (
        /** @type {{ active: boolean; labelIDs: string[]; reference: string; }} */ t
      ) =>
        t.active &&
        t.labelIDs?.includes(props.label._id) &&
        t.reference.toLowerCase().includes(lowerText)
    );
  }, [props.label, state.templates, filterText]);

  useEffect(() => {
    if (
      searchParams.has("newAgreementFromFilename") &&
      templateSource === "import"
    ) {
      return;
    }
    if (searchParams.has("upload") && templateSource === "import") return;
    if (searchParams.has("ticketId")) return;
    if (!filteredTemplates.length && props.isMain && !filterText) {
      return setTemplateSource("import");
    }
    if (props.isCounterparty) return setTemplateSource("import");
    setTemplateSource("company");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTemplates, props.isMain, filterText, searchParams]);

  useEffect(() => {
    if (searchParams.has("upload") && templateSource === "import") return;
    if (templateSource !== "scratch") return setStagedUpload(null);

    convertSfdtToLexical(defaultSfdt, state).then((importedLexical) => {
      setStagedUpload({
        // @ts-ignore
        uploadType: "scratch",
        agrType: null,
        content: importedLexical.content,
        contentMetadata: {
          listsStructure: importedLexical.listsStructure,
        },
        fileSource: null,
        sfdt: defaultSfdt,
        firstPageHeader: null,
        firstPageFooter: null,
        tempKey: generateRandomString(20),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSource]);

  useEffect(() => {
    if (
      searchParams.has("newAgreementFromFilename") &&
      templateSource === "import"
    ) {
      return;
    }
    if (!searchParams.has("upload")) return;
    if (searchParams.has("ticketId")) return;
    setTemplateSource("import");
    setStagedUpload(state.fileUpload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  /**
   *
   * @param {string} id
   * @param {string} type
   */
  const handleSelectTemplate = (id, type) => {
    setLoading(true);
    // let oat = state.templates.filter((t) => t._id === id)[0]

    axios
      .get(state.settings.api + "template/" + id)
      .then((resTemp) => {
        if (resTemp.data.success) {
          // dispatch({ type: "INIT_WORKFLOWS", payload: resTemp.data.data.workflows })
          // dispatch({ type: "INIT_PARAMS_DOC", payload: resTemp.data.data.params })
          // setTemplate(resTemp.data.data.template)

          const templates = resTemp.data.data.templates;

          if (type === "main") {
            const mainTemplate = templates.find(
              (/** @type {{ parentID: string; }} */ t) => !t.parentID
            );

            const exhibits = templates.filter(
              (/** @type {{ parentID: string; }} */ t) => t.parentID
            );

            props.handleSelectOrUploadTemplate(
              type,
              mainTemplate,
              "exhibit",
              exhibits
            );
          }
          // Exhibits...
          else {
            props.handleSelectOrUploadTemplate(type, templates);
          }

          setTemplateSource("company");
          setLoading(false);
        } else {
          setErrMsg(
            "Unable to retrieve template - refresh browser and try again"
          );
          setLoading(false);
        }
      })
      .catch(() => {
        setErrMsg("Unable to retrieve tremplate");
        setLoading(false);
      });
  };

  /**
   * @param {{name: string; type: string;}} _
   */
  const getFileType = ({ name, type }) => {
    if (name?.endsWith(".pdf") && type === fileAppTypes.pdf) return "pdf";
    if (name?.endsWith(".doc") && type === fileAppTypes.doc) return "doc";
    if (name?.endsWith(".docx") && type === fileAppTypes.docx) return "docx";
    if (name?.endsWith(".xlsx") && type === fileAppTypes.xlsx) return "xlsx";

    return null;
  };

  const [conversionError, setConversionError] = React.useState(
    /** @type {Error | undefined} */ (undefined)
  );

  /**
   * @param {*} file
   */
  const handleUploadChange = async (file) => {
    try {
      setErrMsg(null);
      setLoading(true);

      const fileType = getFileType(file);
      if (!fileType) {
        setErrMsg("Unable to recognize the file type");
        setLoading(false);
        return;
      }

      const uploadSignedUrlResponse = await axios.post(
        state.settings.api + "upload/signedUrl",
        {
          contentType: file.type,
          bucketAlias: "documents",
        }
      );

      const uploadSignedUrl = uploadSignedUrlResponse.data.data.uploadSignedUrl;
      const fileName = uploadSignedUrlResponse.data.data.key;

      await fetch(uploadSignedUrl, { method: "PUT", body: file });

      // TODO: The issue is somewhere over here.

      let uploadFile = {
        fileType: fileType,
        file: fileName,
        fileName: file.name,
        uploadType: "import",
        // Previous approach.
        // uploadType:
        //   ["pdf"].includes(fileType) && props.isMain
        //     ? "backload" // A PDF file as main means: handled outside Canveo, set to InEffect
        //     : "import", // A PDF as exhibit means: attachment
        agrType: null,
        fileSource: fileSources[0], // "Template by counterparty" is always the default option.
        tempKey: generateRandomString(20),
      };

      if (fileType === "doc" || fileType === "docx" || fileType === "pdf") {
        const importResult = await axios.get(
          `${state.settings.api}document/import/${fileName}`
        );

        if (!importResult?.data?.data) {
          setErrMsg(
            "An error occurred while converting the file (interim step)"
          );
          setLoading(false);
          return;
        }

        const lexical = await convertSfdtToLexical(
          importResult.data.data,
          state
        );

        uploadFile = {
          ...uploadFile,
          // @ts-ignore
          content: lexical?.content ?? [],
          contentMetadata: {
            listsStructure: lexical.listsStructure,
          },
          sfdt: importResult.data.data,
          firstPageHeader: lexical.header,
          firstPageFooter: lexical.footer,
        };
      }

      setLoading(false);
      setStagedUpload(uploadFile);
    } catch (error) {
      setErrMsg("An error occurred while processing the Word document.");
      if (error instanceof Error) {
        setConversionError(error);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {string} changeType
   * @param {string} value
   */
  const handleAgrTypeChange = (changeType, value) => {
    if (["newName"].includes(changeType)) {
      // You're creating a new Agr Type => defining name
      setStagedUpload(
        (
          /** @type {{ agrType: { newName: string; shortName: string; }; }} */ prev
        ) => ({
          ...prev,
          agrType: {
            newName: value,
            shortName:
              prev.agrType?.newName && prev.agrType?.shortName
                ? prev.agrType.shortName
                : "",
          },
        })
      );
    } else if (["shortName"].includes(changeType)) {
      // You're creating a new Agr Type => defining short name
      setStagedUpload({
        ...stagedUpload,
        agrType: { ...stagedUpload.agrType, shortName: value },
      });
    } else if (["agrType"].includes(changeType)) {
      // You've picked an existing Agr Type
      setStagedUpload({ ...stagedUpload, agrType: value });
    }
  };

  const handleFileUploadSubmit = () => {
    // Check if new Agreement Type needs to be created
    if (
      stagedUpload.agrType._id === undefined &&
      stagedUpload.agrType.newName !== undefined &&
      stagedUpload.agrType.shortName !== undefined
    ) {
      // Creating a new Agreement Type
      let newat = {
        fullName: stagedUpload.agrType.newName,
        shortName: stagedUpload.agrType.shortName,
        orgID: state.org._id,
        active: true,
      };

      // Create the new Agreement type
      axios
        .post(state.settings.api + "agrtype", { agrType: newat })
        .then((resAgrt) => {
          if (resAgrt.data.success) {
            // Add newly created agrType to the reducer
            // Create with the new AgrType
            dispatch({ type: "ADD_AGRTYPE", payload: resAgrt.data.data });
            pushUploadToStagedAg(stagedUpload, resAgrt.data.data);
          } else {
            setErrMsg(
              "An error occured while creating the agreement type - refresh your browser"
            );
            setLoading(false);
          }
        })
        .catch(() => {
          setErrMsg("An error occured while creating the agreement type");
          setLoading(false);
        });
    } else if (stagedUpload.agrType._id !== undefined) {
      // No new AgrType needs to be created
      pushUploadToStagedAg(stagedUpload, stagedUpload.agrType);
    }
  };

  /**
   * @param {*} upload
   * @param {*} agrType
   */
  const pushUploadToStagedAg = (upload, agrType) => {
    upload.agrTitle = agrType.fullName[0];
    upload.agrTypeID = agrType._id;

    props.handleSelectOrUploadTemplate(
      props.isMain ? "main" : "exhibit",
      upload
    );
    setTemplateSource("company");
    setStagedUpload(null);
  };

  /**
   * @param {string} value
   */
  const changeFileSource = (value) => {
    setStagedUpload((/** @type {*} */ prev) => ({
      ...prev,
      fileSource: value,
    }));
  };

  const isSubmitDisabled = () => {
    const isAgreementTypeInvalid =
      !stagedUpload?.agrType ||
      (!stagedUpload?.agrType?._id &&
        (stagedUpload?.agrType?.newName?.length < 2 ||
          stagedUpload?.agrType?.shortName?.length < 2));

    if (templateSource === "scratch") return isAgreementTypeInvalid;

    return isAgreementTypeInvalid || !stagedUpload?.fileSource?.value;
  };

  const getButtons = () => {
    const buttons = /** @type {{ id: string; title: string; }[]} */ ([]);
    if (!props.isCounterparty) {
      buttons.push({ id: "company", title: "Company Templates" });
    }

    buttons.push({ id: "import", title: "Upload File" });

    if (getCanveoTier(state?.user?.email) === "experimental") {
      buttons.push(
        { id: "ticket", title: "Ticket" },
        { id: "scratch", title: "From Scratch" }
      );
    }

    return buttons;
  };

  return (
    <>
      <Grid item container justifyContent="center">
        <Grid item>
          <Box>
            <ButtonGroupPills
              buttons={getButtons()}
              selected={templateSource}
              click={(/** @type {React.SetStateAction<string>} */ e) =>
                setTemplateSource(e)
              }
            />
          </Box>
        </Grid>
      </Grid>

      {errMsg !== null && (
        <Grid item sx={{ my: 4 }}>
          <Typography align="center" color="error">
            {errMsg}
          </Typography>
        </Grid>
      )}

      {templateSource === "company" &&
        (filterText ||
          filteredTemplates.length > minTemplateCountForSearch) && (
          <Grid item container justifyContent="right" sx={{ mb: 1, mt: 3 }}>
            <TextField
              variant="outlined"
              size="small"
              onChange={(e) => setFilterText(e.target.value)}
              value={filterText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ padding: "4px 7px" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: !!filterText && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setFilterText("")}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                  minHeight: "unset",
                },
                inputProps: {
                  style: { fontWeight: "bold" },
                },
              }}
            />
          </Grid>
        )}

      <Grid
        item
        container
        sx={{ mt: userRole === "Counterparty" ? 0 : 2 }}
        justifyContent="center"
      >
        {["company"].includes(templateSource) ? (
          <Box sx={{ width: "100%" }}>
            {loading ? (
              <Box
                sx={{
                  my: 6,
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <CanveoCircularProgress />
              </Box>
            ) : (
              <CoverFlow
                handleSelect={handleSelectTemplate}
                templates={filteredTemplates}
                logoURL={state.org.logoURL}
                selectionType={props.isMain ? "main" : "exhibit"}
              />
            )}
          </Box>
        ) : templateSource === "ticket" && task ? (
          <Grid container direction="column" alignItems={"left"} width={300}>
            <Grid item mb={4} color={theme.palette.grey[700]}>
              <Typography variant="h6">{`${task.type}: ${task.request.name}`}</Typography>
            </Grid>

            <Grid item mb={2}>
              <Grid container gap={2}>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faFileContract}
                    color={theme.palette.grey[700]}
                  />
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold">
                    Associated Documents
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <FormControl>
              <RadioGroup defaultChecked={false}>
                {task.documents.map(
                  (
                    /** @type {{ file: string; fileName: string; }} */ document
                  ) => (
                    <FormControlLabel
                      key={document.file}
                      value={document.file}
                      control={
                        <Radio
                          onChange={() => {
                            setStagedUpload({ ...document });
                          }}
                        />
                      }
                      label={document.fileName}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>

            <Grid item mt={4}>
              <Autocomplete
                sx={{ width: "330px" }}
                options={fileSources}
                autoHighlight
                // @ts-ignore
                onChange={(e, value) => changeFileSource(value)}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Source"
                    variant="outlined"
                    placeholder="Please select ..."
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={faBookCircleArrowUp} />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item mt={2}>
              <SelectOrCreateAgrType
                agrType={undefined}
                setAgrType={handleAgrTypeChange}
                width={"330px"}
                isExhibit={!props.isMain}
                canCreate={state.user?.role?.name !== "Counterparty"}
                justifyContent={""}
              />
            </Grid>

            <Grid item>
              <Grid container direction="row" justifyContent="right">
                <Grid item>
                  <Button
                    variant="contained"
                    disableElevation
                    disabled={isSubmitDisabled()}
                    onClick={handleFileUploadSubmit}
                  >
                    Submit&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <Box
                sx={{
                  my: 6,
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <CanveoCircularProgress />
              </Box>
            ) : stagedUpload !== null ? (
              <Grid
                container
                direction="column"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item>
                  {stagedUpload.fileName ? (
                    <img
                      src={
                        stagedUpload.fileName.toLowerCase().endsWith("pdf")
                          ? pdf
                          : stagedUpload.fileName.toLowerCase().endsWith("xlsx")
                          ? xlsx
                          : docx
                      }
                      alt=""
                      height={30}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faFile}
                      size="3x"
                      color={theme.palette.grey[700]}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color={"textSecondary"}
                    mt={1}
                  >
                    {stagedUpload.fileName || "Empty Agreement"}
                  </Typography>
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <SelectOrCreateAgrType
                    agrType={stagedUpload.agrType}
                    setAgrType={handleAgrTypeChange}
                    width={"330px"}
                    isExhibit={!props.isMain}
                    canCreate={state.user?.role?.name !== "Counterparty"}
                  />
                </Grid>
                {templateSource !== "scratch" &&
                  state.user.role.name !== "Counterparty" && (
                    <Grid item sx={{ mt: 2 }}>
                      <Autocomplete
                        sx={{ width: "330px" }}
                        options={fileSources}
                        value={stagedUpload.fileSource}
                        autoHighlight
                        onChange={(e, value) => changeFileSource(value)}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Source"
                            variant="outlined"
                            placeholder="Please select ..."
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon
                                      icon={faBookCircleArrowUp}
                                    />
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent={
                    templateSource !== "scratch" ? "space-between" : "flex-end"
                  }
                  mt={2}
                >
                  {templateSource !== "scratch" && (
                    <Grid item>
                      <Button onClick={() => setStagedUpload(null)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        &nbsp;&nbsp;Cancel
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      variant="contained"
                      disableElevation
                      disabled={isSubmitDisabled()}
                      onClick={handleFileUploadSubmit}
                    >
                      Submit&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <FileUploader
                handleChange={handleUploadChange}
                name="uploadfile"
                types={
                  // Whether main agreement or exhibit. There are different rules for each.
                  props.isMain ? ["doc", "docx", "pdf"] : ["doc", "docx"]
                }
                label={"Upload or drop a file here"}
                maxSize={20}
                minSize={0}
                onDraggingStateChange={(/** @type {boolean} */ dragging) =>
                  setDropHover(dragging)
                }
                hoverTitle={" "}
                children={
                  <Box
                    sx={{
                      width: "320px",
                      height: "180px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      border: dropHover
                        ? "2px solid" + theme.palette.primary.main
                        : "1px dotted" + theme.palette.grey[300],
                      backgroundColor: dropHover
                        ? theme.palette.grey[200]
                        : theme.palette.grey[50],
                      padding: "30px",
                      fontSize: "14px",
                      fontWeight: "600",
                      borderRadius: "20px",
                    }}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <FontAwesomeIcon
                          icon={faUpload}
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: "30px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{ mt: 1, display: "block", textAlign: "center" }}
                        >
                          <>
                            {dropHover
                              ? "Time to let the file go ..."
                              : "Upload or drop your file here ..."}
                            <br />
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              justifyContent="center"
                              sx={{ mt: 1 }}
                            >
                              <Grid item>
                                <img src={docx} alt="docx" width={20} />
                              </Grid>

                              {props.isMain && (
                                <>
                                  <Grid item>
                                    <img src={pdf} alt="pdf" width={20} />
                                  </Grid>

                                  {/* <Grid item>
                                    <img src={xlsx} alt="xlsx" width={20} />
                                  </Grid> */}
                                </>
                              )}
                            </Grid>
                          </>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
            )}
          </Box>
        )}
      </Grid>

      {conversionError && (
        <DialogFileConversionErrorDetails
          conversionError={conversionError}
          close={() => setConversionError(undefined)}
        />
      )}
    </>
  );
}
