import { Box, Checkbox, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectCollaborator from "../../components/SelectCollaborator";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";

/**
 * @returns {JSX.Element}
 */
export function Teams() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();

  const [enableDefaultCollaborators, setEnableDefaultCollaborators] = useState(
    state.org.enableDefaultCollaborators ?? false
  );
  const [defaultCollaborators, setDefaultCollaborators] = useState(
    /** @type {*[]} */ (state.org.defaultCollaborators || [])
  );

  /**
   * @param {boolean} enableDefaultCollaborators
   * @param {*[]} defaultCollaborators
   * @returns {Promise<void>}
   */
  async function updateDefaultCollaborators(
    enableDefaultCollaborators,
    defaultCollaborators
  ) {
    try {
      await axios.put(
        `${state.settings.api}org/${state.user.orgID}/defaultCollaborators`,
        { enableDefaultCollaborators, defaultCollaborators }
      );
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "success",
          message: "Default collaborators settings were updated",
        },
      });
      dispatch({
        type: "UPDATE_ORG",
        payload: {
          ...state.org,
          enableDefaultCollaborators,
          defaultCollaborators,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message: "An error occurred while saving your changes.",
        },
      });
    }
  }

  /**
   * @param {{ _id: string }[]} defaultCollaborators
   * @returns {void}
   */
  function onChangeCollaborators(defaultCollaborators) {
    const createCollaborator = defaultCollaborators.find((c) => !c._id);
    if (createCollaborator) {
      navigate("/admin/users");
      return;
    }

    setDefaultCollaborators(defaultCollaborators);

    debugger;

    updateDefaultCollaborators(
      enableDefaultCollaborators,
      defaultCollaborators
    );
  }

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h4">Manage Teams</Typography>
        </Grid>

        <Grid item mt={2}>
          <Typography variant="body1" textAlign={"center"}>
            Set rules for automatic assignment of contracts and tickets to team
            members
          </Typography>
        </Grid>

        <Box
          sx={{
            mt: 8,
            pt: 4,
            pb: 6,
            px: 4,
            mx: "auto",
            borderRadius: "15px",
            backgroundColor: theme.palette.grey[100],
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
          }}
          style={{
            minWidth: "900px",
            maxWidth: "900px",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Default Collaborators
          </Typography>

          <Grid container alignItems="baseline">
            <Grid item>
              <Checkbox
                value={enableDefaultCollaborators}
                checked={enableDefaultCollaborators}
                onChange={(_event, checked) => {
                  setEnableDefaultCollaborators(checked);
                  updateDefaultCollaborators(checked, defaultCollaborators);
                }}
              />
            </Grid>

            <Grid item>
              <Typography variant="body1">
                For any new agreements, add the following users as default
                collaborators:
              </Typography>

              <Typography variant="body2" color="#979797" mt={1}>
                Collaborators get access to the agreement and receive status
                updates via notifications.
              </Typography>
            </Grid>
          </Grid>

          {enableDefaultCollaborators && (
            <Grid container mt={4}>
              <Grid item xs={12}>
                <SelectCollaborator
                  canCreate={true}
                  party={{
                    partyID: "party0",
                    editMode: "edit",
                    myClient: false,
                    legalName: state.org.shortName,
                  }}
                  users={state.users}
                  collaborators={defaultCollaborators}
                  disabled={false}
                  handleChangeCollaborators={onChangeCollaborators}
                  owner={state.org._id}
                />
              </Grid>
            </Grid>
          )}

          <Box
            sx={{ my: 2 }}
            style={{
              width: "fit-content",
              marginLeft: "auto",
            }}
          ></Box>
        </Box>
      </Grid>
    </>
  );
}
