import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import {
  faGear,
  faPlusCircle,
  faSearch,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useMemo, useState } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import DialogSettingsForGeneralLabels from "./dialogs/DialogSettingsForGeneralLabels";

const LabelBox = styled(Box)(({ theme, color }) => ({
  // @ts-ignore
  border: `1px solid ${theme.palette[color]?.main}`,
  borderRadius: "15px",
  maxHeight: "420px",
  overflow: "auto",
}));

/**
 * @typedef {*} LabelListProps
 */

/**
 * @param {LabelListProps} props
 * @returns {React.JSX.Element}
 */
export default function LabelList({ type, handleAddLabel, handleEditLabel }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [filterText, setFilterText] = useState("");
  const [
    openDialogSettingsForGeneralLabels,
    setOpenDialogSettingsForGeneralLabels,
  ] = useState(false);

  const filteredLabels = useMemo(() => {
    const lowerText = filterText.toLowerCase();
    return state.labels.filter(
      (/** @type {{ type: { name: string; }; name: string; }} */ l) =>
        l.type.name === type.name && l.name.toLowerCase().includes(lowerText)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, state.labels]);

  return (
    <Box
      sx={{
        mt: 8,
        pt: 4,
        pb: 6,
        px: 4,
        mx: "auto",
        borderRadius: "15px",
        backgroundColor: theme.palette.grey[100],
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
      }}
      style={{
        maxWidth: "900px",
      }}
    >
      <Grid
        container
        direction="row"
        alignContent="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            {type.name} Labels
          </Typography>
        </Grid>

        {type?.name === "Agreement" && (
          <Grid item>
            <IconButton
              onClick={() => {
                setOpenDialogSettingsForGeneralLabels(true);
              }}
            >
              <FontAwesomeIcon
                icon={faGear}
                style={{
                  fontSize: "20px",
                  color: "#646464",
                }}
              />
            </IconButton>

            {openDialogSettingsForGeneralLabels && (
              <DialogSettingsForGeneralLabels
                open={openDialogSettingsForGeneralLabels}
                close={() => {
                  setOpenDialogSettingsForGeneralLabels(false);
                }}
              />
            )}
          </Grid>
        )}
      </Grid>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {type.description}
      </Typography>
      <Box
        sx={{ my: 2 }}
        style={{
          width: "fit-content",
          marginLeft: "auto",
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ padding: "4px 7px" }}
                />
              </InputAdornment>
            ),
            endAdornment: !!filterText && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setFilterText("")}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              // @ts-ignore
              backgroundColor: theme.palette.grey[250],
              borderRadius: "10px",
              minHeight: "unset",
            },
            inputProps: {
              style: { fontWeight: "bold" },
            },
          }}
        />
      </Box>

      <LabelBox sx={{ p: 2 }}>
        {filteredLabels.map((/** @type {*} */ label) => (
          <Chip
            key={label._id}
            label={label.name}
            color={type.color}
            sx={{
              fontWeight: "700",
              m: 1,
              px: 2,
            }}
            icon={<FontAwesomeIcon icon={faCircleInfo} />}
            onClick={() => handleEditLabel(label)}
          />
        ))}

        <Chip
          label="Add Label"
          color={type.color}
          variant="outlined"
          sx={{
            fontWeight: "700",
            mx: 1,
            px: 2,
          }}
          icon={<FontAwesomeIcon icon={faPlusCircle} />}
          onClick={() => handleAddLabel(type)}
        />
      </LabelBox>
    </Box>
  );
}
