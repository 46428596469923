import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBookBookmark,
  faBullseyeArrow,
  faCircleInfo,
  faCircleX,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../../state/store";
import theme from "../../../../theme/theme";
import { getCanveoTier } from "../../../../utils/getCanveoTier";
import Agreeements from "../../../../views/Agreements";
import { AnalyzeCurrentAgreementVersionButton } from "../../../AnalyzeCurrentAgreementVersionButton";
import PlaybookDrawer from "../../PlaybookDrawer";

/**
 * @typedef {object} ComplianceAnalysisTabProps
 * @property {string} jobStatus
 * @property {(props: { extractMetadata: boolean; complianceAnalysis: boolean; }) => Promise<void>} analyzeUsingAi
 * @property {*} handleAnalyzedClausePrevious
 * @property {*} handleAnalyzedClauseNext
 * @property {*} jobResult
 * @property {*} handleInfoClick
 * @property {*} handleAnalyzedClauseTopicClick
 * @property {*} openMenu
 * @property {*} handleDeleteClick
 * @property {*} handleComplianceChange
 * @property {*} selectedAnalyzedClauseTopic
 * @property {*} closeMenu
 * @property {*} anchorEl
 * @property {*} selectedClause
 
 */

/**
 * @param {ComplianceAnalysisTabProps} props
 */
export function ComplianceAnalysisTab({
  jobStatus,
  analyzeUsingAi,
  handleAnalyzedClauseNext,
  handleAnalyzedClausePrevious,
  handleAnalyzedClauseTopicClick,
  handleInfoClick,
  jobResult,
  handleComplianceChange,
  handleDeleteClick,
  openMenu,
  selectedAnalyzedClauseTopic,
  closeMenu,
  anchorEl,
  selectedClause,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container direction="row">
      <Grid item xs={6} sx={{ paddingX: 2 }}>
        <AnalyzeCurrentAgreementVersionButton
          jobStatus={jobStatus}
          analyzeUsingAi={analyzeUsingAi}
        />

        {(jobStatus === "initial" || jobStatus === "finished") && (
          <>
            <Grid container mt={2}>
              <Grid item>
                <IconButton
                  color="primary"
                  onClick={handleAnalyzedClausePrevious}
                  disabled={jobStatus === "initial"}
                >
                  <FontAwesomeIcon icon={faAngleLeft} size="sm" />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton
                  color="primary"
                  onClick={handleAnalyzedClauseNext}
                  disabled={jobStatus === "initial"}
                >
                  <FontAwesomeIcon icon={faAngleRight} size="sm" />
                </IconButton>
              </Grid>
            </Grid>

            <Grid mt={2} />

            <Autocomplete
              multiple
              options={replaceMatchingObjects(
                jobResult.complianceTriagePromptResults,
                jobResult.complianceAssessmentPromptResults
              )}
              value={replaceMatchingObjects(
                jobResult.complianceTriagePromptResults,
                jobResult.complianceAssessmentPromptResults
              )}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Topics" size="small" />
              )}
              filterSelectedOptions
              disableClearable
              disabled={jobStatus === "initial"}
              disablePortal
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      {...getTagProps({ index })}
                      label={option.name}
                      title={option.name}
                      onClick={() => {
                        handleAnalyzedClauseTopicClick(option);
                      }}
                      icon={
                        <IconButton
                          onClick={(e) => handleInfoClick(option.name, e)}
                          size="small"
                          sx={{ color: "#e0e0e0" }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            color="#e0e0e0"
                          />
                        </IconButton>
                      }
                      deleteIcon={
                        <>
                          <IconButton
                            onClick={openMenu}
                            size="small"
                            sx={{ color: "#e0e0e0" }}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              color="#e0e0e0"
                            />
                          </IconButton>

                          <IconButton
                            onClick={(e) => handleDeleteClick(option.name, e)}
                            size="small"
                            sx={{ color: "#e0e0e0" }}
                          >
                            <FontAwesomeIcon icon={faCircleX} color="#e0e0e0" />
                          </IconButton>
                        </>
                      }
                      style={{
                        backgroundColor:
                          option.playbookComplianceRating === "nonCompliant" ||
                          option.riskRating === "highRisk" ||
                          option.lawComplianceRating === "noncompliant" ||
                          option.marketStandardRating === "nonstandard"
                            ? theme.palette.error.main
                            : "green",
                        color: "#e0e0e0",
                        fontWeight: "bold",
                        opacity:
                          selectedAnalyzedClauseTopic?.clauseTopicId ===
                            option?.id || !selectedAnalyzedClauseTopic
                            ? 1
                            : 0.2,
                      }}
                    />
                  );
                })
              }
            />

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleComplianceChange("Compliant")}>
                Compliant
              </MenuItem>

              <MenuItem
                onClick={() => handleComplianceChange("Partially Compliant")}
              >
                Partially Compliant
              </MenuItem>

              <MenuItem onClick={() => handleComplianceChange("Non-compliant")}>
                Non-compliant
              </MenuItem>
            </Menu>

            {getCanveoTier(state?.user?.email) === "experimental" && (
              <Box mt={2}>
                <Typography
                  color={theme.palette.primary.main}
                  fontWeight="bold"
                  fontSize={14}
                  onClick={handleClickOpen}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Contracts with similar constellations ...
                </Typography>
              </Box>
            )}
          </>
        )}
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
          height: "85vh",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={(_event, value) => setValue(value)}
          >
            <Tab
              icon={<FontAwesomeIcon icon={faBullseyeArrow} />}
              iconPosition="start"
              label="Details"
              sx={{ fontWeight: "bold" }}
            />

            <Tab
              icon={<FontAwesomeIcon icon={faBookBookmark} />}
              iconPosition="start"
              label="Playbook"
              sx={{ fontWeight: "bold" }}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          {!selectedAnalyzedClauseTopic ? (
            <Grid container justifyContent="center" mt={2}>
              <Grid item>[Select a [topic | parameter]]</Grid>
            </Grid>
          ) : (
            <div
              id="fast-track-drawer-result-container"
              style={{
                minHeight: "740px",
                maxHeight: "740px",
                overflowY: "scroll",
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {selectedClause && (
                <>
                  <Box mt={2}>
                    <Typography
                      color={theme.palette.primary.main}
                      fontWeight="bold"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "260px",
                        fontSize: "17px",
                      }}
                    >
                      Clause: "{selectedClause.textContent}"
                    </Typography>
                  </Box>

                  {selectedAnalyzedClauseTopic?.clauseTopic?.redlineExplanations?.find(
                    (/** @type {{ clauseId: string; }} */ x) =>
                      x.clauseId === selectedClause?.serializedClauseNode.id
                  )?.explanation && (
                    <>
                      <Chip
                        size="small"
                        label="What did the counterparty do?"
                        sx={{
                          marginY: 2,
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />

                      <Typography fontSize={14}>
                        {
                          selectedAnalyzedClauseTopic?.clauseTopic?.redlineExplanations?.find(
                            (/** @type {{ clauseId: string; }} */ x) =>
                              x.clauseId ===
                              selectedClause?.serializedClauseNode.id
                          )?.explanation
                        }
                      </Typography>
                    </>
                  )}

                  {selectedAnalyzedClauseTopic?.clauseTopic?.changeExplanations?.find(
                    (/** @type {{ clauseId: string; }} */ x) =>
                      x.clauseId === selectedClause?.serializedClauseNode.id
                  )?.explanation && (
                    <>
                      <Chip
                        size="small"
                        label="How did we address the issues below?"
                        sx={{
                          marginY: 2,
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />

                      <Typography fontSize={14}>
                        {
                          selectedAnalyzedClauseTopic?.clauseTopic?.changeExplanations?.find(
                            (/** @type {{ clauseId: string; }} */ x) =>
                              x.clauseId ===
                              selectedClause?.serializedClauseNode.id
                          )?.explanation
                        }
                      </Typography>
                    </>
                  )}
                  <br />
                </>
              )}

              {selectedAnalyzedClauseTopic && (
                <>
                  <Box mt={2}>
                    <Typography
                      color={theme.palette.primary.main}
                      fontWeight="bold"
                      sx={{
                        fontSize: "17px",
                      }}
                    >
                      Topic: {selectedAnalyzedClauseTopic.clauseTopic.name}
                    </Typography>
                  </Box>

                  {selectedAnalyzedClauseTopic.clauseTopic
                    .playbookComplianceRating && (
                    <>
                      <Chip
                        size="small"
                        label={
                          selectedAnalyzedClauseTopic?.clauseTopic
                            ?.playbookComplianceRating !== "notFoundInPlaybook"
                            ? transformTextToDisplayText(
                                selectedAnalyzedClauseTopic.clauseTopic
                                  .playbookComplianceRating
                              ) + " with Playbook"
                            : "Not covered in Playbook"
                        }
                        sx={{
                          marginY: 2,
                          backgroundColor:
                            selectedAnalyzedClauseTopic?.clauseTopic
                              ?.playbookComplianceRating !==
                            "notFoundInPlaybook"
                              ? selectedAnalyzedClauseTopic?.clauseTopic
                                  ?.playbookComplianceRating === "nonCompliant"
                                ? theme.palette.error.main
                                : "green"
                              : "gray",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    </>
                  )}

                  {selectedAnalyzedClauseTopic?.clauseTopic
                    ?.playbookComplianceRating === "notFoundInPlaybook" && (
                    <>
                      <br />

                      <Typography fontWeight="600" fontSize={14}>
                        Explanation of playbook assessment
                      </Typography>

                      <Typography fontSize={14}>
                        This topic was not covered in the Playbook that was used
                        for this analysis.
                      </Typography>
                    </>
                  )}

                  {selectedAnalyzedClauseTopic.clauseTopic
                    .playbookComplianceExplanation && (
                    <>
                      <br />

                      <Typography fontWeight="600" fontSize={14}>
                        Explanation of playbook assessment
                      </Typography>

                      <Typography fontSize={14}>
                        {
                          selectedAnalyzedClauseTopic.clauseTopic
                            .playbookComplianceExplanation
                        }
                      </Typography>
                    </>
                  )}

                  {selectedAnalyzedClauseTopic.clauseTopic.riskRating && (
                    <>
                      <Chip
                        size="small"
                        label={transformTextToDisplayText(
                          selectedAnalyzedClauseTopic.clauseTopic.riskRating
                        )}
                        sx={{
                          marginY: 2,
                          backgroundColor:
                            selectedAnalyzedClauseTopic?.clauseTopic
                              ?.riskRating === "highRisk"
                              ? theme.palette.error.main
                              : "green",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    </>
                  )}

                  {selectedAnalyzedClauseTopic.clauseTopic
                    .riskRatingExplanation && (
                    <>
                      <br />

                      <Typography fontWeight="600" fontSize={14}>
                        Explanation of risk assessment
                      </Typography>

                      <Typography fontSize={14}>
                        {
                          selectedAnalyzedClauseTopic.clauseTopic
                            .riskRatingExplanation
                        }
                      </Typography>
                    </>
                  )}

                  {selectedAnalyzedClauseTopic.clauseTopic
                    .lawComplianceRating && (
                    <>
                      <Chip
                        size="small"
                        label={
                          transformTextToDisplayText(
                            selectedAnalyzedClauseTopic.clauseTopic
                              .lawComplianceRating
                          ) + " with Applicable Laws"
                        }
                        sx={{
                          marginY: 2,
                          backgroundColor:
                            selectedAnalyzedClauseTopic?.clauseTopic
                              ?.lawComplianceRating === "compliant"
                              ? "green"
                              : theme.palette.error.main,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    </>
                  )}

                  {selectedAnalyzedClauseTopic.clauseTopic
                    .lawComplianceExplanation && (
                    <>
                      <br />

                      <Typography fontWeight="600" fontSize={14}>
                        Explanation of law compliance assessment
                      </Typography>

                      <Typography fontSize={14}>
                        {
                          selectedAnalyzedClauseTopic.clauseTopic
                            .lawComplianceExplanation
                        }
                      </Typography>
                    </>
                  )}

                  {selectedAnalyzedClauseTopic.clauseTopic
                    .marketStandardRating && (
                    <>
                      <Chip
                        size="small"
                        label={transformTextToDisplayText(
                          selectedAnalyzedClauseTopic.clauseTopic
                            .marketStandardRating
                        )}
                        sx={{
                          marginY: 2,
                          backgroundColor:
                            selectedAnalyzedClauseTopic?.clauseTopic
                              ?.marketStandardRating === "standard"
                              ? "green"
                              : theme.palette.error.main,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    </>
                  )}

                  {selectedAnalyzedClauseTopic.clauseTopic
                    .marketStandardExplanation && (
                    <>
                      <br />

                      <Typography fontWeight="600" fontSize={14}>
                        Explanation of market standard assessment
                      </Typography>

                      <Typography fontSize={14}>
                        {
                          selectedAnalyzedClauseTopic.clauseTopic
                            .marketStandardExplanation
                        }
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <PlaybookDrawer />
        </CustomTabPanel>
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth={"xl"}>
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle>Similar Contracts out of Compliance</DialogTitle>
        <DialogContent>
          <Agreeements tableOnly={true} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Grid>
  );
}

/**
 * @param {*} props
 * @returns
 */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

/**
 * @param {string | undefined} text
 * @returns {string}
 */
function transformTextToDisplayText(text) {
  if (typeof text !== "string") return "";

  if (text === "nonCompliant") return "Non-Compliant";
  if (text === "highRisk") return "High Risk";
  if (text === "lowRisk") return "Low Risk";
  if (text === "nonStandard") return "Non-Standard in Market";
  if (text === "standard") return "Standard in Market";

  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * @param {{ id: string }[]} array1
 * @param {{ id: string }[]} array2
 * @returns {*[]}
 */
function replaceMatchingObjects(array1 = [], array2 = []) {
  const map = new Map(array2.map((obj) => [obj.id, obj]));

  const results = array1.map((obj) =>
    map.has(obj.id) ? map.get(obj.id) : obj
  );

  return results;
}
